import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LoginForm from "../Components/Login/Form";
import {Helmet, HelmetProvider} from "react-helmet-async";


export const Login = () => {

  const metaTitle = "Welcome Back to AI Zones: Get Your AI Fix Now"; 

  const metaDescription = "Log in to AI Zones and step into your AI workspace. Effortlessly discover & manage the latest AI tools out there! ";

  document.title = metaTitle;

  return (
    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/login`}  />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Zones_Cover.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/login`} />
    </Helmet>
    <Box marginTop={{ base: "30px", md: "100px" }}>
      <LoginForm />
    </Box>
   </HelmetProvider>
  );
};
