import React, { useEffect } from "react";
import SignupForm from "../Components/Signup/Form";
import { Box, useColorModeValue } from "@chakra-ui/react";
import {Helmet, HelmetProvider} from "react-helmet-async";

export const Signup = () => {
  
  const metaTitle = "Unlock Your AI-Powered Future: Sign Up for Free at AI Zones";
  document.title = metaTitle;
  const metaDescription = "Join the AI revolution! Sign up for AI Zones and access thousands of tools to supercharge your workflow. Design like a pro, write like a master, and optimize every task";


  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/signup`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Zones_Cover.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/signup`} />
    </Helmet>
    <Box py="50px" marginTop={{ base: "0px", md: "60px" }}>
      <SignupForm />
    </Box>
    </HelmetProvider>
  );
};

