import { Box } from "@mui/material";
import React from "react";
import { CircularProgress, Stack, SimpleGrid  } from "@chakra-ui/react";
import { ListModal_home } from "../ListModal_home";
import LoadingAnimation from '../LoadingAnimation';
import style from "../../Style/List.module.css";
export const LIstView_home = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
  handleUserInteraction,
}) => {
 
  return (
    <>
      {/* <SimpleGrid  columns={{ base: 1, md: 3 }} spacing={1}> */}
      <Stack w="100%">
      <Box className={style.listbox} w="100%">
        {data?.filter((e) => e.verify).map((el, i) => (
          <Box key={i}>
            <ListModal_home
              el={el}
              i={i}
              setcat={setcat}
              setPageName={setPageName}
              setFilterLoader={setFilterLoader}
              handleUserInteraction={handleUserInteraction} 
            />
          </Box>
          
        ))}
        </Box>
        </Stack>
  

      {showLoader && (
        <Box
          height="100px"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingAnimation size="xl" />
        </Box>
      )}
    </>
  );
};