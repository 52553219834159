import {
    Box,
    Flex,
    Text,
 
} from '@chakra-ui/react';


export const Form4 = ({ tool_own,setTool}) => {

 
    return (
        <Box>
            <Text fontSize="18px" lineHeight="24px" fontWeight="400">Do you own this tool?</Text>
            <form>
                <Flex mt="25px" gap="20px"  >
                    <Box><input onChange={()=>

                    {
                        return setTool(true)
                    }
                    
                    
                    } type='radio' /></Box>
                    <Box>
                        <Text fontSize="15px" lineHeight="24px" fontWeight="600">Yes</Text>
                        <Text textAlign="justify" fontSize="14px" lineHeight="24px" fontWeight="400">Welcome to AI ZONES, the hub for AI tool creators! To access and edit your tool's listing details, simply request access on your tool's page.  Once verified, you'll gain access to the "Creators Portal," where you can update your tool's details and explore a range of exciting features. As soon as your tool is listed on AI ZONES, you'll be able to manage your listing through the Creators Portal. We appreciate your contribution and are thrilled to have you with us!</Text>
                    </Box>

                </Flex>
                <Flex mt="25px" gap="20px"  >
                    <Box><input onChange={()=>{

                   return setTool(false)
                    }} type='radio' /></Box>
                    <Box>
                        <Text fontSize="15px" lineHeight="24px" fontWeight="600">No</Text>
                        <Text textAlign="justify" fontSize="14px" lineHeight="24px" fontWeight="400">Thank you for helping us grow our AI ZONES community by submitting a tool!
                            Your contribution means a lot to us.</Text>
                    </Box>
                </Flex>
            </form>
        </Box>
    );
};