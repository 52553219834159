import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  IconButton,
  InputLeftElement,
  InputRightElement,
  Switch,
  
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import style from "../Style/Navbar.module.css";
import dstyle from "../Style/Featured.module.css";
import { Search2Icon } from "@chakra-ui/icons";
import { CloseIcon } from "@chakra-ui/icons";
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from "axios";
import { CurtedCollection } from "./CurtedCollection";
import { BiTrendingUp } from "react-icons/bi";
import { RiShareBoxFill } from "react-icons/ri";
import { SearchClient as TypesenseSearchClient } from "typesense";
import { searchItem } from "../Redux/action";

const getData = async ({ input }) => {
  return axios.post(`${process.env.REACT_APP_API}/data/get?search=${input}`);
};

const SearchBarSmallScreen = ({ toggleSearchBar }) => {
  const [input, setInput] = useState("");
  const [showBox, setShowBox] = useState(false);
  const [dlength, setLength] = useState(0);
  const [sdata, setData] = useState([]);
  const [tdata, settData] = useState([]);
  let [page, setPage] = useState(1);
  const boxRef = useRef(null);
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);


  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    handleSearchWithCurrentInput(input);
  };

  const [check, setCheck] = useState(true);
 
  const toggleChecked = () => {setInput("");  setCheck(value => !value)};

  const handleInputChange = (e) => {
    setInput(e.target.value); // Update the input state with the new value
    setShowBox(false);

    if (e.key === 'Enter') {
        handleSearchWithCurrentInput(e.target.value); // Trigger search on Enter key press
    }
};

const handleClearInput = () => {
  setInput(""); // Clear the input field
  // Additional actions if needed, like resetting search results
};


const handleSearchWithCurrentInput = (inputValue) => {
  if (!inputValue.trim()) {
    console.log("Please enter a search query.");
    return;
  }
  
  // Format the inputValue as a slug
  const slug = inputValue.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

  // Optionally blur the search input field to hide the keyboard on mobile devices
  const searchInput = document.getElementById("searchInput");
  if (searchInput) {
    searchInput.blur();
  }

  // Navigate using the new slug-based URL structure
  navigate(`/search/${slug}`);
};
  

  const getlength = async () => {
    try {
      axios.post(`${process.env.REACT_APP_API}/data/get`).then((res) => {
        setLength(res.data.result);
      });
    } catch (err) {
      //console.log(err);
    }
  };

  const gettData = async (page) => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API}/data/top-ten/category?Category`
      );

      settData(res.data.data);
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    gettData(page);
    getlength();
  }, []);

  const infinitScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight && window.location.pathname !== "/search"
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []);

  return (
    <>
      <Box
        display={{ base: "block", md: "none" }} // Display block on small screens
        position="fixed" // Position fixed to overlay on the navbar
        top={0} // Start from the top of the viewport
        left={0} // Start from the left of the viewport
        right={0} // Stretch to the right of the viewport
        zIndex="9999" // High z-index to overlay on top of everything
        backgroundColor={'var(--chakra-colors-gray-800)'}
        padding="10px"
      
      >
        <Flex alignItems="center">
        <IconButton
        aria-label="Close search"
        icon={<ArrowBackIcon />}
        onClick={toggleSearchBar} // Function to toggle the visibility
        marginRight="10px"
       />
         <Flex flexDirection={"column"} position={"relative"} width={"100%"}>
          <form onSubmit={handleFormSubmit}> {/* Encapsulate input and button in a form */}
            <InputGroup width={"100%"}>
              <Input
                id="searchInput"
                value={input} // Ensure the input value is controlled
                onChange={(e) => setInput(e.target.value)} // Update the state on change
                onKeyDown={handleInputChange} // Keep if you need to handle other key events
                bg={useColorModeValue("white", "#1c1c1c")}
                _placeholder={{
               color: "grey",
                fontSize: "16px",
                   lineHeight: "24px",
                 position: "relative",
                    animation: "typing 10s steps(40) infinite",
                   whiteSpace: "nowrap",
                  overflow: "hidden",
                  }}
                  color={useColorModeValue("black", "white")}
                  borderRadius="5px"
               textAlign="left"
               placeholder={"Discover AI Tools Using AI"}
              />
 
              <InputRightElement>
            {input && (
              <IconButton
                aria-label="Clear"
                icon={<CloseIcon />}
                onClick={handleClearInput}
                variant="ghost"
                ml={-20}
              />
            )}
            {/* ... existing search button code ... */}
          </InputRightElement>
          <InputRightElement>
                <IconButton
                  type="submit" // Make the IconButton act as a submit button
                  aria-label="Search"
                  icon={<Search2Icon />}
                  variant="solid"
                />
              </InputRightElement>
            </InputGroup>
            </form>
          {showBox && (
            <Box
              ref={boxRef}
              boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px"
              maxH="500px"
              position="absolute"
              top={"50px"}
              left={"0px"}
              zIndex={100}
              bg={colorMode === "light" ? "white" : "#2C2C2C"}
              width={"100%"}
              overflow="auto"
              className={style.searchbar}
              p="20px"
            >
              {input.length >= 1
                ? sdata?.map((el, i) => (
                    <Flex
                      mb="20px"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="15px"
                      key={i}
                    >
                      <Link
                        key={i}
                        to={`/tool/${el.slug}`}
                        onClick={() => setShowBox(false)}
                      >
                        <Flex gap="15px" alignItems="center">
                          <Image
                            boxSize="35px"
                            borderRadius="4px"
                            src={el.Logo}
                          />
                          <Box>
                            <Text
                              fontSize="13px"
                              lineHeight="24px"
                              fontWeight="600"
                            color={"initial"}

                            >
                              {el.Title}
                            </Text>
                            <Text
                              fontSize="12px"
                              lineHeight="20px"
                            color={"initial"}

                              className={dstyle.desc}
                            >
                              {el.Tagline}
                            </Text>
                          </Box>
                        </Flex>
                      </Link>
                      <Link to={el.URL} target="_blank">
                        {" "}
                        <RiShareBoxFill size={14} color={colorMode === 'dark' ? 'white' : 'black'}/>
                      </Link>
                    </Flex>
                  ))
                : ""}

             <Flex justifyContent="center" mt="20px">
             <Text color="gray.500">Press enter to load more</Text>
            </Flex>

            </Box>
          )}
        </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default SearchBarSmallScreen;
