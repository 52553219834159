import React, { useState, useEffect } from 'react';
import { Box, Button, Spacer, HStack, List, ListItem, Image, Modal, ModalContent, ModalCloseButton,Flex, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

const tourSteps = [
  {
    title: 'Create Your Personalized Collection',
    image: 'https://ai-zones.b-cdn.net/Website-utilities%20/tourcollection.gif',
    description: [
      'Click on "Collect" button to add tools to existing collection or create a new collection',
      'Add title and description',
      'Choose to keep collection private or public',
    ],
  },
  // {
  //   title: 'Discover AI Tools Using AI - LLM integration',
  //   image: 'https://ai-zones.b-cdn.net/Website-utilities%20/AISearchwithLLM.gif',
  //   description: [
  //     'Utilize AI-enhanced search for precise discovery of AI tools',
  //     'Understands complex queries with the help of Large Language Models (LLMs)',
  //     'Delivers relevant results for tools based on functionality, compatibility, and AI capabilities',
  //     'Offers a smarter, more efficient tool discovery experience.',
  //   ],
  // },
  // {
  //   title: 'Find AI Tools from Industry Standard Platforms',
  //   image: 'path/to/discover-image.gif',
  //   description: [
  //     'Find AI tools from industry-standard platforms',
  //   ],
  // },
  // Add more steps as needed
];

const TourModal = () => {
  // Check localStorage to see if the modal has already been shown
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const modalShown = localStorage.getItem('modalShown');
    if (!modalShown) {
      setIsOpen(true); // Open the modal if it hasn't been shown before
    }
  }, []);

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem('modalShown', 'true'); // Set a flag in localStorage when the modal is closed
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < tourSteps.length - 1 ? prevStep + 1 : prevStep));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  // Render dots
  // const renderDots = () => {
  //   return tourSteps.map((_, index) => (
  //     <Box
  //       key={index}
  //       as="span"
  //       display="inline-block"
  //       w="8px"
  //       h="8px"
  //       m="2px"
  //       borderRadius="full"
  //       bg={currentStep === index ? "blue.500" : "gray.500"}
  //     />
  //   ));
  // };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        backgroundColor="gray.600"
        color="white"
        // mx="2"
        borderRadius="lg"
        boxShadow="2xl"
        p="8"
        textAlign="center"
        py={10}
      >
        <ModalCloseButton color="white" />
        <Text fontSize="xl" fontWeight="bold" mb="4">{tourSteps[currentStep].title}</Text>
        
<Image
  src={tourSteps[currentStep].image}
  alt={tourSteps[currentStep].title}
  borderRadius="lg"
  boxShadow="md"
  mb="4"
  maxH="350px"
  mx="auto"
  objectFit="contain" // Add this line to prevent stretching
  width="auto" // Ensure the width can adjust to maintain aspect ratio
  maxWidth="100%" // Limit the image width to not exceed its container
/>

{/* <List spacing={3} textAlign="left" px={5} fontSize="md" mb="6">
  {tourSteps[currentStep].description.map((point, index) => (
    <ListItem key={index}>{point}</ListItem>
  ))}
</List> */}

        {/* <Flex align="center" justify="space-between" mt="4">
          <Button
            colorScheme="white"
            variant="ghost"
            onClick={prevStep}
            visibility={currentStep > 0 ? 'visible' : 'hidden'}
          >
            Back
          </Button>
          <Flex flex="1" justify="center">
            {renderDots()}
          </Flex>
          <Button
            colorScheme="blue"
            onClick={nextStep}
          >
            {currentStep === tourSteps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Flex> */}
      </ModalContent>
    </Modal>
  );
};

export default TourModal;