import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import style from "../../Style/Tool.module.css";
import daskLogo from "../../Utils/Embed DARK.png";
import lightLogo from "../../Utils/Embed light.png";
import { MdOutlineDoneAll } from "react-icons/md";
import { FiCopy } from "react-icons/fi";
import { Link } from "react-router-dom";

export const Embed = ({slug}) => {
  const [copy, setcopy] = useState(false);
  const [light, setlight] = useState(false);

  let imageUrl = light ? lightLogo : daskLogo;

  const handleCopy = () => {
    // const formattedTitle = title ? title.replace(/\s+/g, '-') : 'default-title';
    const imageUrlToCopy = `<a href="${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}"> <img src="${process.env.REACT_APP_PUBLIC_URL}${imageUrl}"/></a>`;

    navigator.clipboard
      .writeText(imageUrlToCopy)
      .then((res) => {
        setcopy(true);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <>
      <Flex
        w="100%"
        flexDirection={"column"}
        gap={1}
        alignItems="center"
        padding={10}
      >
        <Flex w={"100%"} alignItems={{ base: "center", md: "left" }}  flexDirection={{ base: "column", md: "row" }}justifyContent="space-between">
          <Box pb={5}>
            <Image  h="53px" src={imageUrl} />
          </Box>

          <Flex gap="20px" alignItems="center">
            <Text>Dark</Text>{" "}
            <Switch onChange={(e) => setlight(e.target.checked)} />{" "}
            <Text>Light</Text>
          </Flex>
        </Flex>

        <Flex w={"100%"} justifyContent={"space-between"} flexDirection={{ base: "column", md: "row" }} alignItems={"center"}>
          <Box padding={5}>
            {!copy ? (
              <Button
                p={0}
                display={"flex"}
                gap={{ base: 1, md: 2 }}
                bg="none"
                _hover={{ bg: "" }}
                onClick={handleCopy}
              >
                <FiCopy size={30} />
                <Text>Copy embed code</Text>{" "}
              </Button>
            ) : (
              <Button borderRadius="2px" _hover={{ bg: "" }}>
                <MdOutlineDoneAll size={20} style={{ color: "#3B89B6" }} />
              </Button>
            )}
          </Box>

          <Link
            to="https://www.passionfroot.me/ai-zones"
            target="_blank"
          >
            <Button
              _hover={{ bg: "" }}
              borderRadius="7px"
              color="white"
              padding="14px, 17px, 14px, 17px"
              bg="#3B89B6"
            >
              Advertise this tool
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Divider
        border="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
      />
    </>
  );
};
