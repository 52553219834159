
import {
    Flex,
    Box,
    FormControl,

    Input,

    Stack,

    Button,

    Text,

    Divider,
    useColorModeValue,
} from '@chakra-ui/react';
import style from "../../Style/Signup.module.css"
import { FcGoogle } from "react-icons/fc"
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setUserLogin } from '../../Redux/action';
import notification from '../Toast';
import { auth, googleAuthProvider } from '../../Firebase/Firebase';


export const ForgatePassword = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ email: "", password: "" });
    const [error, setError] = useState("");
 
    const userData = useSelector((state) => state.userReducer.loginData);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            let token = userData.data;
            const url = `${process.env.REACT_APP_API}/api/users/update/password`;
            await axios.patch(url, data,{
                headers: { token },
            });
            navigate("/")
            notification("success", "Password Change Successfully!");
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
                notification("error", error.response.data.message);
            }
        }
    };




    return (
        <Stack

        className={style.signupform}
       
        py={12}
        fontFamily="'Lato', Arial, Helvetica, sans-serif"
       
        margin="auto"
        
        paddingBottom="90px"
        borderRadius="3px"
  
      >
        <Stack width="75%"
         border="1px"
         p="30px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
        bg={useColorModeValue("rgba(248, 248, 248, 0.97)", "rgba(56, 56, 56)")}
        
         margin="auto" >
          <Box textAlign="center">
            <Text fontSize={'18px'} fontWeight="350" lineHeight="54px">Discover, Explore & Collect</Text>
           
            <Text mt="20px" fontSize="16px" fontWeight="600" lineHeight="24px" textAlign="left">Update Password</Text>
  
          </Box>
          <Box>
            <Box  >
  
              <FormControl mt="20px" >
  
                <Input color="black" type="email" name="email" value={data.email} onChange={handleChange} _placeholder={{ color: "black" }} borderRadius="3px" placeholder='Email' bg="white" height="46px" />
              </FormControl>
  
  
              <FormControl mt="20px" >
  
                <Input color="black" type="password" name="password" value={data.password} onChange={handleChange} _placeholder={{ color: "black" }} borderRadius="3px" bg="white" placeholder='Password' height="46px" />
              </FormControl>
  
  
  
  
              <Button
                onClick={handleSubmit}
                borderRadius="3px"
                w="100%"
                mt="25px"
                bg={'blue.400'}
                 color="white"
                _hover={{
                  bg: 'blue.500',
                }}>
                 Update
              </Button>
  
 
  
            </Box>
  
          </Box>
        </Stack>
      </Stack>
    );
}