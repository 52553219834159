import React, { useState } from "react";
import { BiShareAlt } from "react-icons/bi";
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  TelegramIcon,
} from "react-share";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,useColorModeValue
} from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { MdOutlineDoneAll } from "react-icons/md";

export default function ShareModel({ url, title, ShareText = "" }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset copy success state after 2 seconds
    }).catch((error) => {
      console.error("Failed to copy: ", error);
    });
  };

  const iconColor = useColorModeValue("gray.900", "white");

  // This function is called when the share button/icon is clicked
  const handleShare = () => {
    // Check if the Web Share API is available
    if (navigator.share) {
      navigator.share({
        title: title, // You can set the title of the content to be shared
        text: ShareText, // Text to be added to the share
        url: url, // The URL to share
      })
      .then(() => console.log('Content shared successfully'))
      .catch((error) => console.log('Error sharing:', error));
    } else {
      // If the Web Share API is not supported, open the modal
      onOpen();
    }
  };



  return (
    <>
      <Flex onClick={handleShare}  color={iconColor} alignItems={"center"} gap={1} cursor={"pointer"}>
        <Text>{ShareText}</Text>
        <BiShareAlt
        
          style={{ fontSize: "16px" }}
         
          // You may want to use colorMode for dynamic color
        />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" gap="10px" mb="30px">
              <Input value={url} isReadOnly />
              <Button onClick={handleCopy}>
                {copySuccess ? <MdOutlineDoneAll size={30} style={{ color: "green" }} /> : <FiCopy size={30} />}
              </Button>
            </Flex>
            <Flex justifyContent="center" gap="15px">
              <FacebookShareButton url={url}>
                <FacebookIcon size={38} round />
              </FacebookShareButton>
              <WhatsappShareButton url={url}>
                <WhatsappIcon size={38} round />
              </WhatsappShareButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon size={38} round />
              </LinkedinShareButton>
              <TwitterShareButton url={url}>
                <TwitterIcon size={38} round />
              </TwitterShareButton>
              <TelegramShareButton url={url}>
                <TelegramIcon size={38} round />
              </TelegramShareButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
