import React, { useEffect, useState } from "react";
import "./App.css";
import { Navbar } from "./Components/Navbar";
import { Router } from "./Router";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Dashboard from "./Admin/Pages/Dashboard";
import GoogleOneTapLogin from './Firebase/GoogleOneTapLogin';
import { useLocation } from 'react-router-dom';

function App() {
  const { userReducer } = useSelector((store) => store);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.clear();
    if (window.innerWidth < 768) {
      const handleScroll = () => {
        if (window.scrollY > 0 && !isScrollingUp) {
          setIsScrollingUp(true);
        } else if (window.scrollY === 0) {
          setIsScrollingUp(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isScrollingUp]);

  useEffect(() => {
    // Only run this effect on tool pages
    if (!location.pathname.startsWith('/tool/')) {
      return;
    }

    const handleVignetteAd = () => {
      if (window.location.hash === '#google_vignette') {
        // Ensure scrolling is enabled when the ad appears
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
      }
    };

    // Check once when the component mounts or URL changes
    handleVignetteAd();

    // Set up event listener for hash changes
    window.addEventListener('hashchange', handleVignetteAd);

    // Clean up
    return () => {
      window.removeEventListener('hashchange', handleVignetteAd);
    };
  }, [location.pathname]);

  const navbarStyle = {
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px",
  };

  const searchBarStyle = {
    position: "sticky",
    top: "0",
    background: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    zIndex: 1000,
  };

  if (userReducer.isAuth.isAdmin) {
    return <Dashboard />;
  }

  return (
    <>
      {!userReducer.isAuth && <GoogleOneTapLogin />} {/* Google One Tap Login */}
      <Box className="container">
        <Box
          fontFamily="'Lato', Arial, Helvetica, sans-serif"
          maxWidth={"2000px"}
          className="app"
          position="relative"
        >
          <div style={navbarStyle}>
            <Navbar />
          </div>
          <Box marginTop={{ base: "5px", md: "25px" }}>
            <Router />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default App;
