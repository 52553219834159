import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { ListModal } from "../Components/ListModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { LandingRight } from "../Components/LandingRight";
import notification from "../Components/Toast";
import ShareModel from "../Components/Share";
import { MdModeEdit } from "react-icons/md";

const getData = ({ id, token }) => {
  return axios.get(`${process.env.REACT_APP_API}/myspace/get/${id}`, {
    headers: { token },
  });
};
export const MycollectionSingle = () => {
  const [pageName, setPageName] = useState("");

  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let [data, setdata] = useState([]);
  const navigate = useNavigate();
  let [show, setShow] = useState(null);

  let n = data[0]?.spaceID?.space_name;
  let d = data[0]?.spaceID?.description;
  let s = data[0]?.spaceID?.status;

  let { id } = useParams();
  const [desc, setDesc] = useState(d);
  const [name, setName] = useState(n);
  const [status, setStatus] = useState(s);
  const tool = data[0]?.spaceID?.tool;

  const userData = useSelector((state) => state.userReducer.loginData);

  let token = userData.data;

  const handledelete = async (lid) => {
    try {
      const url = `${process.env.REACT_APP_API}/space/delete/${lid}`;
      const { data: spaceRes } = await axios.delete(url, {
        headers: { token },
      });
      onClose();
      navigate("/collection");
      notification("success", spaceRes.msg);
    } catch (err) {
      notification("error", err.response.data.errors);
    }
  };

  useEffect(() => {
    getData({ id, token }).then((res) => {
      setdata(res.data.data);
    });
  }, [id, token]);

  const handleUpdate = async (lid) => {
    const spacePayload = {
      space_name: name,
      description: desc,
      tool: tool,
      status: status,
    };

    try {
      const url = `${process.env.REACT_APP_API}/space/update/${lid}`;
      await axios
        .patch(url, spacePayload, {
          headers: { token },
        })
        .then((res) => {
          onClose();
          window.location.reload();
        });
    } catch (err) {
      notification("error", err.response.data.errors);
    }
  };

  let url = `${process.env.REACT_APP_PUBLIC_URL}/collection/${id}`;

  const updateData = async (idee, v) => {
    const comment = {
      coll_higlight: v,
    };
    try {
      axios
        .patch(`${process.env.REACT_APP_API}/myspace/update/${idee}`, comment)
        .then((res) => {
          setdata((prevData) =>
            prevData.map((el) =>
              el._id === idee ? { ...el, coll_higlight: v } : el
            )
          );
          setShow(null);
        });
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <>
      <Box w="100%">
        <Flex
          justifyContent="space-between"
          marginTop={{ base: "0px", md: "60px" }}
        >
          <Stack w="100%" justifyContent="center">
            <Box width="90%" m="auto" mt="0px">
              <Text
                fontSize="12px"
                lineHeight="20px"
                mt="50px"
                fontWeight="400"
              >
                <Link to="/">Home</Link>
                <ArrowForwardIcon />
                <Link to="/collection">Curted collection</Link>
                <ArrowForwardIcon /> {data[0]?.spaceID?.space_name}{" "}
              </Text>
              <Flex mt="40px" justifyContent="space-between">
                <Box
                  borderBottom="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                  w="100%"
                >
                  <Text fontSize="32px" fontWeight="600" lineHeight="24px">
                    {data[0]?.spaceID.space_name}
                  </Text>

                  <Box py={1} mt="20px">
                    <Flex mt="10px" gap="5px" alignItems="center">
                      <Avatar size="sm" src={data[0]?.userID.image} />
                      <Text>{data[0]?.userID.name}</Text>
                    </Flex>

                    <Text
                      mt="30px"
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight="400"
                      textAlign="justify"
                    >
                      {data[0]?.spaceID.description}
                    </Text>
                  </Box>
                </Box>

                <Flex
                  flexDirection="column"
                  textAlign="right"
                  justifyContent="space-between"
                  gap="20px"
                >
                  <Button
                    onClick={onOpen}
                    alignItems="center"
                    gap="4px"
                    color="white"
                    bg="#3B89B6"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="24px"
                  >
                    {" "}
                    Edit
                  </Button>

                  <Box
                    mt="10px"
                    fontSize="15px"
                    lineHeight="24px"
                    fontWeight="600"
                  >
                    {data[0]?.spaceID.status}
                  </Box>

                  <Flex
                    w="fit-content"
                    m="auto"
                    fontSize="12px"
                    lineHeight="31.5px"
                    fontWeight="400"
                    gap="4px"
                    alignItems="center"
                  >
                    <ShareModel url={url} /> Share
                  </Flex>
                </Flex>
              </Flex>

              <Stack>
                <Box mt="25px">
                  {data?.map((el, i) => (
                    <Box key={i}>
                      {el.coll_higlight !== undefined ? (
                        <Flex
                          gap="10px"
                          m="10px"
                          fontSize="15px"
                          fontWeight="400px"
                          lineHeigh="24px"
                        >
                          {show === i ? (
                            <Input
                              w="30%"
                              placeholder="Add comment"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  updateData(el._id, e.target.value);
                                }
                              }}
                            />
                          ) : (
                            <Text fontStyle="italic">{el.coll_higlight}</Text>
                          )}

                          <Flex
                            cursor="pointer"
                            alignItems="center"
                            gap="5px"
                            onClick={() => {
                              setShow(i);
                            }}
                          >
                            <MdModeEdit /> Edit
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex alignItems="center" m="10px">
                          {show === i ? (
                            <Input
                              w="30%"
                              placeholder="Add comment"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  updateData(el._id, e.target.value);
                                }
                              }}
                            />
                          ) : (
                            <Text
                              cursor="pointer"
                              fontSize="15px"
                              fontWeight="400px"
                              lineHeigh="24px"
                              onClick={() => {
                                setShow(i);
                              }}
                            >
                              + Add comment
                            </Text>
                          )}
                        </Flex>
                      )}

                      <ListModal
                        el={el.videoID}
                        setcat={setcat}
                        setPageName={setPageName}
                        setFilterLoader={setFilterLoader}
                      />
                    </Box>
                  ))}
                </Box>

                <Modal
                  blockScrollOnMount={false}
                  isOpen={isOpen}
                  onClose={onClose}
                >
                  <ModalOverlay />
                  <ModalContent color="#444" bg="#D9D9D9" borderRadius="0px">
                    <ModalHeader
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight="600"
                    >
                      Edit collection
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Flex flexDirection="column" gap="10px">
                        <Text
                          mt="5px"
                          fontSize="16px"
                          lineHeight="24px"
                          fontWeight="700"
                        >
                          Name
                        </Text>

                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          _placeholder={{
                            fontSize: "normal",
                            color: "#444",
                            lineHeigh: "22px",
                          }}
                          placeholder="10 developer tools to add to your stack"
                        />

                        <Text>Description</Text>
                        <Textarea
                          _placeholder={{ color: "#444" }}
                          color="#444"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                          row={5}
                          borderRadius="3px"
                          bg="#ffffff"
                        />
                      </Flex>

                      <Flex
                        alignItems="center"
                        mt="50px"
                        mb="30px"
                        textAlign="end"
                        justifyContent="space-between"
                      >
                        <Flex gap="9px" alignItems="center">
                          {data[0]?.spaceID.status === "Public" ? (
                            <Checkbox
                              borderColor="#444"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setStatus("Private");
                                }
                              }}
                            />
                          ) : (
                            <Checkbox
                              borderColor="#444"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setStatus("Public");
                                }
                              }}
                            />
                          )}

                          <Text>
                            {data[0]?.spaceID?.status === "Public"
                              ? "Private"
                              : "Public"}
                          </Text>
                        </Flex>

                        <Flex gap="15px">
                          <Button
                            color="#444"
                            onClick={() => handledelete(id)}
                            fontSize="15px"
                            lineHeight="24px"
                            fontWeight="600"
                            border="1px solid black"
                            bg="#ffffff"
                            padding="3px 10px"
                            borderRadius="3px"
                          >
                            Delete
                          </Button>

                          <Button
                            color="#444"
                            onClick={() => handleUpdate(id)}
                            fontSize="15px"
                            lineHeight="24px"
                            fontWeight="600"
                            border="1px solid black"
                            bg="#ffffff"
                            padding="3px 10px"
                            borderRadius="3px"
                          >
                            Submit
                          </Button>
                        </Flex>
                      </Flex>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Stack>
            </Box>
          </Stack>

          <Stack>
          <Box
            sx={{
              display: 'block',
              '@media (max-width: 1500px)': {
                display: 'none',
              },
            }}
          >
            <LandingRight />
          </Box> 
          </Stack>
        </Flex>
      </Box>
    </>
  );
};
