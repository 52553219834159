import { useEffect, useState } from 'react';
import {
  useColorMode,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Flex,
  Heading,
  Text,
  CloseButton,
  Alert,
  AlertIcon,
  IconButton,
  Box,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Newsletterimage from "../../Utils/Newsletter-image.jpg";

// const NewsletterPopup = () => {
//   const { colorMode } = useColorMode();
//   const [isOpen, setIsOpen] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isSubscribed, setIsSubscribed] = useState(false);
//   const [btnLoading, setBtnLoading] = useState(false);
//   const [subscriptionStatus, setSubscriptionStatus] = useState('');
//   const toast = useToast();

  // Enhanced UI adjustments


const NewsletterPopup = () => {
  // const { colorMode } = useColorMode();
  // const [isOpen, setIsOpen] = useState(false);
  // const [email, setEmail] = useState('');
  // const [isSubscribed, setIsSubscribed] = useState(false);
  // const [btnLoading, setBtnLoading] = useState(false);
  // const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [scrollCount, setScrollCount] = useState(0);

const { colorMode } = useColorMode();
const [isOpen, setIsOpen] = useState(false);
const [email, setEmail] = useState('');
const [isSubscribed, setIsSubscribed] = useState(false);
const [btnLoading, setBtnLoading] = useState(false);
const [subscriptionStatus, setSubscriptionStatus] = useState('');
const toast = useToast();

// const bgColor = { light: 'gray.50', dark: 'gray.600' };
const color = { light: 'gray.800', dark: 'whiteAlpha.900' };
const borderColor = { light: 'gray.300', dark: 'gray.600' };
const buttonColorScheme = 'blue'; // Adjust as per your theme color
const inputbox=useColorModeValue("#ffffff", "gray.700");

  const shouldShowPopup = () => {
    const lastPopupDate = localStorage.getItem('lastPopupDate');
    if (!lastPopupDate) return true;

    const twoDaysInMs = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
    const currentTime = new Date().getTime();
    const lastDate = new Date(lastPopupDate).getTime();

    return currentTime - lastDate >= twoDaysInMs;
  };

  // const headingColor = colorMode === 'dark' ? '#FFFFFF' : '#333333';
  // const textColor = colorMode === 'dark' ? '#CCCCCC' : '#666666';
  const bgColor =
    colorMode === 'dark'
      ? 'linear-gradient(45deg, #4A5568, #2D3748)'
      : 'linear-gradient(45deg, #EDF2F7, #E2E8F0)'; // Define gradient background for modal

///////////brevo
  const handleSubscribe = async () => {
    const isValidEmail = /\S+@\S+\.\S+/.test(email);

    if (isValidEmail) {
      try {
        const response = await fetch('https://api.brevo.com/v3/contacts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_BREVO_API_KEY
              },
          
          body: JSON.stringify({
            email,
            listIds: [10], // Include the list ID in the request body to assign the contact to the list
          }),
        });
  
        if (response.ok) {
            setIsSubscribed(true);
            setSubscriptionStatus('Successfully subscribed! 🎉');
            setTimeout(() => {
              setIsOpen(false);
            }, 3000);
          } else {
            const data = await response.json();
            if (response.status === 400) {
              setSubscriptionStatus(data?.error?.message || 'Subscription failed. It appears this email is already subscribed. Please use a different email to try again');
            } else {
              console.error('Failed to subscribe. Please try again.');
            }
          }
        } catch (error) {
          console.error('An error occurred while subscribing:', error);
        }
      } else {
        setSubscriptionStatus('Please enter a valid email address');
      }
    };

////////////// beehivv
  // const handleSubscribe = async () => {
  //   const isValidEmail = /\S+@\S+\.\S+/.test(email);
  //   setBtnLoading(true); // Start loading
  
  //   if (isValidEmail) {
  //     try {
  //       // Note: Ensure REACT_APP_API is correctly set in your environment variables
  //       const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });
  
  //       // Axios automatically parses the JSON response, so you access it via `response.data`
  //       if (response.status === 200) {
  //         setIsSubscribed(true);
  //         setSubscriptionStatus('Successfully subscribed! 🎉');
  //       } else {
  //         // This else block might never execute since axios throws an error for bad status codes
  //         setSubscriptionStatus('Failed to subscribe. Please try again.');
  //       }
  //     } catch (error) {
  //       // Axios embeds response of the error within error.response
  //       console.error('An error occurred while subscribing:', error);
  //       setSubscriptionStatus(error.response?.data?.message || 'An error occurred while subscribing.');
  //     } finally {
  //       setBtnLoading(false); // Stop loading regardless of request outcome
  //       setTimeout(() => {
  //         setIsOpen(false); // Close modal after a delay
  //       }, 3000);
  //     }
  //   } else {
  //     setSubscriptionStatus('Please enter a valid email address');
  //     setBtnLoading(false);
  //   }
  //};

    // useEffect(() => {
    //     const shouldDisplayPopup = shouldShowPopup();
    //     if (shouldDisplayPopup) {
    //       const timeout = setTimeout(() => {
    //         setIsOpen(true);
    //         localStorage.setItem('lastPopupDate', new Date().toISOString());
    //       }, 5000); // 5-second delay
    
    //       return () => clearTimeout(timeout);
    //     }
    //   }, []);

    const handleScroll = () => {
      setScrollCount((prevCount) => prevCount + 1);
    };

    useEffect(() => {
      const shouldDisplayPopup = shouldShowPopup();
      const maxScrolls = 4; // Define the number of scrolls required
  
      const handleScrollEvent = () => {
        handleScroll();
      };
  
      if (shouldDisplayPopup && scrollCount < maxScrolls) {
        window.addEventListener('scroll', handleScrollEvent);
  
        return () => {
          window.removeEventListener('scroll', handleScrollEvent);
        };
      }
  
      if (scrollCount >= maxScrolls) {
        setIsOpen(true);
        localStorage.setItem('lastPopupDate', new Date().toISOString());
      }
    }, [scrollCount]);

    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
    const imageSize = useBreakpointValue({ base: '100%', md: '50%' });
  

    const popupStyles = {
      display: isOpen ? "flex" : "none",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      bottom: { base: "20px", md: "30px" },
      right: { base: "20px", md: "30px" },
      width: { base: "90%", md: "480px" },
      padding: "20px",
      background: bgColor,
      boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.1)", // Enhanced shadow
      borderRadius: "8px",
      zIndex: "modal",
    };

  
    return (
      <Box
        display={isOpen ? "flex" : "none"}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        position="fixed"
        bottom="3%"
        right="3%"
        width={{ base: "90%", md: "600px" }}
        p="4"
        bgColor={{ light: 'gray.50', dark: 'gray.600' }[colorMode]}
        color={{ light: 'gray.800', dark: 'whiteAlpha.900' }[colorMode]}
        boxShadow="md"
        rounded="lg"
        zIndex="tooltip"
      >
        {/* Close button positioned absolutely within the Box */}
        <IconButton
          icon={<CloseIcon />}
          onClick={() => setIsOpen(false)}
          position="absolute"
          top={2} // Adjust for proper spacing from the top
          right={2} // Adjust for proper spacing from the right
          variant="ghost"
          size="md"
          aria-label="close"
        />
        <Image
          src={Newsletterimage}
          alt="AI Updates"
          objectFit="cover"
          rounded="lg"
          w={{ base: '80%', md: '50%' }}
          h="auto"
        />
        <Stack
          spacing={4}
          w={{ base: '100%', md: '50%' }}
          p="4"
        >
          <Text fontSize="24px" fontWeight="600" textAlign="center">
           Exclusive Updates
         </Text>
          <Text fontSize="md" textAlign="center">
            Stay up to date with the latest & greatest AI tools 🚀
          </Text>
          <Input
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="md"
            //bg={{ light: 'gray.50', dark: 'gray.600' }[colorMode]}
            borderColor={{ light: 'gray.300', dark: 'gray.600' }[colorMode]}
            _placeholder={{ color: 'gray.500' }}
            bg={inputbox}
          />
          <Button
            onClick={handleSubscribe}
            isLoading={btnLoading}
            colorScheme="blue" // Adjust as per your theme color
            size="md"
          >
            Subscribe
          </Button>
          {subscriptionStatus && (
            <Text fontSize="sm" color={isSubscribed ? 'green.500' : 'red.500'}>
              {subscriptionStatus}
            </Text>
          )}
        </Stack>
      </Box>
    );
  };
  
  export default NewsletterPopup;