import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserLogin } from '../Redux/action';
import notification from '../Components/Toast';

const GoogleOneTapLogin = () => {
  const dispatch = useDispatch();

  // Helper function to parse the JWT token
  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error parsing JWT token', error);
      return null;
    }
  };

  // Function to handle the response from Google One Tap
  const handleCredentialResponse = async (response) => {
    const user = parseJwt(response.credential);
    if (!user) {
      notification('error', 'Invalid user data');
      return;
    }

    let payload = {
      name: user.name,
      image: user.picture,
      email: user.email,
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/users/google/auth`,
        payload
      );
      dispatch(setUserLogin(data));
      localStorage.setItem('UserData', JSON.stringify(data));
      notification('success', 'Logged in successfully');
    } catch (error) {
      notification('error', 'Login failed: ' + error.message);
    }
  };

  // Function to initialize Google One Tap
  const initializeGoogleOneTap = () => {
    window.google.accounts.id.initialize({
      client_id: "255599874809-s5mjhl2roblfudqm6pu2ac3s0ctsa39s.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // handle case when One Tap is not displayed or skipped
        console.log('Google One Tap not displayed or skipped');
      }
    });
  };

  // Load the Google One Tap script and initialize it
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleOneTap;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default GoogleOneTapLogin;
