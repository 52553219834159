import React, { useState, useEffect, useRef } from 'react';
import { EditorState, convertToRaw,AtomicBlockUtils , convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Box, Button, Flex, Heading, Input, useDisclosure, useToast, VStack, Text
} from '@chakra-ui/react';
import axios from 'axios';
import notification from '../Components/Toast'; // Assuming this is your custom notification component
import { setUserLogin } from '../Redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react'; 

const AdminPage = () => {
  // State hooks for editor and blog fields
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [slug, setSlug] = useState('');
  const [metaTitle, setMetaTitle] = useState(''); // New state for meta title
  const [metaDescription, setMetaDescription] = useState(''); // New state for meta description
  const [blogs, setBlogs] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const userData = useSelector((state) => state.userReducer.loginData);
  const navigate = useNavigate();
  // Additional states for UI control
  const [dialogContext, setDialogContext] = useState('');
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  // const [imageUrl, setImageUrl] = useState('');
  // const [imageAlt, setImageAlt] = useState('');
  const [content, setContent] = useState(''); // Store content from TinyMCE
  


  const YoutubeEmbedComponent = ({ contentState, block }) => {
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { src } = entity.getData();
  
    return (
      <iframe
        width="560"
        height="315"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
    );
  };
  
  

  const toast = useToast(); // Using Chakra UI's useToast for notifications
  const token = userData.data;
  useEffect(() => {
    if (!userData || userData.Account_type !== 'blogger') {
      toast({
        title: 'Access denied.',
        description: 'You must be an blogger to access this page.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      navigate('/'); // Redirect to home or login page
    }
  }, [userData, navigate, toast]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
      const sortedBlogs = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setBlogs(response.data);
    } catch (error) {
      toastError(error, 'fetching blogs');
    }
  };

  const handleBlogSelect = (blog) => {
    setSelectedBlog(blog);
    if (unsavedChanges) {
      setDialogContext('unsavedChanges');
    } else {
      setDialogContext('editConfirmation');
    }
    onOpen();
  };

  const loadBlog = () => {
    if (selectedBlog) {
      setTitle(selectedBlog.title);
      setCoverImage(selectedBlog.coverImage);
      setSlug(selectedBlog.slug);
      setMetaTitle(selectedBlog.metaTitle);
      setMetaDescription(selectedBlog.metaDescription);
      
      // Set the HTML content directly for TinyMCE to use
      setContent(selectedBlog.content || ''); 
  
      setUnsavedChanges(false);
    }
    onClose();
  };

  const handleNewBlog = () => {
    if (unsavedChanges) {
      setDialogContext('newBlog');
      onOpen();
    } else {
      clearForm();
    }
  };

  const clearForm = () => {
    setTitle('');
    setCoverImage('');
    setSlug('');
    setMetaTitle('');
    setMetaDescription('');
    setContent('');
    setEditorState(EditorState.createEmpty());
    setUnsavedChanges(false);
    setSelectedBlog(null);
  };

  const handleSaveOrUpdate = async (published) => {
    // Directly use the HTML content from TinyMCE stored in the 'content' state
    // Make sure that 'content' state is updated with the TinyMCE's content in its onChange or onEditorChange event handler
    try {
      const blogData = { 
        title, 
        slug, 
        coverImage, 
        content, // This is the HTML content from TinyMCE
        published, 
        metaTitle, 
        metaDescription 
      };
      // Determine whether it's a new blog or updating an existing one.
      // If selectedBlog is null, it means it's a new blog.
      const method = selectedBlog ? 'put' : 'post';
      const url = selectedBlog 
        ? `${process.env.REACT_APP_API}/api/blogs/${selectedBlog.slug}` 
        : `${process.env.REACT_APP_API}/api/blogs`;
  
      await axios[method](url, blogData, {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming you're using bearer token authentication.
        },
      });
      toastSuccess(published ? 'published' : 'saved');
      // clearForm();
      fetchBlogs();
    } catch (error) {
      toastError(error, published ? 'publishing' : 'saving');
    }
  };
  

  // const handleDelete = async () => {
  //   try {
  //     await axios.delete(`${process.env.REACT_APP_API}/api/blogs/${slug}`);
  //     toastSuccess('deleted');
  //     clearForm();
  //     fetchBlogs();
  //     onClose();
  //   } catch (error) {
  //     toastError(error, 'deleting');
  //   }
  // };


  const handleDeleteInitiate = () => {
    setDialogContext('deleteBlogConfirmation'); // Set context to show delete confirmation dialog
    onOpen(); // Open the AlertDialog
  };
  
  const confirmAction = () => {
    onClose(); // Always close the dialog first
    if (dialogContext === 'unsavedChanges' || dialogContext === 'editConfirmation') {
      loadBlog();
    } else if (dialogContext === 'newBlog') {
      clearForm();
    } else if (dialogContext === 'deleteBlogConfirmation') {
      handleActualDelete(); // Proceed with deletion after confirmation
    }
  };
  
  const handleActualDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/api/blogs/${slug}`);
      toastSuccess('deleted');
      clearForm();
      fetchBlogs();
    } catch (error) {
      toastError(error, 'deleting');
    }
  };

  const toastSuccess = (action) => {
    toast({
      title: `Blog ${action}`,
      description: `The blog has been successfully ${action}.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const toastError = (error, action) => {
    toast({
      title: `Error ${action} blog`,
      description: error.toString(),
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  // const confirmAction = () => {
  //   onClose(); // Always close the dialog first
  //   if (dialogContext === 'unsavedChanges' || dialogContext === 'editConfirmation') {
  //     loadBlog();
  //   } else if (dialogContext === 'newBlog') {
  //     clearForm();
  //   } else if (dialogContext === 'deleteBlog') {
  //     handleDelete();
  //   }
  // };

  return (
    userData && userData.Account_type === 'blogger' ? (
    <>
      
      <Button colorScheme="teal" onClick={handleNewBlog} m={4}>New Blog</Button>
      <Flex>
        <VStack align="stretch" w="250px" borderRight="1px" borderColor="gray.200" p="4">
          {blogs.map(blog => (
            <Box key={blog.slug} p="2" _hover={{ bg: 'gray.100' }} cursor="pointer" onClick={() => handleBlogSelect(blog)}>
              <Text isTruncated>{blog.title}</Text>
              <Text fontSize="xs">{blog.published ? 'Published' : 'Draft'}</Text>
            </Box>
          ))}
        </VStack>
        <Box flex="1" p="6">
          <Heading as="h2" size="lg">Create/Edit Blog</Heading>
          <Input placeholder="Blog Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          <Input placeholder="Blog Slug" value={slug}  onChange={(e) => setSlug(e.target.value)}/>
          <Button onClick={() => setSlug(title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''))}>Generate Slug</Button>
          <Input placeholder="Cover Image URL" value={coverImage} onChange={(e) => setCoverImage(e.target.value)} />
          <Input placeholder="Meta Title" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
          <Input placeholder="Meta Description" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
          {/* <Editor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  wrapperClassName="rich-text-editor-wrapper"
  editorClassName="rich-text-editor"
  toolbarClassName="rich-text-toolbar"
  blockRendererFn={blockRendererFn}
/> */}
          <TinyMCEEditor
        initialValue="<p>This is the initial content of the editor</p>"
        apiKey="vhcahhkefojnlwwasl5m7ofkxn9dauqpa3ii8kkge4um1nqg"
        value={content}
        onEditorChange={(newContent) => setContent(newContent)}
        init={{
          height: 500,
          menubar: true, // Enables the menu bar
          plugins: [
            'advlist autolink link image lists charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
            'table emoticons template paste help',
            'media embed codesample',
            'fullscreen',
            'link',
            'lists',
            'textpattern','pageembed', 'image', 'table'
          ],
          toolbar: 'undo redo | styleselect | bold italic underline | alignleft aligncenter ' +
                   'alignright alignjustify | bullist numlist outdent indent | link image media | pageembed | image ' +
                   'forecolor backcolor emoticons | codesample fullscreen | ' +
                   'formatselect fontselect fontsizeselect' +
                   'table | tabledelete | tableprops tablerowprops tablecellprops | ' + // Adding table controls to the toolbar
                   'tableinsertrowbefore tableinsertrowafter tabledeleterow | ' +
                   'tableinsertcolbefore tableinsertcolafter tabledeletecol',
          style_formats: [ // This is optional, allows you to define custom styles
            { title: 'Headers', items: [
              { title: 'Header 1', format: 'h1' },
              { title: 'Header 2', format: 'h2' },
              { title: 'Header 3', format: 'h3' },
              { title: 'Header 4', format: 'h4' },
              { title: 'Header 5', format: 'h5' },
              { title: 'Header 6', format: 'h6' }
            ]},
            { title: 'Inline', items: [
              { title: 'Bold', icon: 'bold', format: 'bold' },
              { title: 'Italic', icon: 'italic', format: 'italic' },
              { title: 'Underline', icon: 'underline', format: 'underline' },
              { title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough' },
              { title: 'Superscript', icon: 'superscript', format: 'superscript' },
              { title: 'Subscript', icon: 'subscript', format: 'subscript' },
              { title: 'Code', icon: 'code', format: 'code' }
            ]},
            { title: 'Blocks', items: [
              { title: 'Paragraph', format: 'p' },
              { title: 'Blockquote', format: 'blockquote' },
              { title: 'Div', format: 'div' },
              { title: 'Pre', format: 'pre' }
            ]},
            { title: 'Alignment', items: [
              { title: 'Left', icon: 'alignleft', format: 'alignleft' },
              { title: 'Center', icon: 'aligncenter', format: 'aligncenter' },
              { title: 'Right', icon: 'alignright', format: 'alignright' },
              { title: 'Justify', icon: 'alignjustify', format: 'alignjustify' }
            ]}
          ],
          // The following options allow images to be uploaded and handled on the server
          /* images_upload_url: 'POST_UPLOAD_URL_HERE', // The URL to which images are uploaded
          images_upload_handler: function (blobInfo, success, failure) {
            // Your image upload handling here
          }, */
          textpattern_patterns: [ // Enables automatic replacement of text patterns
            {start: '*', end: '*', format: 'italic'},
            {start: '**', end: '**', format: 'bold'},
            {start: '#', format: 'h1'},
            {start: '##', format: 'h2'},
            {start: '###', format: 'h3'},
            {start: '####', format: 'h4'},
            {start: '#####', format: 'h5'},
            {start: '######', format: 'h6'},
            {start: '1. ', cmd: 'InsertOrderedList'},
            {start: '* ', cmd: 'InsertUnorderedList'},
            {start: '- ', cmd: 'InsertUnorderedList'}
          ],
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' // Optional content style
        }}
      />
          <Button colorScheme="blue" onClick={() => handleSaveOrUpdate(false)}>Save</Button>
          <Button colorScheme="green" onClick={() => handleSaveOrUpdate(true)}>Publish</Button>
          {slug && <Button colorScheme="red" ml="4" onClick={handleDeleteInitiate}>Delete</Button>}
          {/* <Input placeholder="Image URL or youtbe embed" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} my="2" /> */}
  {/* <Input placeholder="Image Alt Text" value={imageAlt} onChange={(e) => setImageAlt(e.target.value)} my="2" />
  <Button colorScheme="blue" onClick={insertMediaToEditor }>Insert Image</Button>
 
<Button colorScheme="blue" onClick={() => insertMediaToEditor('youtube')}>Insert YouTube Video</Button> */}



        </Box>

      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {dialogContext === 'unsavedChanges' ? 'Edit Blog' : dialogContext === 'newBlog' ? 'New Blog' : 'Delete Blog'}
            </AlertDialogHeader>
            <AlertDialogBody>
              {dialogContext === 'unsavedChanges' ? 'Do you want to edit this blog? Your unsaved changes will be lost.' :
              dialogContext === 'deleteBlogConfirmation' ? 'Are you sure you want to delete this blog?' :
               dialogContext === 'newBlog' ? 'Do you want to discard the current blog and start a new one?' :
               'Are you sure you want to edit this blog?'
               }
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button colorScheme="red" onClick={confirmAction} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
     ) : null
  );
};

export default AdminPage;