import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

function ThankYouModal({ isOpen }) {
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const cancelRef = React.useRef();

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">Thank You!</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody textAlign="left">
          <Text mb={4}>
            Your tool submission is <Text as="span" fontWeight="bold">complete</Text>! We will review your submission within the next 1-7 days. Once approved, your tool will go live, and we will notify you accordingly.
          </Text>
          <Text>
            Should you require further assistance or need to provide additional details, please don't hesitate to reach out to us at <Text as="span" fontWeight="bold">hello@aizones.io</Text>. Thank you for choosing <Text as="span" fontWeight="bold">AI ZONES</Text>!
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={handleClose}>
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ThankYouModal;
