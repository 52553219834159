import React from 'react'
import { Submissioncomp } from '../Components/Submission'

export const Submission = () => {
  return (
    <>
      <Submissioncomp/>
    </>
  )
}
