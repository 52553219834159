import {
  Box,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Spinner,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "../../Style/Tool.module.css";
import { useSnapCarousel } from "react-snap-carousel";
// import nocover from "../../Utils/NO COVER .png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useLocation } from 'react-router-dom';






// Custom Next Arrow component
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: "initial !important" }}
      onClick={onClick}
    />
  );
};

// Custom Prev Arrow component
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: "initial !important" }}
      onClick={onClick}
    />
  );
};

export const Overview = ({ el, id }) => {
  useSnapCarousel(true);
  //console.log(el,"why is here.... bro wh");
  let [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");

  const [imageLoadingStatus, setImageLoadingStatus] = useState({});

  // Function to update image loading status
  const handleImageLoad = (imageUrl) => {
    setImageLoadingStatus(prevStatus => ({ ...prevStatus, [imageUrl]: true }));
  };

  // Inside your component
const location = useLocation();

useEffect(() => {
  // Reset the spinner to its initial state here
  setIsLoading(true);

  // Perform any additional cleanup if necessary

}, [location]);
 

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  useEffect(() => {
    library.add(fas)
    let gallery = [];

    if (el.Cover_image && el.Cover_image !== "") {
      gallery.push(el.Cover_image);
    }
    if (el.Youtube_embed && el.Youtube_embed !== "") {
      gallery.push(el.Youtube_embed);
    }
    if (el.Galary_image && el.Galary_image.length > 0) {
      const filteredGalaryImages = el.Galary_image.filter(image => image !== "");
      gallery = [...gallery, ...filteredGalaryImages];
    }
    console.log(gallery);
    setData(gallery);
    setTimeout(()=> {
      setIsLoading(false);
    },)
  }, [el]);

  const hasCoverOrVideo = data.some(
    (e) => e.includes && (e.includes("youtube") || e === el.Cover_image)
  );

  const fdata = data
    .filter((item) => item.includes("youtube"))
    .concat(data.filter((item) => !item.includes("youtube")));
  
  // Fullscreen view ****************************************************
  useState(false);

  const sliderDiv = document.getElementById("slider")
  return (
    <Box>
      <Box id="slider" w={{ base: "fit-content", sm: "100%", md: "100%", lg: "800px" }}
            alignItems="start"
            justifyContent={"start"}      
            position={"relative"}>
        {!isLoading ? (
        
          <Box
         
            position={"relative"}
            // gap="5px"
            // width={"100%"}
            // maxW={{ base: "90vw", sm: "400px", md: "500px", lg: "900px" }}
            maxW={{ base: "100%", sm: "400px", md: "500px", lg: "100%" }}
            // height={"455px"}
          >
            <Box maxW={{ base: "310px", sm: "100%", md: "90%", lg: "90%" }} >

              <Slider {...settings}>
                {fdata?.map((e, i) =>
                  (e && e.includes && e.includes("youtube")) ? (
                    <div key={i} style={{ height: "100%", width: "100%" }}>
                      <Box display={{ base: "block", md: "none" }}>
                     
                        {" "}
                        <iframe
                        allowfullscreen="allowfullscreen"
                          title="youtube"
                          className={style.iframeTool}
                          style={{
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          height={"170px"}
                          width={"100%"}
                          src={e}
                        ></iframe>
                      </Box>

                      <Box display={{ base: "none", md: "block", lg: "none" }}>
                        {" "}
                        <iframe
                        allowfullscreen="allowfullscreen"
                          title="youtube"
                          className={style.iframeTool}
                          style={{
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          height={"250px"}
                          width={"100%"}
                          src={e}
                        ></iframe>
                      </Box>

                      <Box display={{ base: "none", lg: "block" }}>
                        {" "}
                        <iframe
                        allowfullscreen="allowfullscreen"
                          title="youtube"
                          className={style.iframeTool}
                          style={{
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          height={"380px"}
                          width={"100%"}
                          src={e}
                        ></iframe>
                      </Box>
                    </div>
                  ) :  (
                    <>
                     <Box key={i} style={{ 
                          height: "100%", 
                          width: "100%",                          
                          //{ base: "310px", lg: "550px", md: "676px" }, 
                         minWidth: window.innerWidth < 450 ? "310px" : "auto",                  
                         }}>

                       { !imageLoadingStatus[e] && (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <Spinner size="xl" color="blue.500" speed="0.90s" emptyColor="gray.200" />
                      </Box>
                      )}
                           <LazyLoadImage
                           key={i}
                           src={`${e}?height=380`}
                           alt={e}
                           afterLoad={() => handleImageLoad(e)}
                           style={{
                            borderRadius: '10px', // Set the border radius here
                            // Other styles if needed
                            
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow effect
                          }}
                           // onLoad={() => handleImageLoad(e)} // Add this line
                          effect="blur" // Optional: Adds a blur effect while loading
                         // Add other styling or props as needed
                         />
                     {/* <Image key={i} src={`${e}?height=456`} alt={e} /> */}
                     <Text style={{ visibility: "hidden", fontSize: "1px" }}> {e} </Text>
                     </Box>
                    </>
                  )
                )}

                {!hasCoverOrVideo && (
                  <div style={{ width: "100%" }} >
                    <picture borderRadius="5px" h="100%" src={"https://ai-zones.b-cdn.net/Website-utilities%20/NO%20COVER%20.png"}  />
                  </div>
                )}
              </Slider>
            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <Spinner size="xl" color="blue.500" />
      </Box>
        )}
      </Box>

      <Text fontSize="16px" as="h2"lineHeight="24px" fontWeight="600" mt="20px" color={heading} >
        What is {el.Title} ?
      </Text>

      <Text
  fontSize="14px"
  textAlign={{ base: "left", md: "justify" }}
  fontWeight="400"
  lineHeight="24px"
  width={{ base: "100%",md: "100%", lg: "90%"}}
  mt="5px"
  color={font}
  as="h2"
  dangerouslySetInnerHTML={{ __html: el.Description }}
      />

      {el?.key_features?.some(feature => feature.trim() !== "") && (
  <Box mt="20px">
    <Text fontSize="16px" fontWeight="600" as="h2" lineHeight="24px">
      Features
    </Text>
    <UnorderedList
      mt="17px"
      className={style.list}
      fontSize="14px"
      fontWeight="400"
      lineHeight="24px"
      color={font}
      width={{ base: "100%",md: "100%", lg: "90%"}}
      as="h3"
    >
      {[...new Set(el?.key_features)]
        ?.filter(feature => feature.trim() !== "") // Filter out empty strings
        .map((e, i) => (
          <ListItem key={i}>{e}</ListItem>
        ))}
    </UnorderedList>
  </Box>
)}
    </Box>
  );
};