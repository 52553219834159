
// import { useNavigate } from "react-router-dom";

// import { useRef, useState } from "react";
// // import React from "react";
// import {
//   Button,
//   AlertDialog,
//   AlertDialogOverlay,
//   AlertDialogContent,
//   AlertDialogHeader,
//   AlertDialogCloseButton,
//   AlertDialogBody,
//   AlertDialogFooter,
// } from "@chakra-ui/react";

// export default function LoginModel({onCloseOuter = () =>{}}) {
//   const finalRef = useRef(null);
//   const [open, setOpen] = useState(true);

//   const onClose = () => {setOpen(false); onCloseOuter()};

//   const navigate = useNavigate();

//   return (
//     <>
//       <AlertDialog
//         motionPreset="slideInBottom"
//         leastDestructiveRef={finalRef}
//         onClose={() =>{}}
//         isOpen={open}
//         isCentered
//       >
//         <AlertDialogOverlay />
//         <AlertDialogContent>
//           <AlertDialogHeader>Login</AlertDialogHeader>
//           {/* <AlertDialogCloseButton /> */}
//           <AlertDialogBody>
//             <Button onClick={() => navigate("/login")}>Go to login page</Button>
//           </AlertDialogBody>
//           <AlertDialogFooter>
//             <Button ref={finalRef} onClick={onClose}>
//               Close
//             </Button>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>
//     </>
//   );
// }


  import { useRef, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text,
    Link,
    useColorMode,
    Image,
    useColorModeValue,
    Box,
    VStack,
    HStack,
    useBreakpointValue,
    Flex,
  } from "@chakra-ui/react";
  import { FcGoogle } from "react-icons/fc";
  import logodark from "../../Utils/LOGO-AIZONES-black.svg";
  import logolight from "../../Utils/LOGO-AIZONES-white.svg";
  import HandleGoogle from '../../Firebase/handleGoogleAuth';
  import { useDispatch } from 'react-redux';



  export default function LoginModal({ onCloseOuter = () => {} }) {
    const { colorMode } = useColorMode();
    const logo = colorMode === "light" ?  logodark : logolight;
    const navigate = useNavigate();
    const finalRef = useRef(null);
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    const onClose = () => {
      setOpen(false);
      onCloseOuter();
    };

    return (
      <Modal isOpen={open} onClose={onClose} isCentered motionPreset="slideInBottom" size="xl">
        <ModalOverlay />
        <ModalContent mx={4} borderRadius="lg">
          <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">
            <Image src={logo} mx="auto" my={4} />
            AI For Everyone
          </ModalHeader>
          <ModalBody textAlign="center">
            <VStack spacing={4} align="stretch" mb={6} >
              <Box>
                <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                  Discover AI Tools Using AI
                </Text>
                <Text fontSize="sm">AI Search with Large Language Model Integration.</Text>
              </Box>
              <Box>
                <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                  Curated Collections
                </Text>
                <Text fontSize="sm">Explore expert-curated AI tool collections for enhanced project efficiency.</Text>
              </Box>
              <Box>
                <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                  Create Personalized Collection
                </Text>
                <Text fontSize="sm">Organize your AI tools in one place.</Text>
              </Box>
            </VStack>
            <Button
              leftIcon={<FcGoogle />}
              // colorScheme="red"
              size="lg"
              w="full"
              onClick={() => HandleGoogle(dispatch, navigate, onClose)}
              // variant="outline"
            >
              Sign up with Google
            </Button>
            {/* <Text fontSize="sm" mt={4}>
              By signing in, you agree to our <Link color="blue.500" href="/terms" isExternal>Terms of Use</Link> and <Link color="blue.500" href="/privacy" isExternal>Privacy Policy</Link>.
            </Text> */}
             <Text fontSize="sm" mt={4}>
              Already a user? <Link color="blue.500" onClick={() => HandleGoogle(dispatch, navigate, onClose)}>Sign in</Link>
            </Text>
            {/* <Text fontSize="sm" mt={4}>
              Register using email <Link color="blue.500" onClick={() => navigate('/login')}>Click here</Link>
            </Text> */}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }