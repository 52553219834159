import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";


import style from "../../Style/Grid.module.css";

import { CircularProgress, Stack } from "@chakra-ui/react";

import { Modalcomp } from "../Modal";

import axios from "axios";
import { setMyspaceName } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from '../LoadingAnimation';

export const GridPage = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
}) => {
const [allLibrary, setAllLibrary] = useState([]);
  const userData = useSelector((state) => state.userReducer.loginData);
  const dispatch = useDispatch();

  let token = userData.data;
  const getData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/space/get`, {
      headers: { token },
    });

    setAllLibrary(res.data.data);
    // setloading(false);
    dispatch(setMyspaceName(res.data.data));
    console.log(res.data.data);
  };
  useEffect(()=> {
    token && getData()
  }, [])

  return (
    <Stack w="100%">
      <Box className={style.gridbox} w="100%">
        {data
          ?.filter((e) => e.verify)
          .map((el, i) => (
            <Box key={i} >
              <Modalcomp
                el={el}
                i={i}
                setcat={setcat}
                setPageName={setPageName}
                setFilterLoader={setFilterLoader}
              />
            </Box>
          ))}
      </Box>

      {showLoader ? (
        <Box
          height="100px"
          display={"flex"}
          alignItems={"center"}
          textAlign="center"
          justifyContent={"center"}
        >
          <LoadingAnimation size="xl"/>
        </Box>
      ) : null}
    </Stack>
  );
};
