import React from "react";
import style from "../../Style/List.module.css";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ImageBackground } from "../ListModal";
import { BsCaretUpSquare } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Overall = ({ data }) => {

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");
  const bg = useColorModeValue("#ffffff", "#222222");
  const boxShadowColor = useColorModeValue('0px 4px 10px rgba(0, 0, 0, 0.2)', '0px 4px 10px rgba(255, 255, 255, 0.2)');
   
  return (
    <>
      <Flex width={"100%"} flexDirection={"column"} gap={5} paddingBlock={5}>
        {data?.map((el, i) => (
          <Flex
          width={"100%"}
          justifyContent="space-between"
          alignItems="center"
          p={5}
          borderRadius={10}
          bg={bg} // Replace with your color values
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" // Lush shadow
          transition="transform 0.3s, box-shadow 0.3s" 
          _hover={{ transform: "translateY(-5px)", boxShadow: boxShadowColor  }}
          >
            <Flex gap="10px" alignItems="center">
              <Text fontWeight="600" lineHeight="20px" fontSize="14px" color="blue.300">
                #{i + 1}
              </Text>

              <Box marginLeft={2} padding={3}>
                <Link to={`/tool/${el?.slug}`}>
                  {" "}
                  <Box
          display="flex"
          className={style.iconImg2}
          borderRadius="5px"
          justifyContent="center"
          alignItems="center"
        >
          <img       
            style={{ margin: "auto", display: "block", borderRadius: "5px" }}
            className={style.iconImg}
            src={el?.Logo
                ? `${el?.Logo}?height=80`
                : "https://ai-zones.b-cdn.net/Website-utilities%20/NO%20COVER_logo.png?height=80"
            }
            alt={`${el.Title} logo`} 
          />
 
        </Box>
                </Link>
              </Box>

              <Flex flexDirection={"column"} gap={"2px"}>
                <Link to={`/tool/${el?.slug}`}>
                  <Text fontSize="16px" fontWeight="bold" color={heading} >
                    {el?.Title}
                  </Text>
                </Link>

                <Link to={`/tool/${el?.slug}`}>
                <Box display={{ base: "block", md: "none" }}>
                  <Text fontSize={"12px"} color={font}>
                    {`${el?.Tagline}`.length > 55
                      ? `${el?.Tagline}`.substring(0, 55) + "..."
                      : `${el?.Tagline}`}
                  </Text>
                </Box>
                </Link> 
                  
                <Link to={`/tool/${el?.slug}`}>
                <Box display={{ base: "none", md: "block" }}>
                  <Text fontSize="14px"  color={font}>
                    {el?.Tagline}
                  </Text>
                </Box>
                </Link>  
              </Flex>
            </Flex>

            {/* <Box display={{ base: "none", md: "flex" }}>
              <BsCaretUpSquare size={19} />
            </Box> */}
          </Flex>
        ))}
      </Flex>
    </>
  );
};
