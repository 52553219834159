import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LIstcomp } from "./Listcomp";
import { LIstcomp_home } from "./Listcomp_home";
import { Gridcomp } from "./gridcomp";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsGrid } from "react-icons/bs";
import { CgMenuGridO } from "react-icons/cg"; 
import { SpaceLike } from "./spaceLike";

export const LikeBoard = () => {
  const [grid, setgrid] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [show, setShow] = useState(true);
  return (
    <Box
  width={{ base: "100%", md: "100%" }}
  p={{ base: "2", md: "4" }}
  display="flex"
  // flexDirection="column" // Use 'column' to stack children vertically
 justifyContent="center" // Center horizontally in the flex container
  //alignItems="center" // Center vertically in the flex container
>
      <Tabs mt="20px" position="sticky">
        <TabList
          border="none"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          fontSize="16px"
          fontWeight="600"
          lineHeight={"24px"}
        >
         <Flex gap="20px" >
            <Tab onClick={() => setShow(true)} bg="none" border="1px solid " borderRadius="5px">
              Tools
            </Tab>
            <Tab onClick={() => setShow(false)} bg="none" border="1px solid " borderRadius="5px">
              Collection
            </Tab>
          </Flex>

          {show && (
            <Flex gap="20px">
              <AiOutlineUnorderedList
                onClick={() => setViewMode('list')}
                size={20}
                cursor="pointer"
              />
              <BsGrid
                onClick={() => setViewMode('grid')}
                size={20}
                cursor="pointer"
              />
              <CgMenuGridO
                onClick={() => setViewMode('smallGrid')}
                size={20}
                cursor="pointer"
              />
            </Flex>
          )}
        </TabList>

        <TabPanels>
        <TabPanel>
            {viewMode === 'grid' && <Gridcomp />}
            {viewMode === 'list' && <LIstcomp />}
            {viewMode === 'smallGrid' && <LIstcomp_home />}
          </TabPanel>
          <TabPanel>
            <SpaceLike />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};