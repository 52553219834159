import React, { useState, useEffect } from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Input, Stack, Text, Image, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import notification from '../Toast'; // Assuming this is your custom notification component
import { setUserLogin } from '../../Redux/action';

export default function ProfileForm() {
  const userData = useSelector((state) => state.userReducer.loginData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast(); // Using Chakra UI's useToast for notifications
  const token = userData.data;
  useEffect(() => {
    if (!userData || userData.Account_type !== 'expert') {
      toast({
        title: 'Access denied.',
        description: 'You must be an expert to access this page.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      navigate('/'); // Redirect to home or login page
    }
  }, [userData, navigate, toast]);

  const [reavatar, setReAvatar] = useState(userData.image);
  const [input, setInput] = useState({
    name: userData.name,
    Channel_description: userData.Channel_description || '',
    Social_media: userData.Social_media || [''],
  });

  const handleImageUpload = async (event) => {
    const image = event.target.files[0];
    const dataImg = new FormData();
    dataImg.append('file', image);
    dataImg.append('upload_preset', process.env.REACT_APP_PRESET);
    dataImg.append('cloud_name', process.env.REACT_APP_CLOUD_NAME);

    try {
      const { data } = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        dataImg
      );
      setReAvatar(data.url);
    } catch (error) {
      notification('error', 'Image upload failed');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSocialMediaChange = (index, value) => {
    const updatedSocialMedia = [...input.Social_media];
    updatedSocialMedia[index] = value;
    setInput((prev) => ({
      ...prev,
      Social_media: updatedSocialMedia,
    }));
  };

  const addSocialMediaInput = () => {
    setInput((prev) => ({
      ...prev,
      Social_media: [...prev.Social_media, ''],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Prepare the payload
      let payload = {
        ...input,
        image: reavatar,
      };
  
      // Send the update request to the backend
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/api/users/update`,
        payload,
        { headers: { token } }
      );
  
      // Handle successful update
      if (response.data) {
        // Update local state or Redux store as necessary
        dispatch(setUserLogin({
          ...userData,
          ...payload,
        }));
  
        // Optionally, navigate to a different route or show success notification
        toast({
          title: 'Profile updated.',
          description: "Your profile has been successfully updated.",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
  
        // For example, navigate to the profile page or reload the current page to reflect changes
        // navigate('/profile');
      }
    } catch (error) {
      // Handle errors, e.g., show notification with error message
      toast({
        title: 'Update failed.',
        description: error.response.data.error || "An error occurred while updating your profile.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    userData && userData.Account_type === 'expert' ? (
      <Stack w="50%"spacing={4} border="1px solid #e0e0e0" p={8} borderRadius="lg" alignContent="center">
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input value={input.name} name="name" onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Channel Description</FormLabel>
          <Input value={input.Channel_description} name="Channel_description" onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Profile Picture</FormLabel>
          <Flex alignItems="center">
            <Image boxSize="100px" src={reavatar} borderRadius="full" />
            <Input type="file" onChange={handleImageUpload} ml={4} />
          </Flex>
        </FormControl>

        <Text> Social Media:</Text>
        {input.Social_media.map((url, index) => (
          <FormControl key={index}>
            <Input value={url} onChange={(e) => handleSocialMediaChange(index, e.target.value)} placeholder="Social media URL" />
          </FormControl>
        ))}
        <Button onClick={addSocialMediaInput} mt={2}>Add more social media</Button>

        <Button colorScheme="blue" onClick={handleSubmit}>Save</Button>
      </Stack>
    ) : null
  );
}
