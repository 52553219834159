import React from "react";
import { Featured } from "./Featured";
import { TopReview } from "./TopReview";
import { CurtedCollection } from "./CurtedCollection";
import BlogUpdates from "./Updates";
import { Box, Divider, useColorModeValue } from "@chakra-ui/react";

import style from "../Style/Featured.module.css";
import { Tranding } from "./Tranding";
import { IconandPrivacy } from "./IconandPrivact";
import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom

export const LandingRight = () => {

  const dividerColor = useColorModeValue("#e0e0e0", "#444");

  const location = useLocation(); // Get the current location
  const isHomepage = location.pathname === "/"; // Check if it's the homepage
  const isBlogPage = location.pathname.startsWith('/updates/');
  return (
    <Box className={style.landingRight} >
 <> <Tranding />
 <Divider
        mt="30px"
        mb="30px"
        border="1px"
        borderColor={dividerColor}
      />

{!isHomepage && !isBlogPage && <BlogUpdates />}
      {isHomepage ? null : <CurtedCollection />} {/* Conditionally render CurtedCollection */}
      {/* <Divider
        mt="30px"
        mb="30px"
        border="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
      /> */}
    
      {/* {!isBlogPage && ( */}
      {!isBlogPage && (
      <Featured />
      )}
       
     
      {/* <TopReview /> */}
    
      </>
      {/* )} */}
      <IconandPrivacy />
    </Box>
  );
};
