import { Box, Divider, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from "react-router-dom";
// import TwitterXIcon from "../Utils/TwitterXIcon.png";


export const IconandPrivacy = () => {

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");
  return (
    <Box color={heading} fontSize="30px">
      <Flex w="100%" gap="20px">
        <a to="https://www.instagram.com/aizones_io/" target="_blank">
          <FaInstagram />
        </a>

        <a to="https://twitter.com/Aizones_io" target="_blank">
          <Box>
          <FaTwitter/>
          </Box>
        </a>

        <a to="https://www.linkedin.com/company/ai-zones" target="_blank">
          <Box>
          <FaLinkedin/>
          </Box>
        </a>

      </Flex>
      <Divider
        mt="30px"
        mb="30px"
        border="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
      />

      <Box fontSize="12px" mt="30px" color={heading} fontWeight="400">
        <Link to="/privacy">
          <Text>Terms : Privacy and Cookies ·</Text>
        </Link>
        <Text
          textTransform="uppercase"
          mt="13px"
          fontSize="10px"
          lineHeight="normal"
          color={heading}
        >
          © 2024 AI ZOnes
        </Text>
      </Box>
    </Box>
  );
};
