// LoadingSpinner.jsx
import React from 'react';
import { Spinner, Center } from '@chakra-ui/react';

const LoadingAnimation = ({ size = 'md' }) => {
  return (
    <Center h="100vh">
      <Spinner
        thickness="3px"
        speed="0.90s"
        emptyColor="gray.200"
        color="blue.500"
        size={size}
      />
    </Center>
  );
};

export default LoadingAnimation;