import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";

import React from "react";
import { CgSortAz } from "react-icons/cg";



export const Sort = ({ setSort, setPageName, setSortLoader, setUserInteracted }) => {
  
  const mostLikeHandler = () => {
    setSort("mostlike");
    setPageName("sort");
    setSortLoader((prev) => !prev);
    setUserInteracted(true);
  };
  const aTozhandler = () => {
    setSort("a-z");
    setPageName("sort");
    setSortLoader((prev) => !prev);
    setUserInteracted(true);
  };
  const newDatahandler = () => {
    setSort("newdataadd");
    setPageName("sort");
    setSortLoader((prev) => !prev);
    setUserInteracted(true);
  };

  const bg=useColorModeValue("#ffffff", "#222222");

  return (
    <Box  px={5}>
      <Menu>
        <MenuButton
          // _hover={{ bg: "transparent" }}
          border="1px"
          borderColor={useColorModeValue("#e0e0e0", "#444")}
          borderRadius="5px"
          size="xs"
          bg={bg}
          boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"
         
        >
          <Flex borderRadius="3px" alignItems="center" gap="5px" px="5px">
            {" "}
            Sort <CgSortAz fontSize={20} fontWeight="bold" />
          </Flex>
        </MenuButton>

        <MenuList>
          <MenuItem onClick={newDatahandler}>Newest</MenuItem>
          <MenuItem onClick={aTozhandler}>Top rated</MenuItem>
          <MenuItem onClick={mostLikeHandler}>Most liked</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
