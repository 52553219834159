import * as React from "react";
import chatgpt from "../Utils/ChatGPT.svg";
import { Spinner } from "@chakra-ui/react";

import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import style from "../Style/List.module.css";
import { BsClockHistory } from "react-icons/bs";
import visit from "../Utils/Visit.svg";
import Modal from "@mui/material/Modal";
import { CloseIcon } from "@chakra-ui/icons";
import { Telement } from "./Tool/Telement";
import { Link, json } from "react-router-dom";
import ShareModel from "./Share";
import { Likes } from "./Likes";
import { MdOutlineVerified } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import Save_home from "./Home/Save_home";
import excel from "../Utils/Spreadsheet_icon.svg";
import sopify from "../Utils/sopify.svg";
import vscode from "../Utils/Vs code.svg";
import figma from "../Utils/Figma.svg";
import github from "../Utils/Github.svg";
import mobile from "../Utils/Mobile app.svg";
import slack from "../Utils/Slack.svg";
import web from "../Utils/Web.svg";
import browser from "../Utils/Browser Extension.svg";
import Wordpress from "../Utils/Wordpress.svg";
import { useState, useEffect, useRef } from "react";
import { AiFillDollarCircle, AiFillGift } from "react-icons/ai";
// import SaveButtonForCollections from "./Home/SaveButtonForCollections";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import {toolspagetitle} from "../Pages/Tools-page-title";


// export const ImageBackground = ({ imageUrl }) => {
//   const [oWidth, setOriginalWidth] = useState(null);
//   const [oHeight, setOriginalHeight] = useState(null);



  
//   const handleImageLoad = (event) => {
//     const imgElement = event.target;
//     setOriginalWidth(imgElement.naturalWidth);
//     setOriginalHeight(imgElement.naturalHeight);
//   };

 
//   return (
//     <>
//       {oHeight < 80 && oWidth < 80 ? (
//         <Box
//           display="flex"
//           className={style.iconImg2}
//           borderRadius="5px"
//           justifyContent="center"
//           alignItems="center"
//         >
//           <img
//             onLoad={handleImageLoad}
//             style={{ margin: "auto", display: "block", borderRadius: "5px" }}
//             className={style.iconImg}
//             src={imageUrl}
//             alt="Logo"
//           />
//         </Box>
//       ) : (
//         <Box
//           display="flex"
//           className={style.iconImg2}
//           borderRadius="5px"
//           justifyContent="center"
//           alignItems="center"
//         >
//           <img
//             onLoad={handleImageLoad}
//             style={{ margin: "auto", display: "block", borderRadius: "5px" }}
//             width="100%"
//             src={imageUrl}
//             alt="Logo"
//           />
//         </Box>
//       )}
//     </>
//   );
// };


const updateDocumentTitleAndMeta = (data) => {
  const title = toolspagetitle(data);
  document.title = title;
};



export const ListModal = ({ el, i, setcat, setFilterLoader, setPageName }) => {
  const [open, setOpen] = React.useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const prevUrl = useRef(window.location.pathname);
  const [loading, setLoading] = React.useState(false); // New state for loading spinner
  const [initialState, setInitialState] = useState({ url: '', title: '' });

  useEffect(() => {
    setInitialState({ 
      url: window.location.pathname + window.location.search, // Include query parameters
      title: document.title 
    });
  }, []);



  useEffect(() => {
    const currentPath = location.pathname;
    const shouldOpenModal = currentPath.includes(`/tool/${el?.slug}`);
    if (shouldOpenModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location, el?.slug]);

  const handleOpen = () => {
    setLoading(true);
    // Update prevUrl to include query parameters
    prevUrl.current = window.location.pathname + window.location.search;
    updateDocumentTitleAndMeta(el);
    window.history.pushState({}, '', `/tool/${el?.slug}`);


    // Simulate a delay or wait for an async operation
    setTimeout(() => {
      setOpen(true);
      setLoading(false); // Deactivate spinner once the modal is ready
    },); // Adjust this timeout as needed
  };


  const handleClose = () => {
    setOpen(false);
    document.title = initialState.title;
    // Use initialState.url which includes query parameters
    window.history.pushState({}, '', initialState.url);
  };  
  

  // Detect browser back button press to close the modal
  useEffect(() => {
    const handlePopState = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [open]);

  let url = `${window.location.origin}/tool/${el?.slug}`;
  // let url = `${process.env.REACT_APP_API}/tool/${el?._id}`;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");
  const boxShadowColor = useColorModeValue('0px 4px 10px rgba(0, 0, 0, 0.2)', '0px 4px 10px rgba(255, 255, 255, 0.2)');
  return (
    <>

{loading && (
        <Box position="fixed" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner thickness="3px"
        speed="0.90s"
        emptyColor="gray.200"
        color="blue.500"
        size={"xl"} />
        </Box>
      )}
    
    <Stack w="100%" mb="20px" >
      <Flex
        width={"100%"}
        border={"1px"}
        borderColor={colorMode === "light" ? "#e0e0e0e6" : "#333333"}
        borderRadius={"7px"}
        paddingX={{ base: "12px", md: "25px" }}
        paddingY={{ base: "10px", md: "15px" }}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        bg={useColorModeValue("#ffffff", "#222222")}
        boxShadow={"0 2px 4px rgba(0,0,0,0.1)"} 
        transition="transform 0.3s, box-shadow 0.3s" 
      _hover={{ transform: "translateY(-3px)",boxShadow: boxShadowColor }}
   
      >
        <Flex alignItems={"flex-start"} gap={{ base: 3, md: 5 }}>
        <a 
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
      >
          <Flex mt={"6px"} 
          cursor={"pointer"} 
          onClick={handleOpen} 
         
          >
          <Box
          display="flex"
          className={style.iconImg2}
          borderRadius="5px"
          justifyContent="center"
          alignItems="center"
        >
          <img  
           loading="lazy"     
            style={{ margin: "auto", display: "block", borderRadius: "5px" }}
            className={style.iconImg}
            src={el?.Logo
                ? `${el?.Logo}?height=80`
                : "https://ai-zones.b-cdn.net/Website-utilities%20/NO%20COVER_logo.png?height=80"
            }
            alt={`${el.Title} logo`} 
          />
 
        </Box>
          </Flex>
          </a>

          <Flex flexDirection={"column"} gap={"5px"} >
          <a 
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
         >
            <Flex alignItems="center" justifyContent={"flex-start"}>
              <Text
                
                fontSize="15px"
                lineHeight="24px"
                fontWeight="600"
                className={style.title}
                cursor={"pointer"}
                onClick={handleOpen}
                color={heading}
                as="h2"
              >
                {el?.Title}
              </Text>
              { el?.featured && (
                <Text
                  ml="9px"
                  // textAlign="start"
                  // justifyContent="start"
                  w="fit-content"
                  px="5px"
                  py="1px"
                  bg="linear-gradient(134deg, #4283B0 8.39%, #F31F92 70.00%)"
                  borderRadius="3px"
                  fontSize="12px"
                  color="#EEE"
                  lineHight="23px"
                  fontWeight="400"
                >
                  Featured
                </Text>
              )}
            </Flex>
            </a>

            <Flex
              flexDirection={{ base: "column-reverse", md: "column-reverse" }}
              gap={"10px"}
            >
              <Flex alignItems={"center"} gap={2}>
              {el?.rating > 0 && (              
              <Flex alignItems={"center"} gap={1}>
                <FaStar size={10} color={"#ECBA67"} />
                <Text fontSize={"13px"}>{el?.rating}</Text>
              </Flex>
                )}

                <Box
                  paddingRight="8px"
                  paddingLeft="8px"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="16px"
                  borderLeft="1px"
                  borderRight="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                >
                  {el?.Pricing === "Free" ? (
                    <Flex gap="7px" alignItems="center">
                      <AiFillGift size={10} />
                      <Text fontSize={"12px"}>{el?.Pricing}</Text>
                    </Flex>
                  ) : el?.Pricing === "Free trial" ? (
                    <Flex gap="7px" alignItems="center">
                      <BsClockHistory size={11} />
                      <Text fontSize={"12px"}>{el?.Pricing}</Text>
                    </Flex>
                  ) : el?.Pricing === "Freemium" ? (
                    <Flex gap="7px" alignItems="center">
                      <MdOutlineVerified size={11} />
                      <Text fontSize={"12px"}>{el?.Pricing}</Text>
                    </Flex>
                  ) : el?.Pricing === "Paid" ? (
                    <Flex gap="7px" alignItems="center">
                      <AiFillDollarCircle size={11} />
                      <Text fontSize={"12px"}>{el?.Pricing}</Text>
                    </Flex>
                  ) : (
                    ""
                  )}
                </Box>

                {el?.price_amount && (
                  <Box
                    paddingRight="8px"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    borderRight="1px"
                    borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"}
                  >
                    {el?.price_amount}
                  </Box>
                )}

                <Flex  alignItems={"center"}>
                  {el?.works_with && el?.works_with.map((e, i) => (
                    <Box key={i} 
                    // padding="1px"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    // borderRight="1px"
                    borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"} >
                    
                    {e.includes("Spreadsheet") ? (
                        <img
                          alt="Spreadsheet logo"
                          borderRadius="4px"
                          boxSize="12px"
                          src={excel}
                        />
                      ) : e.includes("Chatgpt(Plugin)") ? (
                        <img
                          alt="Chatgpt(Plugin) logo"
                          boxSize="12px"
                          width={"80%"}
                          src={chatgpt}
                        />
                        ) : e.includes("Web") ? (
                          <img
                            alt="Web logo"
                            boxSize="12px"                           
                            width={"13px"}
                            src={web}
                          />
                      ) : e.includes("VS Code") ? (
                        <img
                          alt="VS Code logo"
                          boxSize="12px"
                          width={"80%"}
                          src={vscode}
                        />
                      ) : e.includes("Github") ? (
                        <img
                          alt="Github logo"
                          boxSize="12px"
                          width={"80%"}
                          src={github}
                        />
                      ) : e.includes("Mobile app") ? (
                        <img
                          alt="Mobile app logo"
                          boxSize="12px"
                          width={"80%"}
                          src={mobile}
                        />
                      ) : e.includes("Wordpress") ? (
                        <img
                          alt="Wordpress logo"
                          boxSize="12px"
                          width={"80%"}
                          src={Wordpress}
                        />
                      ) : e.includes("Figma") ? (
                        <img
                          alt="Figma logo"
                          boxSize="12px"
                          width={"80%"}
                          src={figma}
                        />
                      ) : e.includes("Browser Extension") ? (
                        <img
                          alt="Browser Extension logo"
                          boxSize="12px"
                          width={"80%"}
                          src={browser}
                        />
                      ) : e.includes("Slack") ? (
                        <img
                          alt="Slack logo"
                          boxSize="12px"
                          width={"80%"}
                          src={slack}
                        />
                      ) : e.includes("Shopify") ? (
                        <img
                          alt="Shopify logo"
                          boxSize="12px"
                          width={"80%"}
                          src={sopify}
                        />
                      ) : (
                        ""
                      )}
                    </Box>                    
                  ))}

                    
           {el?.Category && el?.Category.length > 0 && (
            
        < Flex alignItems={"center"}  display={{ base: "none", md: "flex" }}>
        {/* Category 1 */}
        <Text
            px="1"
            fontSize="12px"
            fontWeight="400"
            lineHeight="20px"
            color={colorMode === "light" ? "blue.500" : "blue.400"} 
            cursor="pointer"
          onClick={() => {
            setcat(el?.Category[0]);
            setPageName("filter");
            setFilterLoader((prev) => !prev);
          }}
        >
          #{el?.Category[0]}
        </Text>

        {/* Space between categories */}
        {el?.Category.length > 1 && (
      <Box display={{ base: "none", md: "block", lg: "none" }}>
        <Text 
         fontSize="12px"
         fontWeight="400"
         lineHeight="20px"
         color={colorMode === "light" ? "blue.500" : "blue.400"} 
         cursor="pointer"
          px="1"
          onClick={() => {
            setcat(el?.Category[1]);
            setPageName("filter");
            setFilterLoader((prev) => !prev);
          }}
          >
          +1
        </Text>
      </Box>
    )}

        {/* Category 2 */}
        {el?.Category.length > 1 && (
      <Box display={{ base: "none", lg: "block" }}>
          <Text
            fontSize="12px"
            fontWeight="400"
            lineHeight="20px"
            color={colorMode === "light" ? "blue.500" : "blue.400"} 
            cursor="pointer"
            px="1"
            onClick={() => {
              setcat(el?.Category[1]);
              setPageName("filter");
              setFilterLoader((prev) => !prev);
            }}
          >
            #{el?.Category[1]}
          </Text>
          </Box>
        )}
        
      </Flex>
      
    
  )}

            
                </Flex>
              </Flex>

              <Box display={{ base: "block", md: "none" }}cursor={"pointer"} onClick={handleOpen}>
            <Text as="h3" fontSize={"12px"} color={useColorModeValue("gray.600", "gray.400")}>
          {`${el?.Tagline}`.length > 50
          ? `${el?.Tagline}`.substring(0, 50) + "..."
             : `${el?.Tagline}`}
            </Text>
           </Box>

           {/* Tagline for medium screens (768px to 999px) */}
       <Box display={{ base: "none", md: "block", lg: "none" }} cursor={"pointer"} onClick={handleOpen}>
     <Text as="h3" fontSize={"15px"} color={useColorModeValue("gray.600", "gray.400")}>
         {`${el?.Tagline}`.length > 50
      ? `${el?.Tagline}`.substring(0, 50) + "..."
      : `${el?.Tagline}`}
         </Text>
        </Box>

         {/* Tagline specifically for lg screens (1000px and above) */}
      <Box display={{ base: "none", lg: "block" }} cursor={"pointer"} onClick={handleOpen}>
       <Text  as="h3" fontSize={"15px"} color={useColorModeValue("gray.600", "gray.400")}>
    {`${el?.Tagline}`.length > 90
      ? `${el?.Tagline}`.substring(0, 90) + "..."
      : `${el?.Tagline}`}
      </Text>
          </Box>


            </Flex>            
          </Flex>
        </Flex>

        {/* <Box
          display={{ base: "flex", md: "none"}}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"50px"}
          paddingLeft={5}
        >
          <Flex gap={"70px"} alignItems={"center"}>
            
            <a href={el?.URL} target="_blank"  >
      <Box position="relative" ml={4}  mb={1}>
        <ExternalLinkIcon color="blue.300" boxSize={4} />
      </Box>
    </Link>
    </Flex>
    </Box> */}

        <Box
          display={{ base: "none", md: "flex"}}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"50px"}
          paddingLeft={5}
        >
          <Flex gap={"70px"} alignItems={"center"}>
            <ShareModel url={url} />
            <a href={el?.URL} target="_blank"  >
      <Box position="relative" ml={4}  mb={1}>
        <ExternalLinkIcon color="blue.300" boxSize={4} />
      </Box>
    </a>
          </Flex>
          <Flex gap={"70px"} alignItems={"center"} mt={-9}>
            <Flex fontSize="15px" alignItems="center" mt={2} flexDirection="row">
              <Likes el={el} />
              <Text fontSize="12px" px="2px" lineHight="25px" fontWeight="400">
                {" "}
                {el?.likes}
              </Text>
            </Flex>

            {window.innerWidth > 750 && (
                <Box mt="15px" /* Adjust the value as needed */>
                  <Save_home el={el}/>
                    </Box>
                    )}
          </Flex>
        </Box>

        <Box display={{ base: "flex", md: "none" }}> 
          <Menu >             
           <Flex direction="column" align="center" justify="end" h="full">
          <Box mb="10">
    <a href={el?.URL} target="_blank"  >
      <ExternalLinkIcon color="blue.300" boxSize="4" mb="2" />
    </a>
   </Box>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BiDotsVerticalRounded size={25} />}
              variant="outline"
              border={"none"}
              size={0}
              mt="3px"             
             
              _hover={{ backgroundColor: "" }}
              _active={{ backgroundColor: "" }}
            />
            </Flex>
            <MenuList
              padding={3}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
            >
              <Flex
                alignItems="center"
                gap="5px"
                fontSize="12px"
                fontWeight="400"
                lineHeight="20px"
              >
                <Text
                  textAlign="center"
                  border="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                  px="10px"
                  borderRadius="12px"
                  cursor="pointer"
                  onClick={() => {
                    // setcat((pre) => [...pre, el?.Category[0]]);
                    setcat(el?.Category[0]);

                    setPageName("filter");
                    setFilterLoader((prev) => !prev);
                  }}
                >
                  {el?.Category[0]}
                </Text>
                <Text
                  display={el?.Category.length > 1 ? "block" : "none"}
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="20px"
                  border="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                  px="10px"
                  borderRadius="12px"
                  cursor="pointer"
                >
                  + {el?.Category.length - 1}
                </Text>
              </Flex>
              <MenuItem _hover={{ backgroundColor: "inherit" }} py={0} px={0}>
                <a href={el?.URL} target="_blank"  style={{ width: "100%" }}>
                  <Button
                    width={"100%"}
                    h="29.68px"
                    color="white"
                    borderRadius="5px"
                    fontSize="14px"
                    fontWeight="400"
                    gap="5px"
                    bg="#3B89B6"
                    _hover={{ bg: "" }}
                  >
                    <img src={visit} alt="visit" />
                    <Text>Visit Site</Text>
                  </Button>
                </a>
              </MenuItem>
              <MenuItem  _hover={{ backgroundColor: "inherit" }} py={0} px={0}>
                <Flex
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  
                >
                  <Box
                   paddingX={3}
                    paddingY={"9px"}
                    border="1px"
                    borderColor={useColorModeValue("#e0e0e0", "#444")}
                    borderRadius={"5px"}
                  >
                    <Likes el={el} />
                  </Box >
                  <Save_home el={el} py={40} />
                  <Box
                    padding={3}
                    border="1px"
                    borderColor={useColorModeValue("#e0e0e0", "#444")}
                    borderRadius={"5px"}
                  >
                    <ShareModel url={url} />
                  </Box>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>

      <Modal className={style.modal} open={open} onClose={handleClose}>
        <Box
          // bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}
          bgGradient={useColorModeValue(
            "linear(to right,  #f6f6f6, #f6f6f6 )", // light mode complementary gradient
            "linear(to right, #222222, #303030)" // dark mode grey gradient
          )}
          overflow="auto"
          sx={{ width: "fit-content" }}
          borderRadius={10}
          mt={70}
        >
          <Flex
            justifyContent="space-between"
            w="90%"
            alignItems="center"
            margin="auto"
            mt="20px"
            mb={"20px"}
          >
            <Box></Box>
            <Box
            as={CloseIcon}
            cursor="pointer"
            onClick={handleClose}
            pos="fixed"
            bg="#666" // A professional shade of gray
            w="1.5em"
            h="1.5em"
            borderRadius="50%" // Makes it a circle
            border="2px solid #666" // A darker border for contrast
            padding="2px"
            zIndex="55"
            color= "white"
            boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)" // Subtle shadow for depth               
            transition="all 0.3s ease-in-out" // Smooth transition
           />
             <a href={`/tool/${el?.slug}`} target="_blank" >
          <Text
            cursor={"pointer"}
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            padding="3px"
            px={2}
            borderRadius="5px"
    >
        Open in new tab
          </Text>
             </a>
          </Flex>
                  
          <Telement el={el} id={el?._id} handleClose={handleClose} />
        </Box>
      </Modal>
      
    </Stack>
    </>
  );
};
