import React from "react";
import { Box, Text, Button, Flex } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign="center"
      minH="100vh"
    >
      <Text fontSize="6xl" fontWeight="bold">
        404
      </Text>
      <Text fontSize="2xl" fontWeight="medium" mt="4">
        Oops! Page not found.
      </Text>
      <Button
        as="a"
        href="/"
        mt="8"
        colorScheme="teal"
        size="md"
        px="8"
        py="4"
        fontWeight="semibold"
        fontSize="md"
        rounded="full"
        _hover={{ textDecoration: "none" }}
      >
        Back to Home
      </Button>
    </Flex>
  );
};

export default NotFound;
