import React from 'react'
import { Highlighted } from '../Components/Highlighted'

export const HighlightedManage = () => {
  return (
     <>
      <Highlighted/>
     </>
  )
}
