import {
  Box,
  Flex,
  Input,
  Text,
  Divider,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  HStack,
  TagLabel,
  Tag,
  TagCloseButton,
  Tooltip,
  IconButton,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiFillDollarCircle, AiFillUnlock } from "react-icons/ai";

import { BsClockHistory, BsFillGiftFill, BsQuestionDiamondFill } from "react-icons/bs";
import { MdOutlineVerified } from "react-icons/md";
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

export const Form2 = ({
  // handlechange,
  Pricing,
  price_amount,
  Tags,
  setTag,
  Discount,
  setData, // Add this line
  data // And this line
}) => {

  const mandatoryTextColor = useColorModeValue ("lightShadeInLightMode", "lightShadeInDarkMode");

  const handlechange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const [currency, setCurrency] = useState('$'); // Default currency

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handlePriceChange = (e) => {
    const priceWithCurrency = `${currency}${e.target.value}`;
    handlechange({ target: { name: 'price_amount', value: priceWithCurrency } });
  };

  const [newTag, setNewTag] = useState("");

  const addTag = () => {
    if (newTag.trim() !== "") {
      setTag([...Tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = Tags.filter((tag) => tag !== tagToRemove);
    setTag(updatedTags);
  };

  const addTagWithLimit = () => {
    if (Tags.length < 5) {
      addTag();
    } else {
      alert("Maximum of 5 tags allowed");
    }
  };


  return (
    <Box>
       <Flex alignItems="center" gap="5px">
      <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
      Pricing
        </FormLabel>
        <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
         </Flex>
      <Flex gap="20px" mt="20px">
        <Box w="fit-content">
          <input
            value={"Free"}
            name="Pricing"
            onChange={handlechange}
            type="radio"
            checked={Pricing === "Free"} // Ensure the radio button is checked if Pricing is 'Free'
          />
        </Box>
        <Box>
          <Flex
            alignItems="center"
            py={1}
            fontSize="13px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            px={2}
            borderRadius="3px"
            bg="rgba(0, 41, 78, 0.2)"
            gap="5px"
          >
            <BsFillGiftFill />
            <Text>Free</Text>
          </Flex>

          <Text
            mt="10px"
            fontSize="14px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            textAlign="justify"
          >
            If you choose to list your tool as "Free," it means that users can
            access and use your tool without having to pay any fee.
          </Text>
        </Box>
      </Flex>
      <Flex gap="20px" mt="15px">
        <Box w="fit-content">
          <input
            type="radio"
            value={"Freetrial"}
            name="Pricing"
            onChange={handlechange}
            checked={Pricing === "Freetrial"}
          />
        </Box>
        <Box>
          <Flex
            alignItems="center"
            py={1}
            fontSize="13px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            px={2}
            borderRadius="3px"
            bg="rgba(0, 41, 78, 0.2)"
            gap="5px"
          >
            <BsClockHistory />
            <Text> Free trial</Text>
          </Flex>
          <Text
            mt="10px"
            fontSize="14px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            textAlign="justify"
          >
            If you choose to list your tool as "Free trial," it means users can
            access and use the tool without paying any fees for limited period .
            It allows individuals or organizations to experience the full
            functionality and features of the product or service for a specified
            duration, typically without any financial commitment.
          </Text>
        </Box>
      </Flex>
      <Flex gap="20px" mt="15px">
        <Box>
          <input
            type="radio"
            value={"Freemium"}
            name="Pricing"
            onChange={handlechange}
            checked={Pricing === "Freemium"}
          />
        </Box>
        <Box>
          <Flex
            alignItems="center"
            py={1}
            fontSize="13px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            px={2}
            borderRadius="3px"
            bg="rgba(0, 41, 78, 0.2)"
            gap="5px"
          >
            <MdOutlineVerified />
            <Text> Freemium</Text>
          </Flex>
          <Text
            mt="10px"
            fontSize="14px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            textAlign="justify"
          >
            If you choose to list your tool as "Freemium," it means that you are
            offering both a free and a paid version of your tool.
          </Text>
        </Box>
      </Flex>
      <Flex gap="20px" mt="15px">
        <Box w="fit-content">
          <input
            value={"Paid"}
            name="Pricing"
            onChange={handlechange}
            type="radio"
            checked={Pricing === "Paid"}
          />
        </Box>
        <Box>
          <Flex
            alignItems="center"
            py={1}
            fontSize="13px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            px={2}
            borderRadius="3px"
            bg="rgba(0, 41, 78, 0.2)"
            gap="5px"
          >
            <AiFillDollarCircle />
            <Text>Paid</Text>
          </Flex>
          <Text
            mt="10px"
            fontSize="14px"
            lineHeight="16px"
            fontWeight="400"
            w="fit-content"
            textAlign="justify"
          >
            If you choose to list your tool as "Paid," it means that users will
            need to pay a fee to access and use your tool.
          </Text>
        </Box>
      </Flex>

      <Divider mt="30px" mb="30px" />

      <FormControl mt="30px">
        <Flex alignItems="center" gap="8px">
          
            Price Amount
            <Tooltip padding={5} label="Enter the price of your tool or the starting price of the tool's subscription plan in the 'Price Amount' section. This will help users understand the cost of your tool and make an informed decision about whether they want to use it. Providing clear pricing information can also improve the perceived value of the tool and increase user trust." aria-label="">
              <span>
                <BsQuestionDiamondFill size={15} />
              </span>
            </Tooltip>
         
        </Flex>
        <Flex gap="20px" alignItems="center" mt="20px">
          <Select w="auto" value={currency} onChange={handleCurrencyChange}>
            <option value="$">$ (USD)</option>
            <option value="€">€ (Euro)</option>
            <option value="₹">₹ (Rupee)</option>
          </Select>
          <Input
            name="price_amount"
            value={price_amount.replace(currency, '')} // Remove the currency symbol for display
            onChange={handlePriceChange}
            w="200px"
            placeholder="Enter amount"
            borderColor="gray.400" // Sets a default border color.
            _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
            _focus={{ 
              borderColor: "blue.500", // Changes border color on focus.
              boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
            }}
          />
        </Flex>
      </FormControl>

      <Divider mt="30px" mb="30px" />

      <FormControl mt="30px">
      <Flex alignItems="center" gap="8px">
          
          Tags
          <Tooltip padding={5} label=" Tags (Max 5 tags) act as keywords that help users find your tool more easily. They provide a quick understanding of what your tool offers." aria-label="">
            <span>
              <BsQuestionDiamondFill size={15} />
            </span>
          </Tooltip>
       
      </Flex>
      <Flex>
        <Input
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              addTagWithLimit();
            }
          }}
          borderRadius="4px"
          placeholder="Tags for your tool (Press Enter to add)"
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
<Button
  leftIcon={<AddIcon />}
  onClick={addTagWithLimit}
  aria-label="Add tag"
>
  Add tag
</Button>
      </Flex>
      <HStack mt="4" spacing="2">
        {Tags.map((tag) => (
          <Tag
            key={tag}
            size="sm"
            variant="subtle"
            colorScheme="blue"
            borderRadius="5px"
            fontSize="14px"
            px="5px"
            py="5px"
          >
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => removeTag(tag)} />
          </Tag>
        ))}
      </HStack>
    </FormControl>
      <FormControl mt="15px">
      <Flex alignItems="center" gap="8px">
          Discount{" "}
          <Tooltip padding={5} borderRadius={10} 
            label="On AI Zones, we have a dedicated filter for tools that offer Discounts. By providing a Discount code, your tool becomes part of this select group. This feature increases the visibility of your tool as users often filter their search to find tools with special offers, making it more likely for your tool to be discovered."
            aria-label=""
          >
            <p >
              <BsQuestionDiamondFill size={13} />
            </p>
            </Tooltip>
          </Flex>
        
        <Input
          value={Discount}
          name="Discount"
          onChange={handlechange}
          borderRadius="4px"
          w="100%"
          placeholder="Aizones50 for 50% off"
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
      </FormControl>
      {/* <Text fontSize="20px" fontWeight="400" lineHeight="24px">Deals</Text>

            <Flex gap="20px" mt="20px" fontSize="14px" fontWeight="400" lineHeight="24px" alignItems="center">
                <FormControl mt="15px" >
                    <FormLabel  >
                        Promo code
                    </FormLabel>
                    <Input name='promo_code' value={promo_code} onChange={handlechange} borderRadius="3px" placeholder="AIPEDIA30" />

                </FormControl>

                <FormControl mt="15px"  >
                    <FormLabel  >
                        What is the offer?
                    </FormLabel>
                    <Input name='offer' value={offer} onChange={handlechange} borderRadius="3px" placeholder="30% Discount on student plan" />

                </FormControl>




            </Flex>
            <FormControl mt="15px" >
                <FormLabel  >
                    Expiration Date
                </FormLabel>
                <Input name='expire_date' value={expire_date} onChange={handlechange} borderRadius="3px" placeholder="Expiration Date" />

            </FormControl> */}
    </Box>
  );
};
