import React, { useState, useEffect } from "react";
import "../../Style/Leftbar.css";

import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import vscode from "../../Utils/Vs code.svg";

import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import sopify from "../../Utils/sopify.svg";
import Web from "../../Utils/Web.svg";
import { MdDiscount } from "react-icons/md";

import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,Tooltip,
} from "@chakra-ui/react";

import { MdDeleteOutline, MdOutlineVerified } from "react-icons/md";
import { AiFillApi, AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { DiOpensource } from "react-icons/di";
import { BsClockHistory, BsTagFill } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

const data = [
  {
    logo: excel,
    name: "Spreadsheet",
  },
  // {
  //   logo: chatgpt,
  //   name: "ChatGPT (Plugins)",
  // },
  {
    logo: vscode,
    name: "VS Code",
  },
  {
    logo: Web,
    name: "Web",
  },
  {
    logo: github,
    name: "Github",
  },
  {
    logo: slack,
    name: "Slack",
  },
  {
    logo: mobile,
    name: "Mobile app",
  },
  {
    logo: Wordpress,
    name: "Wordpress",
  },
  {
    logo: figma,
    name: "Figma",
  },
  {
    logo: browser,
    name: "Browser Extension",
  },
  {
    logo: sopify,
    name: "Shopify",
  },
  
];

let price = [
  {
    logo: <AiFillGift size={14} />,
    name: "Free",
  },
  {
    logo: <BsClockHistory size={14} />,
    name: "Free trial",
  },
  {
    logo: <MdOutlineVerified size={14} />,
    name: "Freemium",
  },
  {
    logo: <AiFillDollarCircle size={14} />,
    name: "Paid",
  },
];

let other = [
  {
    logo: <AiFillApi size={15} />,
    name: "API",
  },
  {
    logo: <DiOpensource size={15} />,
    name: "Open Source",
  },
  {
    logo: <MdDiscount size={15} />,
    name: "Discount",
  },
];

export const FilterSearch = ({ open, updateFilters }) => {
  // State for each category of filters
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState([]);
  const [selectedOthers, setSelectedOthers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const scrollbarColor = useColorModeValue('#e0e0e0e0', '#303030');

   // Function to clear all selected filters
   const clearAllFilters = () => {
    setSelectedWorks([]);
    setSelectedPricing([]);
    setSelectedOthers([]);
    updateFilters({ works_with: [], Pricing: [], others_features: [] });
    setIsChecked(false); // Assuming this state is used to manage the checkbox states
  };
  

  const updateFilterSelection = (isChecked, value, currentSelection, setSelection, category) => {
    let updatedSelection = isChecked
      ? [...currentSelection, value]
      : currentSelection.filter(item => item !== value);

    setSelection(updatedSelection);

    let tempFilters = {
      works_with: selectedWorks,
      Pricing: selectedPricing,
      others_features: selectedOthers
    };

    tempFilters[category] = updatedSelection;
    updateFilters(tempFilters);
  };

  const handleFilterChange = (e, filterCategory) => {
    const { value, checked } = e.target;

    setCheckedItems(prev => ({ ...prev, [value]: checked }));

    updateFilterSelection(checked, value, 
      filterCategory === "works_with" ? selectedWorks : 
      filterCategory === "Pricing" ? selectedPricing : selectedOthers,
      filterCategory === "works_with" ? setSelectedWorks : 
      filterCategory === "Pricing" ? setSelectedPricing : setSelectedOthers,
      filterCategory
    );
  };

  // Calculate the total count of selected filters
  const totalFiltersCount = selectedWorks.length + selectedPricing.length + selectedOthers.length;

  useEffect(() => {
    library.add(fas);
  }, []);


  return (
    <>
        <style>
            {`
              :root {
                --scrollbar-thumb-color: ${scrollbarColor};
              }
            `}
          </style>
        
        <Box
        bg={useColorModeValue("#FFFFFFF", "#1e1e1e")}
        // className="sidebar"
        w={open ? "155px" : "64.5px"}
        borderRight="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
        // overflowY={open ? "scroll" : "hidden"} // Hide scrollbar when closed
        overflowY="scroll" // Always allow scrolling    
        className={`sidebar ${!open ? 'closed' : ''}`}
        
      >
          
          <Box
            className="scroll"
            mr={open ? "23px" : "0"}
            ml={open ? "18.8px" : "0"}
          >
            <Flex alignItems={"center"} gap={2} paddingTop={6} >
              <Flex alignItems={"center"} gap={"5px"} color={"#71C6F8"}>            
              {open && totalFiltersCount > 0 && <Text fontSize={"14px"}>{totalFiltersCount}</Text>}
            {open && totalFiltersCount > 0 && <Text fontSize={"14px"}>Applied</Text>}
 
              </Flex>
              {totalFiltersCount > 0 && (
                <button         
                  color="black"
                  onClick={() => {
                var x = document.getElementsByClassName("checkbox");
                for (var i = 0; i < x.length; i++) {
                  x[i].checked = false;
                  setIsChecked(true);
                }
                window.location.reload();
              }}
                  style={{
                    background: "#f8f8f8",
                    fontSize: "15px",
                    paddingInline: "5px",
                    borderRadius: "5px",
                    color: "black",   
                    padding: "5px",
                    border: "1px",              
                    cursor: "pointer",
                    outline: "none",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add shadow here                           
                 }}
                >
                  {/* {open ? "Clear" : ""} */}
                  Clear
                </button>
              )}
            </Flex>
            {/* {open &&  */}        
            <Box pt={5} position="relative" >
              <Accordion defaultIndex={[0,1,2]} allowMultiple>
                <AccordionItem >
          
                    <AccordionButton pl={0} pr={0} >
                      <Box flex="1" fontSize={open ? "14px" : "12px"} textAlign={open ? "left" : "center"}>
                      Works with:
                      </Box>
                      {open && <AccordionIcon /> }
                    </AccordionButton>
                 
    
                  <AccordionPanel paddingLeft={0} paddingRight={0}>
      <Flex flexDirection="column" gap="27px">
      {data?.map((el, i) => {
       
             const content = (
              <label 
              key={i} 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: open ? 'space-between' : 'center',
                width: '100%',     
                padding: checkedItems[el.name] ? '8px' : '0',        
                
                backgroundColor: checkedItems[el.name] ? '#3B89' : 'transparent',
                borderRadius: '5px',
                border: checkedItems[el.name] ? '1px solid #ccc' : 'none', // Optional border
                boxSizing: 'border-box'
              }}
            >
            <Flex alignItems="center" gap="5px" >
            <Image boxSize="15px" src={el.logo} alt={el.name + " logo"}  title={el.name} cursor="pointer" />
              {open && (
                <Text fontSize="13px" cursor="pointer" lineHeight="20px">
                  {el.name}
                </Text>
              )}
            </Flex>
    
            {/* {open && ( */}
              <input
                
                className="checkbox"
                type="checkbox"
                name="works_with"
                value={el.name}
                onChange={(e) => handleFilterChange(e, 'works_with')} 
            style={{ 
              visibility: open ? 'visible' : 'hidden', 
              position: open ? 'static' : 'absolute', 
              marginLeft: 'auto' 
            }}
          />
            
          </label>
           );
    
           return open ? (
             content
           ) : (
             <Tooltip key={i} label={el.name} placement="top">
               {content}
             </Tooltip>
           );
         })}
      </Flex>
    </AccordionPanel>
                  
                </AccordionItem>
                <AccordionItem >
            
                    <AccordionButton pl={0} pr={0}> <Box flex="1" fontSize={open ? "14px" : "13px"} textAlign={open ? "left" : "center"}>
                        Price:
                      </Box>
                      {open && <AccordionIcon /> }
                    </AccordionButton>
                
                  <AccordionPanel paddingLeft={0} paddingRight={0}>
                  <Flex flexDirection="column" gap="27px">
        {price.map((el, i) => {
       
       const content = (
          <label 
            key={i} 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: open ? 'space-between' : 'center',
              width: '100%',     
              padding: checkedItems[el.name] ? '8px' : '0',        
              backgroundColor: checkedItems[el.name] ? '#3B89' : 'transparent',
              borderRadius: '5px',
              border: checkedItems[el.name] ? '1px solid #ccc' : 'none', // Optional border
              boxSizing: 'border-box'
            }}
          >
            <Flex alignItems="center" gap="5px">
            <Box cursor="pointer" >{el.logo} </Box>
              {open && (
                <Text fontSize="13px" cursor="pointer" lineHeight="16px" fontWeight="400">
                  {el.name}
                </Text>
              )}
            </Flex>
    
            {/* {open && ( */}
              <input
                className="checkbox"
                type="checkbox"
                name="Pricing"
                value={el.name}
                onChange={(e) => handleFilterChange(e, 'Pricing')} 
                style={{ 
                  visibility: open ? 'visible' : 'hidden', 
                  position: open ? 'static' : 'absolute', 
                  marginLeft: 'auto' 
                }}
          />
            
          </label>
         );
    
         return open ? (
           content
         ) : (
           <Tooltip key={i} label={el.name} placement="top">
             {content}
           </Tooltip>
         );
       })}
      </Flex>
    </AccordionPanel>
      
    
                </AccordionItem>
                <AccordionItem >
     
                    <AccordionButton pl={0} pr={0}>
                    <Box flex="1" fontSize={open ? "14px" : "13px"} textAlign={open ? "left" : "center"}>
                        Other:
                      </Box>
                      {open && <AccordionIcon /> }
                    </AccordionButton>
                 
    
                  <AccordionPanel paddingLeft={0} paddingRight={0}>
      <Flex flexDirection="column" gap="27px">
        {other.map((el, i) => {
           const content = (
          <label 
            key={i} 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: open ? 'space-between' : 'center',
              width: '100%',     
              padding: checkedItems[el.name] ? '8px' : '0',        
              backgroundColor: checkedItems[el.name] ? '#3B89' : 'transparent',
              borderRadius: '5px',
              border: checkedItems[el.name] ? '1px solid #ccc' : 'none', // Optional border
              boxSizing: 'border-box'
            }}
          >
            <Flex gap="5px" alignItems="center">
              <Box cursor="pointer" >{el.logo}</Box>
              {open && (
                <Text fontSize="13px" cursor="pointer" lineHeight="16px" fontWeight="400">
                  {el.name}
                </Text>
              )}
            </Flex>
    
            {/* {open && ( */}
              <input
                className="checkbox"
                type="checkbox"
                name="others_features"
                value={el.name}
                onChange={(e) => handleFilterChange(e, 'others_features')} 
                style={{ 
                  visibility: open ? 'visible' : 'hidden', 
                  position: open ? 'static' : 'absolute', 
                  marginLeft: 'auto' 
                }}           
          />
            
          </label>
        );
    
        return open ? (
          content
        ) : (
          <Tooltip key={i} label={el.name} placement="top">
            {content}
          </Tooltip>
        );
      })}
      </Flex>
    </AccordionPanel>
                  
                </AccordionItem>
              </Accordion>            
            </Box>        
             {/* } */}
    
        </Box>
        </Box>
        </>
      );
    };
    