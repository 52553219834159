import * as React from 'react';


import style from "../../Style/Footer.module.css"
import { Box, Divider, Flex, useColorModeValue  } from '@chakra-ui/react';
import { BCategory } from './Bcategory';
import { BFilter } from './Bfilter';

export const Footer = ({setSort,  setSortLoader ,  cat ,setcat ,   setUserInteracted,
  currentSort, setCount, setUserInfo, setPageName, setFilterLoader,count }) => {
 

  return (
    <>

      <Box 
      // borderTop="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} 
      className={style.footer}                      
      // bgGradient={useColorModeValue(
      // "linear(to right,  #f6f6f6, #f6f6f6 )", // light mode complementary gradient
      // "linear(to right, #222222, #303030)" // dark mode grey gradient
      //  )}  
       >

        <Flex fontWeight="600" justifyContent="space-evenly" h="45px" alignItems="center" textTransform="uppercase" fontSize="18px" letterSpacing="1px">


          <BFilter  setUserInteracted={setUserInteracted}
        currentSort={currentSort}  setSort={setSort} setSortLoader={setSortLoader} count={count}   setCount={setCount}  setUserInfo={setUserInfo} setPageName={setPageName} setFilterLoader={setFilterLoader}  />

          {/* <Divider orientation="vertical" border="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} h="100%" /> */}
          <BCategory setUserInteracted={setUserInteracted} setPageName={setPageName}  setFilterLoader={setFilterLoader}  cat={cat}  setcat={setcat} count={count}/>
        </Flex>





      </Box>
    </>
  )
}
