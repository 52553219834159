import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
 
import { ListModal } from '../ListModal';
import { useSelector } from 'react-redux';
import axios from 'axios';
 
export const LIstcomp = () => {


   const [pageName,setPageName]=useState("")
    let [data, setData] = useState([]);
   const [cat,setcat]=useState("")
   const [filterLoader,setFilterLoader]=useState(false)
    const userData = useSelector((state) => state.userReducer.loginData);

  
    let token = userData.data;
 

    const getData = async () => {
      //get/space-like

        try {
        
         
            const res = await axios.get(
              `${process.env.REACT_APP_API}/data/get/userlikes/data`,
             
              { headers: { token } }
            );

            setData(res.data.data)

           
            
          
        } catch (err) {
           
          
        }
    };
    useEffect(() => {
        
            getData();
      
    }, []);

   

    return (

      



                    <Box  >
                       

                        {
                            data?.map((el,i) => (

                                <Box mt="30px" key={i}>
                                    <ListModal title={el.Title} el={el.videoID} id={el?.videoID?._id} i={i} setcat={setcat} setPageName={setPageName} setFilterLoader={setFilterLoader}/>
                                </Box>

                            ))
                        }


                    </Box>

              

    )
}



