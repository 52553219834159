import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  button,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { Overview } from "./Overview";
import ShareModel from "../Share";
import style from "../../Style/Tool.module.css";
import { Link } from "react-scroll";
import { Alternative } from "./Alternative";
import { Comment } from "./Comment";
import { Embed } from "./Embed";
import axios from "axios";
import notification from "../Toast";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoginModel from "../model/LoginModel";
import { useNavigate } from "react-router-dom";

export const Tabcomp = ({ setreview, el, id }) => {
  //console.log("el: ###", el);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedValue, setSelectedValue] = useState("");

  const [email, setEmail] = useState("");

  let url = `${window.location.origin}/tool/${el?.slug}`;
  // let url = `${process.env.REACT_APP_PUBLIC_URL}/tool/${el?._id}`;

  const { loginData } = useSelector((state) => state.userReducer);

  function isEmailFromDomain(email, domainToCheck) {
    //console.log("function calling");
    domainToCheck = domainToCheck.split("/?")[0].split("://")[1].split(".")[0];
    const emailParts = email.split("@");
    const emailDomain = emailParts[1].split(".")[0];
    return emailDomain === domainToCheck;
  }

  const [otpSent, setOtpSent] = useState(false);

  const extractDomain = (url) => {
    const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/?#]+)/);
    return match ? match[1] : null;
  };

  const getEmailDomain = (email) => {
    const parts = email.split('@');
    return parts[1];
  };

  const sendRequest = () => {
    if (!email.length) {
      toast.error("Please enter the email");
      return;
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      let domainToCheck = el.URL;
      const ownerDomain = getEmailDomain(email);
      const domains = extractDomain(domainToCheck);
   
// console.log(ownerDomain, domains, domains === ownerDomain)
      // domainToCheck = domainToCheck
      //   .split("/?")[0]
      //   .split("://")[1]
      //   .split(".")[0];
      // const emailParts = email.split("@");
      // const emailDomain = emailParts[1];
      // console.log("emailParts", emailParts)
      // console.log("domainToCheck", emailDomain, domainToCheck);
      // return;
     
      if (ownerDomain === domains) {
        // api call which send otp
       
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: loginData.data,
        };
        axios
          // .post(`${process.env.REACT_APP_API}/auth/verifyEmail`, { 
          // ${process.env.REACT_APP_API}
          .post(`${process.env.REACT_APP_API}/reqAccess/verifyEmail/${el?._id}`, {
            email: email,
          }, { headers })
          .then((res) => {

            notification("success", "An OTP send to your email please verify")
            setOtpSent(true);
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.error || "Something went wrong!"
            );
          });
      } else {
        return toast.error("This tool does not belongs to you!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong!");
    }

    // validate email
    // const payload = {
    //   videoID: id,
    //   tool_member: selectedValue,
    //   domain_email: email,
    // };
    // //console.log(payload);
    // const token = userData.data;
    // axios
    //   .post(`${process.env.REACT_APP_API}/request/add`, payload, {
    //     headers: { token },
    //   })
    //   .then((res) => {
    //     notification("success", res.data.msg);
    //     onClose();
    //   });
  };

  // function scrollToElement(elementId) {
  //   const targetElement = document.getElementById(elementId);
  //   if (targetElement) {
  //     const offsetTop = targetElement.getBoundingClientRect().top;
  //     const scrollOptions = {
  //       top: offsetTop,
  //       behavior: "smooth",
  //       duration: 500,
  //     };
  //     window.scrollTo(scrollOptions);
  //   }
  // }
  const [otp, setOTP] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "200px",
  };

  const inputStyle = {
    width: "40px",
    height: "40px",
    border: "1px solid #ccc",
    textAlign: "center",
    // fontSize: "24px",
    padding:"0"
  };

  const errorMessageStyle = {
    color: "red",
    marginTop: "10px",
  };

  const buttonStyle = {
    marginTop: "10px",
    padding: "5px 10px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    cursor: "pointer",
  };

  const handleChange = (e, index) => {
    const updatedOTP = [...otp];
    updatedOTP[index] = e.target.value;
    if (e.target.value.length > 1) {
      return;
    }
    setOTP(updatedOTP);
    setError("");

    if (index < 3 && e.target.value) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      inputRefs[index - 1].current.focus();
    }
  };

  const navigate = useNavigate();
  

  const handleSubmit = () => {
    const otpValue = otp.join("");
    // console.log(otpValue, selectedValue);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      token: loginData.data,
    };

    if (/^\d{4}$/.test(otpValue)) {
      // Valid OTP format, make the API call
      axios
        .post(`${process.env.REACT_APP_API}/reqAccess/verifyOTP`, {
        // .post(`${process.env.REACT_APP_API}/reqAccess/verifyEmail/${}`, {
          // email: email,
          otp: otpValue,
          // toolId: el._id,
        }, {headers})
        .then((res) => {
          //console.log("res: ", res);
          toast.success("OTP Verified successfully!");
          localStorage.setItem("editToolID", el._id);
          navigate(`/update-tool/${el._id}`);
          // setOtpSent(true);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error || "Something went wrong!");
        });

      //console.log(`API call with OTP: ${otpValue}`);
    } else {
      toast.error("Invalid OTP. Please enter a 4-digit OTP.");
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(`#${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Box>
      <Tabs>
        <TabList>
          <Flex justifyContent="space-between" w="100%" alignItems="center">
            <Box overflow="auto" whiteSpace="nowrap" className={style.tscroll}>
              <Flex className={style.tnav} gap="10px">
              <button onClick={() => scrollToSection('Overview')}>
                  <Tab fontSize="14px" fontWeight="600" lineHeight="24px">
                    Overview
                  </Tab>
                  </button>
                
                  <button onClick={() => scrollToSection('Alternative')}>               
                  <Tab fontSize="14px" fontWeight="600" lineHeight="24px">
                    Alternative tools
                  </Tab>
                  </button>
                  <button onClick={() => scrollToSection('Embed')}>
                  <Tab fontSize="14px" fontWeight="600" lineHeight="24px">
                    Embed
                  </Tab>
                  </button>
                  <button onClick={() => scrollToSection('Reviews')}>
                  <Tab fontSize="14px" fontWeight="600" lineHeight="24px">
                    Reviews
                  </Tab>
                  </button>
              

                <Tab
                  onClick={onOpen}
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Request access
                </Tab>
              </Flex>
            </Box>

            <ShareModel url={url} />
          </Flex>
        </TabList>
      </Tabs>

      <Box mt="50px">
        <section id="Overview">
          <Overview el={el} id={id} />
        </section>

        <section id="Alternative">
          <Alternative id={id} el={el} />
        </section>

        <section id="Embed">
        <Embed id={id} title={el?.Title} slug={el?.slug}/>

        </section>

        <section id="Reviews">
          <Comment setreview={setreview} el={el} id={id} />
        </section>
      </Box>

      {loginData && isOpen ? (
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent color="#444" bg="#D9D9D9" borderRadius="0px">
            <ModalHeader fontSize="15px" lineHeight="24px" fontWeight="600">
              {otpSent ? "Request Access" : "Verify email"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!otpSent && (
                <>
                  <Flex flexDirection="column" gap="10px">
                    <Text
                      mt="5px"
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight="400"
                    >
                      Are you an official member of this tool?
                    </Text>
                    <Flex gap="10px" alignItems="center">
                      <input
                        value="Yes"
                        checked={selectedValue === "Yes"}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>Yes</Text>
                    </Flex>
                    <Text fontSize="13px" lineHeight="24px" fontWeight="400">
                      Kindly provide us with your official domain registered
                      email ID for verification purposes. Once its verified, you
                      will be granted the access to the Creators Portal, where
                      you can efficiently manage your tool. Thank you for
                      choosing our platform. 
                      Contact: hello@aizones.io                  
                    </Text>
                    <Input
                      disabled={selectedValue !== "Yes" ? true : false}
                      onChange={(e) => setEmail(e.target.value)}
                      borderRadius="3px"
                      bg="#F8F8F8"
                      _placeholder={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#929292",
                      }}
                      placeholder="Official domain registered Email ID"
                    />

                    <Flex mt="20px" gap="10px" alignItems="center">
                      <input
                        value="No"
                        checked={selectedValue === "No"}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>No</Text>
                    </Flex>
                    <Text fontSize="13px" lineHeight="24px" fontWeight="400">
                      Attention: This message is intended for official members
                      of our tool only. If you are not an authorized member,
                      kindly refrain from proceeding further. For any inquiries
                      or requests, please reach out to our support team. Thank
                      you for your understanding.
                    </Text>
                  </Flex>
                  <Flex
                    mt="50px"
                    mb="30px"
                    textAlign="end"
                    justifyContent="right"
                  >
                    <Button
                      color="#444"
                      onClick={sendRequest}
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight="600"
                      border="1px solid black"
                      bg="#F8F8F8"
                      padding="3px 10px"
                      borderRadius="3px"
                    >
                      Submit
                    </Button>
                  </Flex>
                </>
              )}
              {otpSent && (
                <>
                  <div>
                    <h2>Enter 4-Digit OTP</h2>
                    <div style={containerStyle}>
                      {otp.map((digit, index) => (
                        <Input 
                          key={index}
                          type="number"
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                          maxLength="1"
                          style={inputStyle}
                          ref={inputRefs[index]}
                        />
                      ))}
                    </div>
                    {error && <p style={errorMessageStyle}>{error}</p>}

                    <p
                      onClick={sendRequest}
                      style={{
                        cursor: "pointer",
                        color: "blue", // Change the color to your preferred button color
                        textDecoration: "underline", // Add an underline to make it look clickable
                        fontWeight: "bold", // You can adjust the font weight as needed
                        margin: "10px 0", // Add some margin for spacing
                      }}
                    >
                      {/* Resend OTP */}
                    </p>
                    <Button colorScheme='blue' onClick={handleSubmit}>Submit</Button>
                    
                  </div>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        isOpen && <LoginModel />
      )}
      {/* {loginData === false ?  : null} */}
    </Box>
  );
};
