import React, { useEffect, useState } from "react";
import { Box, Flex, Stack, Text, useColorMode } from "@chakra-ui/react";
import axios from "axios";
import { Telement } from "../Components/Tool/Telement";
import style from "../Style/Tool.module.css";
import { Link, useParams } from "react-router-dom";
import { LandingRight } from "../Components/LandingRight";
import { IoIosArrowForward } from "react-icons/io";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {toolspagetitle} from "./Tools-page-title";
import Footer_options from "../Components/Home/Footer_home";


const getData = ({ slug }) => {
  return axios.get(`${process.env.REACT_APP_API}/data/single/get/${slug}`);
};

export const Tool = () => {
  let { slug } = useParams();
  const [loading, setloading] = useState(true);
  let [data, setdata] = useState([]);
  const [pageTitle, setPageTitle] = useState("AI Zones");
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData({ slug });
        setdata(res.data.data);
        setloading(false);
        window.scrollTo(0, 0);
      } 
      catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [slug]);

  const title = toolspagetitle(data);

  const metaTitle = title;
  //const metaDescription = `Discover ${data?.Title} tool pricing insights, top-notch alternatives, in-depth user reviews, and features exclusively on AI Zones`;
  const metaDescription = `${data?.Description}`;

  document.title = metaTitle;

  return (     
     
     <HelmetProvider>
      <Helmet> 
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />       
        <meta property="og:type" content="website" />
        <meta property="og:url" content= {`${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}`}/>
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content={data?.Cover_image} />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}`} />  

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />
  

      </Helmet>     
   
    <Box 
    marginTop={{ base: "80px", md: "80px" }}
    >      
      <Flex
        mt="20px"
        mb="30px"
        ml={{ base: "20px", md: "50px" }}
        alignItems="center"
        fontSize="13px"
        fontWeight="400"
        lineHeight="20px"
        gap={"2px"}
      >
        <Link to="/">
          <Text>Browse Tools</Text>
        </Link>

        <Text>
          {" "}
          <IoIosArrowForward
            size={15}
            color={colorMode === "light" ? "#1a202c" : "white"}
          />{" "}
          {""}
        </Text>

        <Text>{data?.Title}</Text>
      </Flex>

      <Flex justifyContent="space-between" className={style.leftscroll}>
        <Stack>
          {loading ? <Text>Loading...</Text> : <Telement slug={slug} el={data} />}
        </Stack>
        <Stack>
             <Box display="block" css={{ '@media(max-width: 1500px)': { display: 'none' } }}>
    <LandingRight />
  </Box>
        </Stack>
      </Flex>
      
    </Box>
    <Footer_options/>
    
    </HelmetProvider>
    
  );
};
