import {
  Box,
  Button,
  Input,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import axios from "axios";
import { useSelector } from "react-redux";
import notification from "../../Components/Toast";
import { Link, useNavigate } from "react-router-dom";

export default function CollectionEditButton({
  slug,
  name,
  description,
  status
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPublic, setIsPublic] = useState(status);
  const [checkedItems, setCheckedItems] = useState(false);
  const [chkBox, chkBoxSet] = useState(false);
  const [formData, setFormData] = useState({});

  const userData = useSelector((state) => state.userReducer.loginData);
  const token = userData.data;
  const navigate=useNavigate()
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onCloseAlert = () => setIsAlertOpen(false);
  const cancelRef = useRef(); // For the focus of the cancel button in the dialog



  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    delete formData.name;
    setFormData({ ...formData, [name === "name" ? "space_name": name]: value });
  };
  const update = async (payload)=>  {    
    try {
      const data = await axios.patch(`${process.env.REACT_APP_API}/space/updateincollection/${slug}`, payload, {
        headers: { token },
      } ).then((result) => {
        console.log(result);
        notification("success", result?.data.msg);
        navigate("/collection")
        setTimeout(()=>navigate(`/collection/my-collection/${slug}`),300)

      }).catch((err) => {
        notification("error", err?.msg);
      });
      
    } catch (error) {
      console.log(error);
    }    
  }
const removeCollection = async ()=> {
  try {
    const data = await axios.delete(`${process.env.REACT_APP_API}/space/delete/${slug}`, {
      headers: { token },
    } ).then((result) => {
      console.log(result);
      notification("success", result?.data.msg);
      navigate("/collection#mycollections")
      // setTimeout(()=>navigate(`/collection/my-collection/${id}`),300)

    }).catch((err) => {
      notification("error", err?.msg);
    });
    
  } catch (error) {
    console.log(error);
  } 
}



const setData = ()=> {
  onOpen()
  setFormData({space_name: name, description: description, status: status});
  setCheckedItems(status === "Private" ? true : false)
} 
// console.log(formData);
  // useEffect(()=> {
  //   if (!name ||  !description || !status) {
  //     setFormData({space_name: name, description: description, status: status});
      
  //   }
  //   // setCheckedItems((status === "Public") && true)
  //   console.log(formData);
  // },{formData})

  return (
    <>
      <Button
        fontSize={"14px"}
        onClick={setData}
        paddingBlock={"2px"}
        paddingInline={"16px"}
      >
        Edit
      </Button>
      {(Object.keys(formData).length > 0 && formData.space_name) && <Modal
        isOpen={isOpen}
        onClose={onClose}
        // color="#444"
        bg="#D9D9D9"
        borderRadius="0px"
        w="fit-content"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Collection</ModalHeader> 
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box>
                <Text>Name</Text>
                <Input
                  type="text"
                  placeholder="Enter collection name"
                  name="name"
                  // border={"1px"}
                  // color="#444"
                  borderRadius="10px"
                  // _placeholder={{ color: "#444" }}
                  value={formData?.space_name}
                  onChange={handleChange}
                />
              </Box>

              <Box mt="20px">
                <Text>Description</Text>
                <Textarea
                  row={5}
                  type="text"
                  // border={"1px"}
                  placeholder="Enter Description "
                  name="description"
                  value={formData?.description}
                  // color="#444"
                  borderRadius="10px"
                  // _placeholder={{ color: "#444" }}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
          <Box
  w="100%"
  display="flex"
  flexDir={{ base: "column", sm: "row" }} // Stack on small screens, row on larger
  alignItems="center"
  justifyContent={{ base: "flex-start", sm: "space-between" }} // Start alignment on small screens, space-between on larger
>
  <Checkbox
    isChecked={checkedItems}
    onChange={(e) => {
      setCheckedItems(e.target.checked);
      formData.status = !checkedItems ? "Private" : "Public";
      setFormData(formData);
    }}
    mb={{ base: 2, sm: 0 }} // Margin bottom on small screens, none on larger
  >
    Private
  </Checkbox>
  <Box
    display="flex"
    flexDirection={{ base: "column", sm: "row" }}
    alignItems={{ base: "stretch", sm: "center" }}
    width={{ base: "100%", sm: "auto" }}
    mt={{ base: 2, sm: 0 }} // Margin top on small screens, none on larger
  >
    <Button
      variant="ghost"
      onClick={() => setIsAlertOpen(true)}
      mb={{ base: 2, sm: 0 }} // Margin bottom on small screens, none on larger
      size="sm" // Smaller button size for mobile
    >
      Delete Collection
    </Button>
    <Button
      colorScheme="blue"
      onClick={() => update(formData)}
      size="sm" // Smaller button size for mobile
    >
      Update
    </Button>
  </Box>


  <AlertDialog
    isOpen={isAlertOpen}
    leastDestructiveRef={cancelRef}
    onClose={onCloseAlert}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete Collection
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure? You can't undo this action afterwards.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onCloseAlert}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={removeCollection} ml={3}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>

            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>}
    </>
  );
}
