import React, { useEffect } from "react";

import style from "../../Style/Grid.module.css";

import { Box } from "@chakra-ui/react";

import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modalcomp } from "../Modal";

export const Gridcomp = () => {
  const [pageName, setPageName] = useState("");

  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  let [data, setData] = useState([]);

  const userData = useSelector((state) => state.userReducer.loginData);

  let token = userData.data;

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/data/get/userlikes/data`,

        { headers: { token } }
      );
      //console.log(res);
      setData(res.data.data);
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box mt="30px" className={style.gridbox}>
      {data?.map((el, i) => (
        <Modalcomp
          key={i}
          el={el?.videoID}
          id={el?.videoID?._id}
          i={i}
          setcat={setcat}
          setPageName={setPageName}
          setFilterLoader={setFilterLoader}
        />
      ))}
    </Box>
  );
};
