// BlogUpdates.js
import React, { useEffect, useState } from 'react';
import { Box, Text, Image, Flex, Divider, useColorModeValue,   AspectRatio, } from '@chakra-ui/react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const BlogUpdates = () => {
  const [blogs, setBlogs] = useState([]);
  const borderColor = useColorModeValue("#e0e0e0", "#444");
  //const heading = useColorModeValue("gray.900", "white");

  const heading = useColorModeValue("gray.800", "#eeeeee");

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
        const publishedBlogs = response.data.filter(blog => blog.published);
        // Sort blogs by createdAt or updatedAt in descending order before slicing the first 3
        const sortedBlogs = publishedBlogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 3);
        setBlogs(sortedBlogs);
      } catch (error) {
        console.error("Failed to fetch blogs", error);
      }
    };
  
    fetchBlogs();
  }, []);



  // Truncate the title to a certain number of characters
  const truncateTitle = (title, limit = 150) => {
    return title.length > limit ? title.substring(0, limit) + '...' : title;
  };

return (
    <Box mt="30px">
      <Flex alignItems="center" gap="8px">
      <Text
          textTransform="uppercase"
          fontWeight="600"
          lineHeight="20px"
          fontSize="12px"
          color={heading}
          // as="h3"
        >
          Updates
        </Text>
      </Flex >
      {blogs.map((blog, index) => (
        <Flex mt="30px" key={index} alignItems="center" mb="20px">
            <Link to={`/updates/${blog.slug}`}>
          <Image
            src={blog.coverImage}
            alt={`Cover image for ${blog.title}`}
            objectFit="cover"
            h="48px"
            w="72px"
            borderRadius="3"
            mr="12px"
          />
          </Link>
          <Box flex="1">
            <Link to={`/updates/${blog.slug}`}>
            <Text 
                fontWeight="800" 
                fontSize="13px" 
                lineHeight="20px"
                color={heading} 
                noOfLines={3} // Allow text to wrap up to three lines
              >
                {truncateTitle(blog.title)}
              </Text>
            </Link>
          </Box>
        </Flex>
      ))}
      <Divider borderColor={borderColor} mb="20px" />
    </Box>
  );
};


export default BlogUpdates;
