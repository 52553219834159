import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Flex,
  Box,
  Text,
  Heading,
  Image,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  Stack,
  Tag,
  Icon,
  Container, 
  useColorModeValue
} from '@chakra-ui/react';
import notification from "../Toast";

import Footer_options from './Footer_home';
import debounce from 'lodash.debounce';
import { FaBrain, FaSearch } from 'react-icons/fa';

const BlogLandingPage_home = () => {
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");

  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
        const publishedBlogs = response.data
          .filter(blog => blog.published) // Filter out unpublished blogs
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by creation date
          .slice(0, 3); // Limit to the first three blogs
        setBlogs(publishedBlogs);
        setFilteredBlogs(publishedBlogs); // Initially showing top three published blogs
      } catch (error) {
        console.error('Error fetching blogs:', error);
        notification("error", "Failed to fetch blogs");
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlogs();
  }, []);

   // Define the search function outside of useEffect to avoid re-creation on each render
   const searchBlogs = (searchValue) => {
    const lowercasedValue = searchValue.toLowerCase();
    const filteredData = blogs.filter(blog =>
      blog.title?.toLowerCase().includes(lowercasedValue) ||
      blog.summary?.toLowerCase().includes(lowercasedValue)
    );
    setFilteredBlogs(filteredData);
  };

  // Use useCallback to memoize the debounced version of the search function
  const debouncedSearch = useCallback(debounce((value) => searchBlogs(value), 300), [blogs]);

  // Handle search input changes
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };


  const handleClick = async () => {
    setBtnLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      notification("error", "Enter a valid email address");
      setBtnLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });
      notification("success", response.data.message || "Successfully subscribed");
    } catch (error) {
      notification("error", error.response?.data?.message || "Something went wrong");
    } finally {
      setBtnLoading(false);
      setEmail(""); // Optionally clear the email input after subscribing
    }
  };
  
  
  const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); 
  const bg=useColorModeValue("#ffffff", "#222222");
  return (
    <>
     <Box mt={7} >


     <Heading  textAlign="Left" as="h2"  fontSize="lg" color='blue.400'  my={2}>
     AI Updates
             </Heading>
            <Text as="h3"fontSize="15px" color={font} mb={4}>
            Explore the Latest Trends in AI Tools, Developer Insights, and News
            </Text>
    {/* <Container maxW="container.xl" mt={70} p={0}> */}
   
    
    

     
      <SimpleGrid columns={{ base: 1, md: 3 }} p={5}spacing={5}>
      {filteredBlogs.map(blog => (
        <Box bg={bg} key={blog._id}  borderRadius="md"
        transition="transform 0.3s, box-shadow 0.3s" 
        _hover={{ transform: "translateY(-5px)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)" }} boxShadow={"0 2px 4px rgba(0,0,0,0.1)"}>
          <Link to={`/updates/${blog.slug}`}>
            <Image src={blog.coverImage} 
            height="170px"  
            width="100%"  
            alt={blog.title} 
            borderRadius="md" 
            transition="opacity 0.3s" 
            _hover={{ opacity: 0.8 }}
            mb={1} 
          objectFit="cover" />
            <Box p={5}>
              <Heading color={heading} as="h2" textAlign="center" fontFamily="Nunito" lineHeight="24px"size="sm">{blog.title}</Heading>
              <Text mt={1} fontSize="xs" >{blog.summary}</Text>
            </Box>
          </Link>
        </Box>
      ))}
    </SimpleGrid>
    {/* </Container> */}
    </Box>
  
     </>
  );
};

export default BlogLandingPage_home;






