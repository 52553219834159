import React from 'react'
import { ToolManages } from '../Components/ToolManage'

export const ToolManage = () => {
  return (
    <>

    <ToolManages/>
    
     </>
  )
}
