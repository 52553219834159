import { Box, Button, FormControl, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'
import { ImageSlideBox } from '../Components/ImageSlide'
 
export const ImageSlede = () => {
  return (
    <Box> 
       <ImageSlideBox/>
    </Box>
  )
}
