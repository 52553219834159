import { Box } from "@mui/material";
import React from "react";
import { CircularProgress, Stack } from "@chakra-ui/react";
import { ListModal } from "../ListModal";
import LoadingAnimation from '../LoadingAnimation';

export const LIstView = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
}) => {
 
  return (
    <Stack>
      <Box>
        {data
          ?.filter((e) => e.verify)
          .map((el, i) => (
            <Box key={i}>
              <ListModal
                el={el}
                i={i}
                setcat={setcat}
                setPageName={setPageName}
                setFilterLoader={setFilterLoader}
              />
            </Box>
          ))}
      </Box>

      {showLoader ? (
        <Box
          height="100px"
          w="100%"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <LoadingAnimation size="xl"/>
        </Box>
      ) : null}
    </Stack>
  );
};
