import React, { useState, useEffect } from "react";
import "../../Style/Leftbar.css";

import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import vscode from "../../Utils/Vs code.svg";

import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import sopify from "../../Utils/sopify.svg";
import Web from "../../Utils/Web.svg";
import { MdDiscount } from "react-icons/md";

import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Image,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,Button
  } from "@chakra-ui/react";

  
 

 
  import { DiOpensource } from "react-icons/di";
  import { BsClockHistory } from "react-icons/bs";
  import { TbAdjustmentsHorizontal } from "react-icons/tb";


import { MdDeleteOutline, MdOutlineVerified } from "react-icons/md";
import { AiFillApi, AiFillDollarCircle, AiFillGift } from "react-icons/ai";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

const data = [
  {
    logo: excel,
    name: "Spreadsheet",
  },
  // {
  //   logo: chatgpt,
  //   name: "ChatGPT (Plugins)",
  // },
  {
    logo: vscode,
    name: "VS Code",
  },
  {
    logo: Web,
    name: "Web",
  },
  {
    logo: github,
    name: "Github",
  },
  {
    logo: slack,
    name: "Slack",
  },
  {
    logo: mobile,
    name: "Mobile app",
  },
  {
    logo: Wordpress,
    name: "Wordpress",
  },
  {
    logo: figma,
    name: "Figma",
  },
  {
    logo: browser,
    name: "Browser Extension",
  },
  {
    logo: sopify,
    name: "Shopify",
  },
  
];

let price = [
  {
    logo: <AiFillGift size={14} />,
    name: "Free",
  },
  {
    logo: <BsClockHistory size={14} />,
    name: "Free trial",
  },
  {
    logo: <MdOutlineVerified size={14} />,
    name: "Freemium",
  },
  {
    logo: <AiFillDollarCircle size={14} />,
    name: "Paid",
  },
];

let other = [
  {
    logo: <AiFillApi size={15} />,
    name: "API",
  },
  {
    logo: <DiOpensource size={15} />,
    name: "Open Source",
  },
  {
    logo: <MdDiscount size={15} />,
    name: "Discount",
  },
];

export const BFilter_search = ({ open, updateFilters }) => {
  // State for each category of filters
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState([]);
  const [selectedOthers, setSelectedOthers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();


  const scrollbarColor = useColorModeValue('#e0e0e0e0', '#303030');


   // Function to clear all selected filters
   const clearAllFilters = () => {
    setSelectedWorks([]);
    setSelectedPricing([]);
    setSelectedOthers([]);
    updateFilters({ works_with: [], Pricing: [], others_features: [] });
    setIsChecked(false); // Assuming this state is used to manage the checkbox states
  };
  

  const updateFilterSelection = (isChecked, value, currentSelection, setSelection, category) => {
    let updatedSelection = isChecked
      ? [...currentSelection, value]
      : currentSelection.filter(item => item !== value);

    setSelection(updatedSelection);

    let tempFilters = {
      works_with: selectedWorks,
      Pricing: selectedPricing,
      others_features: selectedOthers
    };

    tempFilters[category] = updatedSelection;
    updateFilters(tempFilters);
  };

  const handleFilterChange = (e, filterCategory) => {
    const { value, checked } = e.target;

    setCheckedItems(prev => ({ ...prev, [value]: checked }));

    updateFilterSelection(checked, value, 
      filterCategory === "works_with" ? selectedWorks : 
      filterCategory === "Pricing" ? selectedPricing : selectedOthers,
      filterCategory === "works_with" ? setSelectedWorks : 
      filterCategory === "Pricing" ? setSelectedPricing : setSelectedOthers,
      filterCategory
    );
  };

  // Calculate the total count of selected filters
  const totalFiltersCount = selectedWorks.length + selectedPricing.length + selectedOthers.length;

  useEffect(() => {
    library.add(fas);
  }, []);
    
  
  
    return (
      <>
        <Drawer
          placement={"left"}
          onClose={onClose}
          isOpen={isOpen}
          size={"full"}
          width="30px"
          
        >
          <DrawerOverlay />
          <DrawerContent 
          bgGradient={useColorModeValue(
            "linear(to right,  #f6f6f6, #f6f6f6 )", // light mode complementary gradient
            "linear(to right, #222222, #303030)" // dark mode grey gradient
             )}  >
            <DrawerHeader
              h="45px"
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              bg={useColorModeValue("#F8F8F8", "#222222")}
            >
              <Flex
                textTransform="uppercase"
                w="100%"
                m="auto"
                alignItems="center"
                gap="5px"
                fontSize="14px"
                lineHeight="24px"
                textAlign="left"
              >
                <TbAdjustmentsHorizontal /> Filters
                <div
                  style={{
                    borderRadius: "50%", // Create a circular shape
                    backgroundColor: "#3B89B6", // Background color
                    color: "white", // Text color
                    width: "24px", // Set the width of the circle
                    height: "24px", // Set the height of the circle
                    display: "flex", // Make it a flex container
                    alignItems: "center", // Center the content vertically
                    justifyContent: "center", // Center the content horizontally
                    // position: "absolute",
                  }}
                >
                {totalFiltersCount || 0}
                </div>
                {totalFiltersCount > 0 && (
                <button         
                  color="black"
                  onClick={() => {
                var x = document.getElementsByClassName("checkbox");
                for (var i = 0; i < x.length; i++) {
                  x[i].checked = false;
                  setIsChecked(true);
                }
                window.location.reload();
              }}
                    style={{
                      background: "#f8f8f8",
                      fontSize: "15px",
                      paddingInline: "5px",
                      borderRadius: "5px",
                      color: "black",   
                      px: "5px",
                      marginLeft :"9px",
                      border: "1px",              
                      cursor: "pointer",
                      outline: "none",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add shadow here                           
                   }}
                  >
                   Clear
                  </button>
                )}
              </Flex>
            </DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>
              <Box>
                <Tabs
                  bgScheme=""
                  display="flex"
                  justifyContent="space-between"
                  w="100%"
                  border="none"
                >
                  <TabList
                    display="flex"
                    fontWeight="600"
                    flexDirection="column"
                    gap="20px"
                    w="100%"
                    border="none"
                  >
                    <Tab
                      _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                      fontSize="13px"
                      fontWeight="600"
                      justifyContent="left"
                      border="none"
                    >
                      Works with:
                    </Tab>
                    <Tab
                      _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                      fontSize="13px"
                      fontWeight="600"
                      justifyContent="left"
                      border="none"
                    >
                      Price:
                    </Tab>
                    <Tab
                      _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                      fontSize="13px"
                      fontWeight="600"
                      justifyContent="left"
                      border="none"
                    >
                      Other:
                    </Tab>
                    {/* <Tab
                      _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                      fontSize="13px"
                      fontWeight="600"
                      justifyContent="left"
                      border="none"
                    >
                      Sort
                    </Tab> */}
                  </TabList>
  
                  <TabPanels display="flex" justifyContent="end">
                    <TabPanel
                      bg={useColorModeValue("#F3F5FA", "")}
                      w="212px"
                      display="flex"
                      gap="25px"
                      flexDirection="column"
                    >
                      {data?.map((el, i) => (
                        <Flex
                          m="auto"
                          w="75%"
                          key={i}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          
                          <Flex gap="7px" alignItems="center" w="fit-content">
                            <Image src={el.logo} alt={el.name + " logo"} />
                            <Text fontSize="13px" fontWeight="400">
                              {el.name}
                            </Text>
                          </Flex>
  
                          <input
                            type="checkbox"
                            name="works_with"
                            attr="chk"
                            value={el.name}
                            onChange={(e) => handleFilterChange(e, 'works_with')} 
                          />
                        </Flex>
                      ))}
                    </TabPanel>
                    <TabPanel
                      bg={useColorModeValue("#F3F5FA", "")}
                      w="212px"
                      display="flex"
                      gap="25px"
                      flexDirection="column"
                    >
                      {price?.map((el, i) => (
                        <Flex
                          m="auto"
                          w="75%"
                          key={i}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Flex gap="7px" alignItems="center" w="fit-content">
                            {el.logo}
                            <Text fontSize="13px" fontWeight="400">
                              {el.name}
                            </Text>
                          </Flex>
  
                          <input
                            type="checkbox"
                            name="Pricing"
                            value={el.name}
                            attr="chk"
                            onChange={(e) => handleFilterChange(e, 'Pricing')} 
                          />
                        </Flex>
                      ))}
                    </TabPanel>
                    <TabPanel
                      bg={useColorModeValue("#F3F5FA", "")}
                      w="212px"
                      display="flex"
                      gap="20px"
                      justifyContent="center"
                      flexDirection="column"
                      textAlign="center"
                      alignItems="center"
                    >
                      {other?.map((el, i) => (
                        <Flex
                          w="75%"
                          key={i}
                          justifyContent="space-between"
                          alignItems="center"
                          alignSelf="center"
                        >
                          <Flex gap="7px" alignItems="center" w="fit-content">
                            {el.logo}
                            <Text fontSize="13px" fontWeight="400">
                              {el.name}
                            </Text>
                          </Flex>
  
                          <input
                            type="checkbox"
                            name="others_features"
                            value={el.name}
                            attr="chk"
                            onChange={(e) => handleFilterChange(e, 'others_features')} 
                          />
                        </Flex>
                      ))}
                    </TabPanel>
  
                   
                  </TabPanels>
                </Tabs>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
  
        <Button
        // w="50%"
        alignItems="center"
        gap="5px"
        fontSize="md"
        lineHeight="24px"
        textAlign="center"
        justifyContent="center"
        onClick={onOpen}
        borderRadius={10}
        // px={5}
        w="150px"
        variant="solid" // Style as needed
        bg={useColorModeValue("#f8f8f8", "#222222")}   
         border="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} 
         boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)" 
      >
          <TbAdjustmentsHorizontal />
        <Text>Filters</Text>
        {totalFiltersCount > 0 && (
          <Box
            borderRadius="50%"
            backgroundColor="#3B89B6"
            color="white"
            width="24px"
            height="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {totalFiltersCount}
          </Box>
        )}
      </Button>
     
      </>
    );
  };
  
  
  
  