import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Flex,
  Box,
  Text,
  Heading,
  Image,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  Stack,
  Tag,
  Icon,
  Container,useColorModeValue 
} from '@chakra-ui/react';
import notification from "../Components/Toast";

import Footer_options from '../Components/Home/Footer_home';
import debounce from 'lodash.debounce';
import { FaBrain, FaSearch } from 'react-icons/fa';


const BlogLandingPage = () => {
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const bg=useColorModeValue("#ffffff", "#1e1e1e");
  const borderColor=useColorModeValue("#e0e0e0", "#444");

  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);

const publishedBlogs = response.data
      .filter(blog => blog.published) // Filter out unpublished blogs
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by creation date
    setBlogs(publishedBlogs);
    setFilteredBlogs(publishedBlogs); // Set the filtered blogs as well, initially showing all published blogs
  } catch (error) {
    console.error('Error fetching blogs:', error);
    notification("error", "Failed to fetch blogs");
  } finally {
    setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

   // Define the search function outside of useEffect to avoid re-creation on each render
   const searchBlogs = (searchValue) => {
    const lowercasedValue = searchValue.toLowerCase();
    const filteredData = blogs.filter(blog =>
      blog.title?.toLowerCase().includes(lowercasedValue) ||
      blog.summary?.toLowerCase().includes(lowercasedValue)
    );
    setFilteredBlogs(filteredData);
  };

  // Use useCallback to memoize the debounced version of the search function
  const debouncedSearch = useCallback(debounce((value) => searchBlogs(value), 300), [blogs]);

  // Handle search input changes
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };


  ///brevo
  const handleClick = async () => {
    setBtnLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      notification("error", "Enter a valid email address");
      setBtnLoading(false);
      return;
    }
    try {      
      const response = await fetch('https://api.brevo.com/v3/contacts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_BREVO_API_KEY
          },
      
      body: JSON.stringify({
        email,
        listIds: [10], // Include the list ID in the request body to assign the contact to the list
      }),
    });
      notification("success", "Successfully subscribed");
      setBtnLoading(false);
    } catch (error) {
      notification("error", "Something went wrong");
      setBtnLoading(false);
    }
  };

  //beehiv

  // const handleClick = async () => {
  //   setBtnLoading(true);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!email || !emailRegex.test(email)) {
  //     notification("error", "Enter a valid email address");
  //     setBtnLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });
  //     notification("success", response.data.message || "Successfully subscribed");
  //   } catch (error) {
  //     notification("error", error.response?.data?.message || "Something went wrong");
  //   } finally {
  //     setBtnLoading(false);
  //     setEmail(""); // Optionally clear the email input after subscribing
  //   }
  // };


  const metaTitle = "AI Innovations & Insights: Stay Ahead with AI Zones";
  const metaDescription = "Dive into the world of AI with AI Zones. Discover the latest in AI productivity tools, machine learning trends, and the impact of AI on various industries. Subscribe for daily updates and elevate your knowledge.";

  

  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>Discover AI's Potential - Daily Reads for the Curious Mind | AI Zones</title>
        <meta name="description" content="Explore AI Zones for daily insights into AI's potential. Stay ahead with our curated list of AI articles and news. Subscribe now for the latest updates." />
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.aizones.io/updates" />
        <meta property="og:title" content="Discover AI's Potential - Daily Reads for the Curious Mind | AI Zones" />
        <meta property="og:description" content="Explore AI's transformative power through curated articles on AI productivity tools, ChatGPT insights, and cutting-edge machine learning applications. Join AI Zones now." />
        <meta property="og:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.aizones.io/updates" />
        <meta name="twitter:title" content="Discover AI's Potential - Daily Reads for the Curious Mind | AI Zones" />
        <meta name="twitter:description" content="Your gateway to AI advancements. From developer tools to creative AI applications, AI Zones is your daily dose of AI knowledge and inspiration." />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />

        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/updates`} />
      </Helmet>
    <Container maxW="container.xl" mt={70} p={0}>
   
      <Flex direction="column" align="center" justify="center" my={10}>
      <Icon as={FaBrain} w={10} h={10}  mb={2} /> {/* AI-related icon */}
      <Heading as="h1" size="2xl" fontWeight="bold" fontFamily="Nunito" textAlign="center">
        Discover AI's Potential:<br/> Daily Reads for the Curious Mind
      </Heading>
    </Flex>
      {/* Subscription Section */}
      <Flex my={8} align="center" justify="center">
        <Box p={4} border="1px" bg={bg} boxShadow={"0 2px 4px rgba(0,0,0,0.1)"}  borderColor={borderColor} borderRadius="md">
          <Text fontSize="lg" >
          Stay ahead with AI — Subscribe to AI Zones for daily tech insights
          </Text>

          <InputGroup size="md" mt={2}>
            <Input
              pr="4.5rem"
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Your email"
              aria-label="Your email"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" p={5} onClick={handleClick} px={10}color="white" bg="#3B89B6">
                Subscribe
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Flex>

      <Flex mb={5} justify="center" mt={10}>
          <InputGroup maxW="100%" bg={bg}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.500" />
            </InputLeftElement>
            <Input
          placeholder="Search blogs"
          value={searchTerm}
          onChange={onSearchChange}
            />
          </InputGroup>
        </Flex>

      {/* Navigation Bar with Categories */}
    

      {/* Featured Story */}
      {/* You might want to fetch a featured story from your blogs, or simply select the first one */}
     

      {/* Other Stories Grid */}
     
      {/* <SimpleGrid columns={{ base: 1, md: 3 }} p={5} spacing={5}>
  {filteredBlogs.map(blog => (
    <Box 
      key={blog._id} 
      bg={bg} 
      border={"1px"} 
      boxShadow={"0 4px 8px rgba(0, 0, 0, 0.1)"} 
      borderColor={borderColor} 
      borderRadius="lg" 
      overflow="hidden" 
      transition="transform 0.3s, box-shadow 0.3s" 
      _hover={{ transform: "translateY(-5px)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)" }}
    >
      <Link to={`/updates/${blog.slug}`}>
        <Image 
          src={blog.coverImage} 
          height="230px"  
          width="100%"  
          alt={blog.title} 
          objectFit="cover" 
          transition="opacity 0.3s" 
          _hover={{ opacity: 0.8 }}
        />
        <Box p={5}>
        <Heading lineHeight="30px"fontFamily="Nunito" textAlign="center"size="md">{blog.title}</Heading>
          <Text mt={2} fontSize="sm" color="gray.600" textAlign="center">
            {blog.summary}
          </Text>
        </Box>
      </Link>
    </Box>
  ))}
</SimpleGrid> */}



<SimpleGrid columns={{ base: 1, md: 3 }} p={5} spacing={5}>
  {filteredBlogs.map(blog => (
    <Box 
      key={blog._id} 
      bg={bg} 
      border={"2px solid"} 
      borderColor={borderColor} 
      boxShadow={"0 4px 8px rgba(0, 0, 0, 0.1)"} 
      borderRadius="lg" 
      overflow="hidden" 
      transition="transform 0.3s, box-shadow 0.3s" 
      _hover={{ transform: "translateY(-5px)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)" }}
    >
      <Link to={`/updates/${blog.slug}`}>
        <Image 
          src={blog.coverImage} 
          height="230px"  
          width="100%"  
          alt={blog.title} 
          objectFit="cover" 
          transition="opacity 0.3s" 
          _hover={{ opacity: 0.8 }}
        />
        <Box p={5}>
        <Heading lineHeight="30px"fontFamily="Nunito" textAlign="center"size="md">{blog.title}</Heading>
          <Text mt={2} fontSize="sm" color="gray.600" textAlign="center">
            {blog.summary}
          </Text>
        </Box>
      </Link>
    </Box>
  ))}
</SimpleGrid>

    </Container>
     <Footer_options/>
     </HelmetProvider>
     </>
  );
};

export default BlogLandingPage;








// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Flex, Box, Text, Heading, Image, SimpleGrid, Input, InputGroup,
//   InputRightElement, InputLeftElement, Button, Icon, Container,
// } from '@chakra-ui/react';
// import notification from "../Components/Toast";
// import Footer_options from '../Components/Home/Footer_home';
// import debounce from 'lodash.debounce';
// import { FaBrain, FaSearch } from 'react-icons/fa';

// const BlogLandingPage = () => {
//   const [email, setEmail] = useState("");
//   const [btnLoading, setBtnLoading] = useState(false);
//   const [blogs, setBlogs] = useState([]);
//   const [filteredBlogs, setFilteredBlogs] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     setLoading(true);
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
//         setBlogs(response.data);
//         setFilteredBlogs(response.data); // Initialize filteredBlogs with all blogs
//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//         notification("error", "Failed to fetch blogs");
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchBlogs();
//   }, []);

//   // This useEffect will only trigger on searchTerm changes, thanks to its dependency array.
//   useEffect(() => {
//     const debouncedFilter = debounce(() => {
//       if (!searchTerm) {
//         setFilteredBlogs(blogs);
//         return;
//       }
  
//       const lowercasedValue = searchTerm.toLowerCase();
//       const filteredData = blogs.filter(blog =>
//         (blog.title && blog.title.toLowerCase().includes(lowercasedValue)) ||
//         (blog.summary && blog.summary.toLowerCase().includes(lowercasedValue))
//       );
  
//       setFilteredBlogs(filteredData);
//     }, 300);
  
//     debouncedFilter();
  
//     return () => debouncedFilter.cancel();
//   }, [searchTerm, blogs]);

//   const handleSearch = event => {
//     setSearchTerm(event.target.value);
//   };

//   const handleClick = async () => {
//     setBtnLoading(true);
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (!email || !emailRegex.test(email)) {
//       notification("error", "Enter a valid email address");
//       setBtnLoading(false);
//       return;
//     }

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });
//       notification("success", response.data.message || "Successfully subscribed");
//     } catch (error) {
//       notification("error", error.response?.data?.message || "Something went wrong");
//     } finally {
//       setBtnLoading(false);
//       setEmail(""); // Optionally clear the email input after subscribing
//     }
//   };

//   return (
//     <>
//       <Container maxW="container.xl" mt={70} p={0}>
//         {/* UI elements remain unchanged */}
//         <Flex mb={5} justify="center" mt={10}>
//           <InputGroup maxW="100%">
//             <InputLeftElement pointerEvents="none">
//               <Icon as={FaSearch} color="gray.500" />
//             </InputLeftElement>
//             <Input
//               placeholder="Search blogs"
//               onChange={handleSearch}
//             />
//           </InputGroup>
//         </Flex>

//         {/* Update to iterate over filteredBlogs for display */}
//         <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} p={5} spacing={5}>
//           {filteredBlogs.map((blog) => (
//             <Box key={blog._id} boxShadow="md" borderRadius="md">
//               <Link to={`/blog/${blog.slug}`}>
//                 <Image src={blog.coverImage} alt={blog.title} borderRadius="md" mb={3} objectFit="cover" />
//                 <Box p={5}>
//                   <Heading size="sm">{blog.title}</Heading>
//                   <Text mt={2} fontSize="xs" color="gray.500">{blog.summary}</Text>
//                 </Box>
//               </Link>
//             </Box>
//           ))}
//         </SimpleGrid>
//       </Container>
//       <Footer_options />
//     </>
//   );
// };

// export default BlogLandingPage;






















// import React, { useEffect, useState } from 'react';
// import { Link, Input, Box, Heading, Image, SimpleGrid, VStack } from '@chakra-ui/react';
// import axios from 'axios';
// // import InfiniteScroll from 'react-infinite-scroll-component';

// const BlogLandingPage = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [search, setSearch] = useState('');
//   const [hasMore, setHasMore] = useState(true);

//   useEffect(() => {
//     fetchBlogs();
//   }, []);

//   const fetchBlogs = async () => {
//     try {
//       // Assuming the API returns the blogs sorted by creation date
//       const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
//       setBlogs(response.data);
//     } catch (error) {
//       console.error('Error fetching blogs:', error);
//     }
//   };

//   const loadMoreBlogs = () => {
//     // Implement logic to load more blogs
//     // This can be done by keeping track of the last blog loaded and requesting the next set from the backend
//     // Once the data is returned, concatenate it to the existing blogs array
//     // If the backend returns an empty array, setHasMore to false
//   };

//   const filteredBlogs = search
//     ? blogs.filter((blog) => blog.title.toLowerCase().includes(search.toLowerCase()))
//     : blogs;

//   return (
//     <VStack spacing={8} align="stretch">
//       <Input
//         placeholder="Search blogs"
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         my={4}
//       />

//       {/* <InfiniteScroll
//         dataLength={filteredBlogs.length}
//         next={loadMoreBlogs}
//         hasMore={hasMore}
//         loader={<h4>Loading...</h4>}
//         endMessage={
//           <p style={{ textAlign: 'center' }}>
//             <b>Yay! You have seen it all</b>
//           </p>
//         }
//       > */}
//         <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={5}>
//           {filteredBlogs.map((blog) => (
//             <Link key={blog._id} to={`/blog/${blog.slug}`}>
//               <Box boxShadow="md" borderRadius="md" overflow="hidden">
//                 <Image src={blog.coverImage} alt={blog.title} />
//                 <Box p={5}>
//                   <Heading size="md">{blog.title}</Heading>
//                   <p>{new Date(blog.createdAt).toLocaleDateString()}</p>
//                 </Box>
//               </Box>
//             </Link>
//           ))}
//         </SimpleGrid>
//       </InfiniteScroll>
//     </VStack>
//   );
// };

// export default BlogLandingPage;
