import React, { useEffect } from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

export const Privacy = () => {
  useEffect(() => {
    document.title = "Terms & Conditions - AI Zones";
  }, []);
  return (
    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{document.title}</title>       
    </Helmet>
    <Box
      fontSize="16px"
      fontWeight="400"
      w="80%"
      m="auto"
      marginTop={{ base: "10px", md: "80px" }}
    >
      <Tabs>
        <TabList fontSize="16px" fontWeight="600" lineHeight={"24px"}>
          <Tab fontSize="16px"  fontWeight="600" lineHeight={"24px"}>
            Terms of Service
          </Tab>

          <Tab fontSize="16px" fontWeight="600" lineHeight={"24px"}>
            Privacy policy
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <Text mt="25px" as="h1" fontSize="24px" mb="25px" fontWeight="400">
                Terms of Service
              </Text>

              <Text mt="20px" as="h2"fontWeight="700">
                Introduction
              </Text>
              <Text as="h3">
                Welcome to AI Zones, a collection of AI tools designed to help
                you streamline your workflow and increase productivity. By
                accessing or using AI Zones, you agree to be bound by these
                terms and conditions, as well as our privacy policy.
              </Text>
              <Text mt="20px" fontWeight="700">
                {" "}
                User Conduct
              </Text>
              <Text>
                You agree to use AI Zones in accordance with all applicable laws
                and regulations. You may not use AI Zones for any unlawful or
                harmful purpose, including but not limited to spamming, hacking,
                or distributing malicious software.
              </Text>
              <Text mt="20px" fontWeight="700">
                Intellectual Property Rights
              </Text>
              <Text>
                All intellectual property associated with AI Zones, including
                trademarks, logos, and copyrighted material, is owned by AI
                Zones or its licensors. You may not reproduce, distribute, or
                display any content from AI Zones without our prior written
                consent.
              </Text>
              <Text mt="20px" fontWeight="700">
                Limitation of Liability
              </Text>
              <Text>
                AI Zones shall not be liable for any errors, omissions, or
                damages that may arise from the use of AI Zones, including but
                not limited to direct, indirect, incidental, punitive, and
                consequential damages.
              </Text>

              <Text mt="20px" fontWeight="700">
                Termination and Suspension
              </Text>
              <Text>
                {" "}
                AI Zones may terminate or suspend your account or access to AI
                Zones at any time for any reason, including but not limited to
                violation of these terms and conditions.
              </Text>

              <Text>
                {" "}
                AI Zones allows users to submit reviews, save and like tools,
                and engage in other user-generated content. By submitting
                content to AI Zones, you grant AI Zones a non-exclusive,
                worldwide, royalty-free license to use, reproduce, and
                distribute your content in connection with AI Zones and our
                business.
              </Text>

              <Text mt="20px" fontWeight="700">
                {" "}
                Privacy Policy
              </Text>

              <Text>
                {" "}
                AI Zones collects and uses personal data from our users in
                accordance with our privacy policy. By using AI Zones, you agree
                to our collection, use, and storage of your personal data as
                outlined in our privacy policy.
              </Text>

              <Text mt="20px" fontWeight="700">
                Governing Law and Dispute Resolution
              </Text>

              <Text>
                These terms and conditions shall be governed by and construed in
                accordance with the laws of India, regardless of conflicts of
                laws principles. Any disputes arising from these terms and
                conditions shall be resolved through arbitration in India in
                accordance with the rules of the Indian Arbitration Association.
              </Text>

              <Text mt="20px" fontWeight="700">
                Modifications to the Terms and Conditions
              </Text>

              <Text>
                {" "}
                AI Zones reserves the right to modify these terms and conditions
                at any time without notice. Your continued use of AI Zones after
                any such modifications indicates your acceptance of the modified
                terms and conditions.
              </Text>

              <Text mt="20px" fontWeight="700">
                {" "}
                Contact Information
              </Text>

              <Text>
                {" "}
                If you have any questions or concerns about these terms and
                conditions, please contact us at hello@aizones.io
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box>
              <Text mt="25px" mb="25px" fontSize="24px" fontWeight="400">
                Privacy policy
              </Text>
              <Text>
                AI Zones ("we" or "us") is committed to protecting the privacy
                of our website users ("you"). This Privacy Policy describes how
                we collect, use, store, and share information about you in
                connection with your use of our website [www.aizones.io] (the
                "Website").
              </Text>

              <Text mt="10px" fontWeight="700">
                Information We Collect
              </Text>
              <Text>
                {" "}
                We may collect the following information about you when you use
                the Website:
              </Text>
              <Box w="96%" m="auto">
                <ul>
                  <li>
                    Personal Information: We may collect your name and email
                    address when you submit a review or contact us through the
                    Website.
                  </li>
                  <li>
                    Usage Information: We may collect information about how you
                    use the Website, such as the pages you visit, the links you
                    click, and the features you use.
                  </li>
                  <li>
                    {" "}
                    Device Information: We may collect information about the
                    device you use to access the Website, such as the type of
                    device, the operating system, and the browser type.
                  </li>
                  <li>
                    {" "}
                    Cookies and Similar Technologies: We may use cookies and
                    similar technologies to collect information about your use
                    of the Website. You can learn more about how we use cookies
                    and how you can manage them in our Cookie Policy.
                  </li>
                </ul>
              </Box>

              <Text mt="10px" fontWeight="700">
                {" "}
                How We Use Your Information
              </Text>
              <Text>
                We may use the information we collect about you for the
                following purposes:
              </Text>
              <Box w="96%" m="auto">
                <ul>
                  <li>
                    To provide and improve the Website: We may use your
                    information to operate and maintain the Website and to
                    improve its features and functionality.
                  </li>
                  <li>
                    To communicate with you: We may use your email address to
                    respond to your inquiries or to send you updates about the
                    Website.
                  </li>
                  <li>
                    To personalize your experience: We may use your information
                    to customize your experience on the Website and to recommend
                    tools that may be of interest to you.
                  </li>
                  <li>
                    {" "}
                    To protect our rights and interests: We may use your
                    information to protect our rights and interests, such as
                    detecting and preventing fraud and enforcing our Terms and
                    Conditions.
                  </li>
                </ul>
              </Box>

              <Text mt="10px" fontWeight="700">
                {" "}
                How We Share Your Information
              </Text>
              <Text>
                {" "}
                We may share your information with the following third parties:
              </Text>
              <Box w="96%" m="auto">
                <ul>
                  <li>
                    {" "}
                    Service Providers: We may share your information with
                    third-party service providers who help us to operate the
                    Website, such as hosting providers and analytics providers.
                  </li>
                  <li>
                    Affiliates: We may share your information with our
                    affiliates for the purposes described in this Privacy
                    Policy.
                  </li>
                  <li>
                    Legal Requirements: We may disclose your information if
                    required to do so by law or in response to a valid subpoena,
                    court order, or other legal process.
                  </li>
                </ul>
              </Box>

              <Text mt="10px" fontWeight="700">
                Your Choices
              </Text>
              <Text>
                {" "}
                You have the following choices regarding your information:
              </Text>
              <Box w="96%" m="auto">
                <ul>
                  <li>
                    {" "}
                    Opt-Out of Email Communications: You may opt-out of
                    receiving email communications from us by following the
                    instructions in our emails.
                  </li>
                  <li>
                    {" "}
                    Cookie Management: You can manage cookies through your
                    browser settings or our Cookie Policy.
                  </li>
                </ul>
              </Box>

              <Text mt="10px" fontWeight="700">
                Security
              </Text>
              <Text>
                {" "}
                We take reasonable measures to protect your information from
                unauthorized access, use, disclosure, or destruction. However,
                no data security measures can guarantee 100% security.
              </Text>

              <Text mt="10px" fontWeight="700">
                Children's Privacy
              </Text>

              <Text>
                The Website is not intended for use by children under the age of
                13. We do not knowingly collect personal information from
                children under 13. If you are a parent or guardian and believe
                that your child has provided us with personal information,
                please contact us at [Your Contact Information] to request
                deletion of that information.
              </Text>

              <Text mt="10px" fontWeight="700">
                {" "}
                Changes to this Privacy Policy
              </Text>
              <Text>
                {" "}
                We may update this Privacy Policy from time to time. The updated
                Privacy Policy will be posted on the Website with a new
                effective date. We encourage you to review the Privacy Policy
                periodically.
              </Text>

              <Text mt="10px" fontWeight="700">
                {" "}
                Contact Us
              </Text>
              <Text>
                If you have any questions or comments about this Privacy Policy,
                please contact us at hello@aizones.io
              </Text>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
   </HelmetProvider>
  );
};
