import {
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,useColorModeValue, Spinner, Image, useColorMode, Heading, UnorderedList, ListItem
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "../Style/Tool.module.css";
import { Overall } from "../Components/Top10/Overall";
import { LandingRight } from "../Components/LandingRight";
import { MdOutlineEqualizer } from "react-icons/md";
import axios from "axios";
import {Helmet, HelmetProvider} from "react-helmet-async";
import lightLogo from "../Utils/LOGO-AIZONES-white.svg";
import darkLogo from "../Utils/LOGO-AIZONES-black.svg";
import Footer_options from "../Components/Home/Footer_home";


// OLD ARRAY
// const nlist = [
//   "Overall",
//   "Image",
//   "Developers tools",
//   "AI BOT",
//   "3D",
//   "Business",
//   "Video",
// ];

// NEW ARRAY
// const nlist = [
//   "Overall Image",
//   "Generative AI",
//   "Audio & Music",
//   "AI Bot	Miscellaneous",
//   "Developer Tools",
//   "Writing",
//   "Life Assistant",
//   "3D",
//   "Video	Business",
//   "Design",
// ];

const nlist = [];

export const Top10 = () => {
  let [data, setData] = useState([]);
  const [q, setq] = useState("");
  const [cat, setTabheader] = useState([]);

  let [isLoading, setIsLoading] = useState(true);

  const { colorMode } = useColorMode();

  const logo = colorMode === 'light' ? darkLogo : lightLogo;


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/data/top-ten/category`);
        setTabheader(response.data.map(item => item.title));
        setData(response.data.reduce((acc, item) => ({ ...acc, [item.title]: item.tools }), {}));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);

    // Call useColorModeValue at the top level and assign to variables
    const backgroundColor = useColorModeValue('gray.50', 'gray.600');
    const textColor = useColorModeValue('gray.600', 'gray.200');
    const headingColor = useColorModeValue("gray.900", "white");
    const fontColor = useColorModeValue("gray.600", "gray.400");

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");

  const metaTitle = "Unleash the Power of AI: Top 10 Tools Across Every Category - AI Zones";
  const metaDescription = "Explore our definitive ranking of the top 10 AI tools across Image, Design, Writing, and beyond! Gain insights, compare features, and find the perfect AI champion for your needs.";

  document.title = "Top 10 Tools Across Every Category - AI Zones";  

  if (isLoading) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
      <Image src={logo} boxSize="200px" alt="Logo" />
      <Spinner
        thickness="3px"
        speed="0.90s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
    );
  }


  
  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/top10`}/>
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/Top-10-AI-Tools-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/top10`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/top10`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content='../Utils/Top-10-AI-Tools-Coverimg-AIZones.png' />

    </Helmet>
    <Flex marginTop={{ base: "40px", md: "60px" }}>
      <Stack w="100%" fontFamily="Lato">
        <Box className={style.collection} w="90%" margin="auto" mt="30px">
          <Flex
            alignItems="center"
            gap="5px"
            fontWeight="400"
            fontSize={"24px"}
           
            color={heading}
          >
            <MdOutlineEqualizer />
            <Text fontWeight="600" as="h1"> Top 10 AI Tools of 2024: Leaders in Innovation Across Categories </Text>
          </Flex>

          <Text
            fontSize="15px"
            // lineHeight="24px"
            fontWeight="400"
            mt="20px"
            textAlign="justify"
            color={font}
            as="h2"
            py={5}
          >

Explore the Best AI Tools in Design, Development, Writing, and More - Your Ultimate Guide to the Top-Rated AI Applications Shaping the Future of Technology.
          </Text>

          <Tabs mt="20px">
            <TabList overflow="auto" className={style.tscroll}>
            {cat?.map((categoryTitle) => (
    <Tab
      key={categoryTitle}
                  textTransform="uppercase"
                  // fontSize="14px"
                  fontSize="12px"
                  fontWeight="600"
                  // lineHeight={"20px"}
                  onClick={() => setq(categoryTitle)}
                >
                  {categoryTitle}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
  {cat.map((categoryTitle, i) => (
    <TabPanel key={i}>
      <Overall data={data[categoryTitle]} />
    </TabPanel>
  ))}
</TabPanels>
          </Tabs>
          <Box p={5} rounded="md" shadow="sm" marginTop="5">
  <Heading as="h2" size="md" fontWeight="bold" mb={3} textAlign="left" color={headingColor}>
    Top 10 AI Tools of 2024: Leaders in Innovation Across Categories
  </Heading>
  <Text fontSize="md" as="h3"color={textColor} textAlign="justify">
    Welcome to the "Top 10 AI Tools of 2024: Leaders in Innovation Across Categories" on AI Zones, your ultimate destination for exploring the forefront of AI tool innovation. In a world where efficiency, creativity, and smart solutions are paramount, our curated list of top AI tools spans various categories, ensuring you find the perfect tool to enhance your projects and workflow:
  </Text>
  <UnorderedList textAlign="left" ml={8} my={4}>
    <ListItem>
      <Text fontSize="md" py={2}color={textColor}>
        <strong>Design and Development:</strong> Leverage AI for unparalleled productivity, with tools from advanced coding assistants to AI-driven design enhancements.
      </Text>
    </ListItem>
    <ListItem>
      <Text fontSize="md" py={2} color={textColor}>
        <strong>Writing and Content Generation:</strong> Discover writing assistants and generative AI that transform how you create content.
      </Text>
    </ListItem>
    <ListItem>
      <Text fontSize="md" py={2} color={textColor}>
        <strong>Business Intelligence Solutions:</strong> Utilize AI tools to gain insights and streamline your workflow, from email assistants to spreadsheet solutions.
      </Text>
    </ListItem>
    <ListItem>
      <Text fontSize="md" py={2} color={textColor}>
        <strong>Creative Enhancements:</strong> Unleash your creativity with tools for video and audio editing, bringing your visual concepts to life.
      </Text>
    </ListItem>
  </UnorderedList>
  <Text fontSize="md" color={textColor} textAlign="justify">
    Dive into our comprehensive guide and explore the best AI tools in the market. With AI Zones, navigating the vast landscape of AI tools has never been easier. Join us on a journey to discover AI's eclectic edge, where variety meets innovation, and unlock the power of AI to transform your projects.
  </Text>
</Box>
        </Box>
        
      </Stack>
      <Stack>
        <LandingRight />
      </Stack>
    </Flex>
    <Footer_options/>
    </HelmetProvider>
  );
};
