import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,Button
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import vscode from "../../Utils/Vs code.svg";

import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import sopify from "../../Utils/sopify.svg";
import Web from "../../Utils/Web.svg";

import { MdOutlineVerified } from "react-icons/md";
import { AiFillApi, AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { DiOpensource } from "react-icons/di";
import { BsClockHistory } from "react-icons/bs";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { MdDiscount } from "react-icons/md";

const data = [
  {
    logo: excel,
    name: "Spreadsheet",
  },
  // {
  //   logo: chatgpt,
  //   name: "ChatGPT (Plugins)",
  // },
  {
    logo: vscode,
    name: "VS Code",
  },
  {
    logo: Web,
    name: "Web",
  },
  {
    logo: github,
    name: "Github",
  },
  {
    logo: slack,
    name: "Slack",
  },
  {
    logo: mobile,
    name: "Mobile app",
  },
  {
    logo: Wordpress,
    name: "Wordpress",
  },
  {
    logo: figma,
    name: "Figma",
  },
  {
    logo: browser,
    name: "Browser Extension",
  },
  {
    logo: sopify,
    name: "Shopify",
  },
];

let price = [
  {
    logo: <AiFillGift size={13} />,
    name: "Free",
  },
  {
    logo: <BsClockHistory size={13} />,
    name: "Free trial",
  },
  {
    logo: <MdOutlineVerified size={13} />,
    name: "Freemium",
  },
  {
    logo: <AiFillDollarCircle size={13} />,
    name: "Paid",
  },
];

let other = [
  {
    logo: <AiFillApi size={15} />,
    name: "API",
  },
  {
    logo: <DiOpensource size={15} />,
    name: "Open Source",
  },
  {
    logo: <MdDiscount size={15} />,
    name: "Discount",
  },
];
export const BFilter = ({
  setSort,
  setSortLoader,
  setCount,
  setUserInfo,
  setPageName,
  setFilterLoader,
  count,
  setUserInteracted,
  currentSort,
}) => {
  //console.log("count: ", count);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const handleFilterchange = (e) => {
    let { value, checked, name } = e.target;
    

  // Update checked items state
  const newCheckedItems = { ...checkedItems, [value]: checked };
  setCheckedItems(newCheckedItems);

  // Check if any checkbox is checked
  const isAnyChecked = Object.values(newCheckedItems).some(item => item);

  // If no filters are active, check the current sort state
  if (!isAnyChecked && currentSort === 'Default') {
    setUserInteracted(false);
  } else {
    setUserInteracted(true);
  }

    // setCheckedItems(prev => ({ ...prev, [value]: checked }));

    if (checked && value) {
      setCount((pre) => pre + 1);
    }
    if (!checked) {
      setCount((pre) => pre - 1);
    }

    if (checked && name === "works_with") {
      setUserInfo((prev) => {
        return {
          ...prev,
          works_with: [...prev.works_with, value],
          loading: !prev.loading,
        };
      });
    } else if (checked && name === "Pricing") {
      setUserInfo((prev) => {
        return {
          ...prev,
          Pricing: [...prev.Pricing, value],
          loading: !prev.loading,
        };
      });
    } else if (checked && name === "others_features") {
      setUserInfo((prev) => {
        return {
          ...prev,
          others_features: [...prev.others_features, value],
          loading: !prev.loading,
        };
      });
    } else {
      setUserInfo((prev) => {
        return {
          works_with: prev.works_with.filter((ele) => ele !== value),
          others_features: prev.others_features.filter((ele) => ele !== value),
          Pricing: prev.Pricing.filter((ele) => ele !== value),
          loading: !prev.loading,
        };
      });
    }
    setPageName("filter");
    setFilterLoader((prev) => !prev);

  };


  

  return (
    <>
      <Drawer
        placement={"left"}
        onClose={onClose}
        isOpen={isOpen}
        size={"full"}
        width="30px"
        
      >
        <DrawerOverlay />
        <DrawerContent 
        bgGradient={useColorModeValue(
          "linear(to right,  #f6f6f6, #f6f6f6 )", // light mode complementary gradient
          "linear(to right, #222222, #303030)" // dark mode grey gradient
           )}  >
          <DrawerHeader
            h="45px"
            borderBottom="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            bg={useColorModeValue("#ffffff", "#222222")}
          >
            <Flex
              textTransform="uppercase"
              w="100%"
              m="auto"
              alignItems="center"
              gap="5px"
              fontSize="14px"
              lineHeight="24px"
              textAlign="left"
            >
              <TbAdjustmentsHorizontal /> Filters
              <div
                style={{
                  borderRadius: "50%", // Create a circular shape
                  backgroundColor: "#3B89B6", // Background color
                  color: "white", // Text color
                  width: "24px", // Set the width of the circle
                  height: "24px", // Set the height of the circle
                  display: "flex", // Make it a flex container
                  alignItems: "center", // Center the content vertically
                  justifyContent: "center", // Center the content horizontally
                  // position: "absolute",
                }}
              >
              {count || 0}
              </div>
              {count > 0 && (
                <button
                  color="black"
                  onMouseEnter={() => {
                    var x = document.querySelectorAll(`input[attr=chk]`);
                    for (var i = 0; i < x.length; i++) {
                      x[i].checked = false;
                      setCheckedItems({});

                  if (currentSort === 'Default') {
                   setUserInteracted(false);
    }
                      setCount(null)
                      console.log(data);
                    }
                    
                    window.location.reload();
                  }}
                  style={{
                    background: "#ffffff",
                    fontSize: "15px",
                    paddingInline: "5px",
                    borderRadius: "5px",
                    color: "black",   
                    px: "5px",
                    marginLeft :"9px",
                    border: "1px",              
                    cursor: "pointer",
                    outline: "none",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add shadow here                           
                 }}
                >
                 Clear
                </button>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Box>
              <Tabs
                bgScheme=""
                display="flex"
                justifyContent="space-between"
                w="100%"
                border="none"
              >
                <TabList
                  display="flex"
                  fontWeight="600"
                  flexDirection="column"
                  gap="20px"
                  w="100%"
                  border="none"
                >
                  <Tab
                    _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                    fontSize="13px"
                    fontWeight="600"
                    justifyContent="left"
                    border="none"
                  >
                    Works with:
                  </Tab>
                  <Tab
                    _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                    fontSize="13px"
                    fontWeight="600"
                    justifyContent="left"
                    border="none"
                  >
                    Price:
                  </Tab>
                  <Tab
                    _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                    fontSize="13px"
                    fontWeight="600"
                    justifyContent="left"
                    border="none"
                  >
                    Other:
                  </Tab>
                  {/* <Tab
                    _selected={{ bg: useColorModeValue("#F3F5FA", "") }}
                    fontSize="13px"
                    fontWeight="600"
                    justifyContent="left"
                    border="none"
                  >
                    Sort
                  </Tab> */}
                </TabList>

                <TabPanels display="flex" justifyContent="end">
                  <TabPanel
                    bg={useColorModeValue("#F3F5FA", "")}
                    w="212px"
                    display="flex"
                    gap="25px"
                    flexDirection="column"
                  >
                    {data?.map((el, i) => (
                      <Flex
                        m="auto"
                        w="75%"
                        key={i}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        
                        <Flex gap="7px" alignItems="center" w="fit-content">
                          <Image src={el.logo} alt={el.name + " logo"}  />
                          <Text fontSize="13px" fontWeight="400">
                            {el.name}
                          </Text>
                        </Flex>

                        <input
                          type="checkbox"
                          name="works_with"
                          attr="chk"
                          value={el.name}
                          onChange={handleFilterchange}
                        />
                      </Flex>
                    ))}
                  </TabPanel>
                  <TabPanel
                    bg={useColorModeValue("#F3F5FA", "")}
                    w="212px"
                    display="flex"
                    gap="25px"
                    flexDirection="column"
                  >
                    {price?.map((el, i) => (
                      <Flex
                        m="auto"
                        w="75%"
                        key={i}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Flex gap="7px" alignItems="center" w="fit-content">
                          {el.logo}
                          <Text fontSize="13px" fontWeight="400">
                            {el.name}
                          </Text>
                        </Flex>

                        <input
                          type="checkbox"
                          name="Pricing"
                          value={el.name}
                          attr="chk"
                          onChange={handleFilterchange}
                        />
                      </Flex>
                    ))}
                  </TabPanel>
                  <TabPanel
                    bg={useColorModeValue("#F3F5FA", "")}
                    w="212px"
                    display="flex"
                    gap="20px"
                    justifyContent="center"
                    flexDirection="column"
                    textAlign="center"
                    alignItems="center"
                  >
                    {other?.map((el, i) => (
                      <Flex
                        w="75%"
                        key={i}
                        justifyContent="space-between"
                        alignItems="center"
                        alignSelf="center"
                      >
                        <Flex gap="7px" alignItems="center" w="fit-content">
                          {el.logo}
                          <Text fontSize="13px" fontWeight="400">
                            {el.name}
                          </Text>
                        </Flex>

                        <input
                          type="checkbox"
                          name="others_features"
                          value={el.name}
                          attr="chk"
                          onChange={handleFilterchange}
                        />
                      </Flex>
                    ))}
                  </TabPanel>

                  {/* <TabPanel
                    bg={useColorModeValue("#F3F5FA", "")}
                    w="212px"
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                  >
                    <Flex
                      w="75%"
                      justifyContent="space-between"
                      alignItems="center"
                      alignSelf="center"
                    >
                      <Text fontSize="13px" fontWeight="400">
                        Newsest{" "}
                      </Text>
                      <input
                        type="checkbox"
                          attr="chk"
                          onChange={(e) => {
                          if (e.target.checked) {
                            setSort("newdataadd");
                            setPageName("sort");
                            setSortLoader((prev) => !prev);
                          }
                        }}
                      />
                    </Flex>
                    <Flex
                      w="75%"
                      justifyContent="space-between"
                      alignItems="center"
                      alignSelf="center"
                    >
                      <Text fontSize="13px" fontWeight="400">
                        Top rated{" "}
                      </Text>
                      <input
                        type="checkbox"
                          attr="chk"
                          onChange={(e) => {
                          if (e.target.checked) {
                            setSort("a-z");
                            setPageName("sort");
                            setSortLoader((prev) => !prev);
                          }
                        }}
                      />
                    </Flex>
                    <Flex
                      w="75%"
                      justifyContent="space-between"
                      alignItems="center"
                      alignSelf="center"
                    >
                      <Text fontSize="13px" fontWeight="400">
                        {" "}
                        Trending
                      </Text>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSort("mostlike");
                            setPageName("sort");
                            setSortLoader((prev) => !prev);
                          }
                        }}
                      />
                    </Flex>
                  </TabPanel> */}
                </TabPanels>
              </Tabs>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Button
      // w="50%"
      alignItems="center"
      gap="5px"
      fontSize="md"
      lineHeight="24px"
      textAlign="center"
      justifyContent="center"
      onClick={onOpen}
      borderRadius={10}
      // px={5}
      w="150px"
      variant="solid" // Style as needed
      bg={useColorModeValue("#ffffff", "#222222")}   
       border="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} 
       boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)" 
    >
        <TbAdjustmentsHorizontal />
      <Text>Filters</Text>
      {count > 0 && (
        <Box
          borderRadius="50%"
          backgroundColor="#3B89B6"
          color="white"
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {count}
        </Box>
      )}
    </Button>
   
    </>
  );
};



