import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  Text,
  Stack,
  Heading,
} from "@chakra-ui/react";
import style from "../../Style/Submit.module.css";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";

import { Form1 } from "../../Components/Submit/Form1";
import { Form2 } from "../../Components/Submit/Form2";
import { Form3 } from "../../Components/Submit/Form3";

import notification from "../../Components/Toast";
import ThankYouModal from "../../Components/Submit/ThankYouModal";

let underCategory = [
  { value: "3D modeling", label: "3D modeling" },
  { value: "Content creating", label: "Content creating" },
  { value: "Writing tool", label: "Writing tool" },
  { value: "Copywriting", label: "Copywriting" },
  { value: "Image editing", label: "Image editing" },
  { value: "Image generator", label: "Image generator" },
  { value: "Background removal", label: "Background removal" },
  { value: "Art", label: "Art" },
  { value: "AI Model", label: "AI Model" },
  { value: "Code assistant", label: "Code assistant" },
  { value: "No-code/Low-code", label: "No-code/Low-code" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Web/App Development", label: "Web/App Development" },
  { value: "Customer service", label: "Customer service" },
  { value: "AI Community", label: "AI Community" },
  { value: "Virtual Assistant", label: "Virtual Assistant" },
  { value: "VR/AR", label: "VR/AR" },
  { value: "Buy-Sell AI Image", label: "Buy-Sell AI Image" },
  { value: "Image Generator", label: "Image Generator" },
  { value: "Image Editing", label: "Image Editing" },
  { value: "2D-to-3D", label: "2D-to-3D" },
  { value: "Image-to-Code", label: "Image-to-Code" },
  { value: "AI Art Gallery", label: "AI Art Gallery" },
  { value: "SEO", label: "SEO" },
  { value: "Email Assistant", label: "Email Assistant" },
  { value: "Sales", label: "Sales" },
  { value: "Marketing", label: "Marketing" },
  { value: "Ad Creative", label: "Ad Creative" },
  { value: "Story Generator", label: "Story Generator" },
  { value: "Gaming", label: "Gaming" },
  { value: "Game Development", label: "Game Development" },
  { value: "Famous Figure", label: "Famous Figure" },
  { value: "Fun", label: "Fun" },
  { value: "Children", label: "Children" },
  { value: "Prompt", label: "Prompt" },
  { value: "Education", label: "Education" },
  { value: "Learning Tool", label: "Learning Tool" },
  { value: "Search Engine", label: "Search Engine" },
  { value: "Science", label: "Science" },
  { value: "Research", label: "Research" },
  { value: "Books", label: "Books" },
  { value: "Affiliate", label: "Affiliate" },
  { value: "AI Detector", label: "AI Detector" },
  { value: "Formula Generator", label: "Formula Generator" },
  { value: "Music", label: "Music" },
  { value: "Audio Sample", label: "Audio Sample" },
  { value: "Audio Editing", label: "Audio Editing" },
  { value: "Video Creation", label: "Video Creation" },
  { value: "Text-to-Speech", label: "Text-to-Speech" },
  { value: "Dubbing/Translator", label: "Dubbing/Translator" },
  { value: "Video Editing", label: "Video Editing" },
  { value: "Voice Generator", label: "Voice Generator" },
  { value: "Image-To-Video", label: "Image-To-Video" },
  { value: "Podcast", label: "Podcast" },
  { value: "Background Removal", label: "Background Removal" },
  { value: "Audio/Video Transcription", label: "Audio/Video Transcription" },
  { value: "Reel/TikTok/Shorts", label: "Reel/TikTok/Shorts" },
  { value: "Social Media", label: "Social Media" },
  { value: "E-Commerce", label: "E-Commerce" },
  { value: "Data Management", label: "Data Management" },
  { value: "Task Management", label: "Task Management" },
  { value: "Risk Management", label: "Risk Management" },
  { value: "Quality Management", label: "Quality Management" },
  { value: "Data Analysis", label: "Data Analysis" },
  { value: "Analytics", label: "Analytics" },
  { value: "Startup", label: "Startup" },
  { value: "Chatbot", label: "Chatbot" },
  { value: "Chatbot Builder", label: "Chatbot Builder" },
  { value: "Anime", label: "Anime" },
  { value: "Design Tool", label: "Design Tool" },
  { value: "Product Design", label: "Product Design" },
  { value: "Business Idea", label: "Business Idea" },
  { value: "Gift Ideas", label: "Gift Ideas" },
  { value: "Career", label: "Career" },
  { value: "Human Resource", label: "Human Resource" },
  { value: "Resume/CV", label: "Resume/CV" },
  { value: "Legal Assistant", label: "Legal Assistant" },
  { value: "Finance", label: "Finance" },
  { value: "Name Generator", label: "Name Generator" },
  { value: "Logo Design", label: "Logo Design" },
  { value: "Recipe/Food", label: "Recipe/Food" },
  { value: "Self Improvement", label: "Self Improvement" },
  { value: "Experiment", label: "Experiment" },
  { value: "Apps Store", label: "Apps Store" },
  { value: "Sports", label: "Sports" },
  { value: "Live Streaming", label: "Live Streaming" },
  { value: "Motion Capture", label: "Motion Capture" },
  { value: "Quiz", label: "Quiz" },
  { value: "Messaging", label: "Messaging" },
  { value: "Presentation", label: "Presentation" },
  { value: "Networking", label: "Networking" },
  { value: "NFTs", label: "NFTs" },
  { value: "Productivity", label: "Productivity" },
];

export default function Multistep({setOpen}) {
  const [step, setStep] = useState(1);

  const [works_with, setworks_with] = useState([]);
  const [Category, setCategory] = useState([]);
  const [others_features, setothers_features] = useState([]);
  const [Logo, setLogo] = useState("");
  const [Cover_image, setCover_image] = useState("");
  const [Galary_image, setGalary_image] = useState([]);
  const [tool_own, setTool] = useState(true);
  const [verify, setverify] = useState(true);
  const [social_media, setSocial_media] = useState([]);
  const [Tags, setTag] = useState([]);
  const [key_features, setkey_features] = useState([]);

  const [data, setData] = useState({
    URL: "",
    Title: "",
    Tagline: "",
    Description: "",
    Pricing: "",
    price_amount: "",
    Youtube_embed: "",
    afilitedLink: "",
    isCSVFile: false,
  });

  const userData = useSelector((state) => state.userReducer.loginData);

  const handlechange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmitTool = async () => {
    let payload = {
      ...data,
      works_with,
      Category,
      others_features,
      Logo,
      Cover_image,
      Galary_image,
      tool_own,
      verify,
      social_media,
      Tags,
      key_features,
    };

    try {
      const token = userData.data;

      if (token) {
        axios
          .post(`${process.env.REACT_APP_API}/data/add`, payload, {
            headers: { token },
          })
          .then((res) => {
            setOpen(true);

            notification("success", res.data.msg);
          });
      } else {
        notification("error", "Login First!");
      }
    } catch (error) {
      notification("error", "Something went wrong!");
    }
  };

  return (
    <>
      <Box w="100%">
        <Heading p="20px" fontWeight="600">
          Submit Tool
        </Heading>
        <Box className={style.submitform}>
          <Stack className={style.left} m="20px">
            <Box w="100%" fontWeight="600">
              <Text
                mb="10px"
                w="fit-content"
                onClick={() => {
                  setStep(1);
                }}
                cursor="pointer"
                p="5px"
                borderRadius="3px"
                color={step === 1 ? "white" : ""}
                bg={step === 1 ? "red" : ""}
              >
                Basic Information
              </Text>
              <Text
                mb="10px"
                w="fit-content"
                onClick={() => {
                  setStep(2);
                }}
                cursor="pointer"
                p="5px"
                borderRadius="3px"
                color={step === 2 ? "white" : ""}
                bg={step === 2 ? "red" : ""}
              >
                Price & deal
              </Text>

              <Text
                mb="10px"
                w="fit-content"
                onClick={() => {
                  setStep(3);
                }}
                cursor="pointer"
                p="5px"
                borderRadius="3px"
                color={step === 3 ? "white" : ""}
                bg={step === 3 ? "red" : ""}
              >
                Finish
              </Text>
            </Box>
          </Stack>
          <Stack
            className={style.right}
            fontSize="20px"
            fontWeight="400"
            m="20px"
          >
            <Box className={style.leftborder} w="100%">
              {step === 1 ? (
                <Form1
                  handlechange={handlechange}
                  works_with={works_with}
                  others_features={others_features}
                  Category={Category}
                  {...data}
                  key_features={key_features}
                  setkey_features={setkey_features}
                  setCategory={setCategory}
                  social_media={social_media}
                  setSocial_media={setSocial_media}
                  setworks_with={setworks_with}
                  setothers_features={setothers_features}
                  underCategory={underCategory}
                />
              ) : step === 2 ? (
                <Form2
                  handlechange={handlechange}
                  {...data}
                  Tags={Tags}
                  setTag={setTag}
                />
              ) : (
                <Form3
                  setCover_image={setCover_image}
                  setGalary_image={setGalary_image}
                  setLogo={setLogo}
                  Logo={Logo}
                  Galary_image={Galary_image}
                  Cover_image={Cover_image}
                  handlechange={handlechange}
                  {...data}
                />
              )}
              <ButtonGroup mt="5%" w="100%">
                <Flex w="100%" justifyContent="space-between">
                  <Box w="100%" textAlign="right">
                    <Button
                      w="68px"
                      mt="10px"
                      color="white"
                      borderRadius="4px"
                      bg="red"
                      _hover={{ bg: "red" }}
                      display={step === 3 ? "none" : ""}
                      onClick={() => {
                        setStep(step + 1);
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                  {step === 3 ? (
                    <Button
                      w="193px"
                      height="28px"
                      mt="10px"
                      color="white"
                      _hover={{ bg: "red" }}
                      borderRadius="4px"
                      bg="red"
                      onClick={handleSubmitTool}
                    >
                      Submit
                    </Button>
                  ) : null}
                </Flex>
              </ButtonGroup>
            </Box>
          </Stack>
        </Box>
      </Box>

    </>
  );
}
