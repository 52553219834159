export const ActionTypes = {
  SET_USER_LOGIN: "SET_USER_LOGIN",
  SET_MYSPACE_NAME: "SET_MYSPACE_NAME",
  HOME_PAGE_LOADING: "HOME_PAGE_LOADING",
  HOME_PAGE_SUBCATEGORY: "HOME_PAGE_SUBCATEGORY",
  GOOGLE_SIGNUP_LOADING: "GOOGLE_SIGNUP_LOADING",
  GOOGLE_SIGNUP_SUCCESS: "GOOGLE_SIGNUP_SUCCESS",
  GOOGLE_SIGNUP_ERROR: "GOOGLE_SIGNUP_ERROR",
  SIGN_OUT: "login/signout",
  SET_UPDATE_USER_PROFILE: "SET_UPDATE_USER_PROFILE",
  // 
  SET_FITER_CATEGORY: "SET_FITER_CATEGORY",
  REMOVE_FITER_CATEGORY_ITEM: "REMOVE_FITER_CATEGORY_ITEM",
  SEARCH_ITEM: "SEARCH_ITEM"
};
