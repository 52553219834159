import React, { useEffect, useState } from "react";
import Multistep from "../Components/Submit/Mulistepfrom";
import {
  Box,
  Center,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import ThankYouModal from "../Components/Submit/ThankYouModal";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginModel from "../Components/model/LoginModel";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Footer_options from "../Components/Home/Footer_home";

export const Submit = () => {
  const [updateData, setdata] = useState([]);
  const { id } = useParams();

  const getData = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/data/single/get/${id}`);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    
    setTimeout(()=> {
      
      id && getData({ id }).then((res) => {
       res && setdata(res.data.data);
        // console.log(res.data.data);
        // setloading(false);
        // setPageTitle(res.data.data?.Title + " - AI Zones");
      });
    }, 500)
    
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loginData, isAuth } = useSelector((state) => state.userReducer);

  const metaTitle = "Get Your AI Tool Discovered on AI Zones: Submit Today!";
  document.title = "Submit your AI tools & Manage in Creators Portal";

    const metaDescription =
      "Submitting your AI tool to AI Zones is easy and free. Get listed in our largest AI directory, manage it seamlessly in our 'Creators Portal' features, track its performance, and connect with a thriving community of creators & AI enthusiasts.";

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/submit`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/Submit-Your-Tool-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/submit`} />
    </Helmet>
    <>
    
      {loginData === false ? <LoginModel /> : null}

      <ThankYouModal isOpen={isOpen} onClose={onClose} />

      <Box marginTop={{ base: "60px", md: "80px" }} >
        <Stack w="100%" >
          <Box
            w="95%"
            m="auto"
            bg={useColorModeValue("#ffffff", "#222222")}
            padding={{ base: "20px", md: "30px" }}
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            borderRadius={10}
            
          >
            <Text as="h1" fontSize="32px" fontWeight="600" lineHeight="24px" py={4} display={id ? "none" : "block"}>
              Submit a tool ➡️🌐
            </Text>
            <Text fontSize="32px" fontWeight="600" lineHeight="24px" py={4} display={id ? "block" : "none"}>
            Creator’s portal 👩‍💻🚪
            </Text>

            <Text
              mt="20px"
              fontSize="14px"
              fontWeight="400"
              textAlign="justify"
              lineHeight="24px"
              display={id ? "none" : "block"}
              as="h2"
            >
              We're always looking for innovative tools to add to our
              collection, and we welcome submissions from anyone who has
              developed or come across a useful AI tool. Share your creation
              with the world and help expand the field of AI.
            </Text>
            <Text
              mt="20px"
              fontSize="14px"
              fontWeight="400"
              textAlign="justify"
              lineHeight="24px"
              display={id ? "block" : "none"}
            >
              Welcome to our creator's portal, a private access area for creators to manage and update their AI tools information. Our creator's portal makes it easy for creators to keep their tool data up-to-date and accurate, ensuring that users have the most current and relevant information available.
            </Text>

            {/* <Box
              py={3}
              px={4}
              borderRadius="3px"
              bg="#384955"
              mt="20px"
              fontSize="16px"
              fontWeight="400"
              textAlign="justify"
              lineHeight="24px"
              color="white"
            >
              <Text>
                {" "}
                For creators: Stay Logged in with your Official Domain
                Registered Email ID! It will save time in verification and help
                you quickly gain access to the Creators Portal.
              </Text>
            </Box> */}
              {console.log(updateData, id)}
                {(id) ? <Multistep setOpen={onOpen} updateData={updateData} id={id} /> : <Multistep setOpen={onOpen} />}       
                {/* <Multistep setOpen={onOpen} updateData={updateData} /> */}
          </Box>
        </Stack>
      </Box>
      <Footer_options/>
    </>
   </HelmetProvider>
  );
};
