

import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Flex, Box, Text, Heading, Image, Divider,useColorModeValue, SimpleGrid,InputRightElement,InputGroup, Input, useColorMode, useClipboard, VStack, Button,HStack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { LandingRight } from "../Components/LandingRight";
// import { Footer } from "./Footer";
import Footer_options from '../Components/Home/Footer_home';
import { FaTwitter, FaInstagram, FaLinkedin,FaFacebook,  FaLink ,FaCopy } from 'react-icons/fa';
import { IoIosArrowForward, IoMdShareAlt } from 'react-icons/io';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { FaShareAlt } from 'react-icons/fa';
import notification from "../Components/Toast";
import { useBreakpointValue } from '@chakra-ui/react';


const BlogPage = () => {
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [otherBlogs, setOtherBlogs] = useState([]);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { onCopy } = useClipboard(window.location.href);

  const shareButtonPosition = useBreakpointValue({ base: 'initial', md: 'fixed' });
const shareButtonTop = useBreakpointValue({ base: '0', md: '50%' });
const shareButtonLeft = useBreakpointValue({ base: 'initial', md: '2rem' });
const shareButtonTransform = useBreakpointValue({ base: 'none', md: 'translateY(-50%)' });
const shareButtonMarginTop = useBreakpointValue({ base: '1rem', md: '0' });
const shareButtonFlexDirection = useBreakpointValue({ base: 'row', md: 'column' });
const inputbox=useColorModeValue("#ffffff", "#1c1c1c");


const bg=useColorModeValue("#ffffff", "#1e1e1e");

  // State to handle the visibility of share buttons on scroll
  const [showShareButtons, setShowShareButtons] = useState(true);

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const dividerRef = useRef(null);
  const shareButtonsRef = useRef(null);

  const handleScroll = () => {
    if (!dividerRef.current || !shareButtonsRef.current) return;

    const dividerRect = dividerRef.current.getBoundingClientRect();
    const shareButtonsRect = shareButtonsRef.current.getBoundingClientRect();

    // Unstick the share buttons when the bottom of share buttons touches the divider
    if (dividerRect.top <= shareButtonsRect.bottom) {
      shareButtonsRef.current.style.position = 'absolute';
      shareButtonsRef.current.style.top = `${window.scrollY + dividerRect.top - shareButtonsRect.height}px`;
    } else {
      shareButtonsRef.current.style.position = 'fixed';
      shareButtonsRef.current.style.top = '';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/blogs/${slug}`);
        setPost(response.data);
  
        const otherPostsResponse = await axios.get(`${process.env.REACT_APP_API}/api/blogs`);
        const sortedOtherBlogs = otherPostsResponse.data
        .filter(blog => blog.published) // Filter out unpublished blogs
          .filter(b => b.slug !== slug) // Exclude current post
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort from latest to oldest
  
        setOtherBlogs(sortedOtherBlogs);
      } catch (error) {
        toast({
          title: 'Error fetching blog post',
          description: error.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    if (slug) {
      fetchBlogPost();
    }
  }, [slug, toast]);

  if (!post) {
    return <Box>Loading...</Box>;
  }

  

  //const contentState = convertFromRaw(JSON.parse(post.content));
  // const htmlContent = stateToHTML(contentState);
  // const htmlContent = post.content;


  let htmlContent;
  
  // Check if the content is JSON, parse it using Draft.js methods; otherwise, use it as is (HTML from TinyMCE)
  if (isJson(post.content)) {
    const contentState = convertFromRaw(JSON.parse(post.content));
    htmlContent = stateToHTML(contentState);
  } else {
    htmlContent = post.content; // This is HTML content from TinyMCE
  }


  document.title = `${post.title} | AI Zones`;

const handleSocialShare = (platform) => {
  const url = encodeURIComponent(window.location.href);
  const text = encodeURIComponent(post.title);
  
  let shareUrl = "";

  switch (platform) {
    case "facebook":
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      break;
    case "twitter":
      shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
      break;
    case "linkedin":
      shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}`;
      break;
    default:
      // If no platform matches, we default to copy link functionality
      onCopy();
      toast({
        title: 'Link Copied!',
        description: "The link to this post has been copied to your clipboard.",
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
  }

  window.open(shareUrl, '_blank', 'noopener,noreferrer');
};


///brevo
const handleClick = async () => {
  setBtnLoading(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email || !emailRegex.test(email)) {
    notification("error", "Enter a valid email address");
    setBtnLoading(false);
    return;
  }
  try {      
    const response = await fetch('https://api.brevo.com/v3/contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': process.env.REACT_APP_BREVO_API_KEY
        },
    
    body: JSON.stringify({
      email,
      listIds: [10], // Include the list ID in the request body to assign the contact to the list
    }),
  });
    notification("success", "Successfully subscribed");
    setBtnLoading(false);
  } catch (error) {
    notification("error", "Something went wrong");
    setBtnLoading(false);
  }
};

//beehiv
// const handleClick = async () => {
//   setBtnLoading(true);
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//   if (!email || !emailRegex.test(email)) {
//     notification("error", "Enter a valid email address");
//     setBtnLoading(false);
//     return;
//   }
//   try {
//     // Use your backend endpoint here. Update the URL to your deployed backend service
//     const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });

//     // Assuming your backend sends a success message
//     notification("success", response.data.message || "Successfully subscribed");
//   } catch (error) {
//     // Axios embeds the response in error.response
//     notification("error", error.response?.data?.message || "Something went wrong");
//   } finally {
//     setBtnLoading(false);
//     setEmail(""); // Optionally clear the email input after subscribing
//   }
// };







// Inline styles for cover image
const coverImageStyle = {
  //width: '709px',
  height: 'auto',
  objectFit: 'cover', // Ensures the image covers the area without losing aspect ratio
};

// Inline styles for content rendered with dangerouslySetInnerHTML
const htmlContentStyle = {
  // Example: font color changes with color mode
  lineHeight: "32px", // Line spacing
  //width: '709px', // Width of the content area
  maxWidth: '100%', // Ensures that the content area is not wider than its container
  margin: '0 auto', // Centers the content block
  // Additional styles can be added here as needed
};


  return (
    <HelmetProvider>
       <Flex direction="column" minHeight="100vh"> {/* PageContainer with flex column and full viewport height */}
         <Helmet>
    <title>{post.metaTitle} | AI Zones</title>
    <meta name="description" content={post.metaDescription} />

    {/* Open Graph / Facebook */}
    <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/blog/${post.slug}`} />
    <meta property="og:type" content="article" />
    <meta property="og:title" content={post.metaTitle} />
    <meta property="og:description" content={post.metaDescription} />
    <meta property="og:image" content={post.coverImage} />

    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@aizones_io" /> {/* Your Twitter handle */}
    <meta name="twitter:title" content={post.metaTitle} />
    <meta name="twitter:description" content={post.metaDescription} />
    <meta name="twitter:image" content={post.coverImage} />

    {/* Canonical Link */}
    <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/updates/${post.slug}`} />
  </Helmet>

 

  
      
      {/* Navigation Bar */}
          {/* <Flex
        // as="nav"
        position="scroll"
        top="0"
        left="0"
        right="0"
        justify="flex-start"
        p="13px 15px"
        mt="50px"
   
        zIndex="banner"
      > */}

<Flex
             mt="30px"
             p="13px 15px"
              // mb="25px"
              alignItems="center"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              // ml={50}

            >
      
      <Link to="/">
                <Text >Home</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/blog">
                <Text>Updates</Text>
              </Link>


  
    </Flex> 
      <Flex direction="row" >
      <Flex direction="column"  mt={0} align="center" margin="0 auto">
     
        <Flex direction={{ base: "column", md: "row" }} justify="center" align="flex-start">
       
          <Box flex="1" maxW={{ base: "100%", md: "809px" }} margin="0 auto" p="4">

             <Box position="fixed" left="10px" top="30%" >
             {showShareButtons && (
          <Box
            display={{ base: 'none', md: 'block' }}
            position="fixed"
            left="10px"
            top="50%"
            transform="translateY(-50%)"
            zIndex="sticky"
            >
    <VStack spacing={2}>
      <Tooltip hasArrow label="Share on Facebook" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on Facebook"
          icon={<FaFacebook />}
          onClick={() => handleSocialShare('facebook')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
      <Tooltip hasArrow label="Share on Twitter" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on Twitter"
          icon={<FaTwitter />}
          onClick={() => handleSocialShare('twitter')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
      <Tooltip hasArrow label="Share on LinkedIn" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on LinkedIn"
          icon={<FaLinkedin />}
          onClick={() => handleSocialShare('linkedin')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
      <Tooltip hasArrow label="Copy link" bg="gray.300" color="black">
        <IconButton
          aria-label="Copy link"
          icon={<FaLink />}
          onClick={() => handleSocialShare('copy')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
    </VStack>
    </Box>
        )}
  </Box>
            <Heading as="h1" size="xl" fontFamily="Nunito" width="100%" mb={4}>{post.title}</Heading>
            <Text color="gray.500" mb={6}>{`Published on ${new Date(post.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`}</Text>
            <HStack spacing={4} justify="left" mb={4}>
            <Tooltip hasArrow label="Share on Facebook" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on Facebook"
          icon={<FaFacebook />}
          onClick={() => handleSocialShare('facebook')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
      <Tooltip hasArrow label="Share on Twitter" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on Twitter"
          icon={<FaTwitter />}
          onClick={() => handleSocialShare('twitter')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
      <Tooltip hasArrow label="Share on LinkedIn" bg="gray.300" color="black">
        <IconButton
          aria-label="Share on LinkedIn"
          icon={<FaLinkedin />}
          onClick={() => handleSocialShare('linkedin')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
            <Tooltip hasArrow label="Copy link" bg="gray.300" color="black">
        <IconButton
          aria-label="Copy link"
          icon={<FaLink />}
          onClick={() => handleSocialShare('copy')}
          variant="ghost"
          // ... other IconButton props
        />
      </Tooltip>
          </HStack>
          <Box flex="1" maxW={{ base: "100%", md: "100%" }} p="4">
      
     
      <Image  boxShadow= '0px 4px 8px rgba(0, 0, 0, 0.1)' src={post.coverImage} margin="0 auto" mb={4} borderRadius={10} maxW={{ base: "100%", md: "100%" }} style={coverImageStyle} alt={post.title} />
      {/* For content that needs to inherit styles like color, line-height */}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={htmlContentStyle} />
    </Box>
    
          </Box>
          <Box ml={5}>
          < LandingRight/>
          </Box>
          {/* <VStack spacing={4} ml={{ md: 4 }} mt={{ base: 4, md: 0 }}> */}
          
            {/* <Box>
              <Heading as="h4" size="md">Other Blogs</Heading>
              {otherBlogs.map((b) => (
                <Box key={b.slug} my={2}>
                  <Link to={`/blogs/${b.slug}`}>
                    <Text>{b.title}</Text>
                  </Link>
                </Box>
              ))}
            </Box> */}
          {/* </VStack> */}
        </Flex>
        
      </Flex>
      {/* <Flex
        width={{ lg: "300px" }} // Width of the sidebar
        flexShrink={0} // Prevents the sidebar from shrinking
        mt={{ base: 4, lg: 0 }} // Margin top on base and no margin on larger screens
        // ml={300}
        align="right"
        position={{ base: "scroll", lg: "scroll" }} // Fixed on larger screens
        right="10px"
        top="0"
        height="100vh"
      >
           <Box display="block" css={{ '@media(max-width: 1500px)': { display: 'none' } }}>
    <LandingRight />
  </Box>
        </Flex> */}
      </Flex>
      <Divider id="divider" my={8} />
      <Flex my={8} align="center" justify="center">
        <Box p={4} borderRadius="md" bg={inputbox}  boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"  borderWidth={'2px'} >
          <Text fontSize="lg" >
          Stay ahead with AI — Subscribe to AI Zones for daily tech insights
          </Text>
          <InputGroup size="md" mt={2}>
            <Input
              pr="4.5rem"
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Your email"
              aria-label="Your email"
              bg={inputbox}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" p={5} onClick={handleClick} px={10}color="white" bg="#3B89B6">
                Subscribe
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Flex>
        <Heading as="h3" px={10} size="lg" my={5}>More updates</Heading>
        <SimpleGrid px={10} columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={5}>
           {otherBlogs.slice(0, 8).map((otherPost) => ( 
            <Box key={otherPost.slug} bg={bg} boxShadow="md" borderRadius="md">
              <Link to={`/updates/${otherPost.slug}`}>
                <Image src={otherPost.coverImage} alt={otherPost.title} borderRadius="md" mb={3} height="200px"  width="100%" objectFit="cover" />
             
                {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
                <Box p={5}>
                  <Text textAlign="center"fontWeight="bold">{otherPost.title}</Text>
                  {/* You can include other details like author and read time here */}
                </Box>
              </Link>
            </Box>
          ))}
        </SimpleGrid>
        <Footer_options/>
        </Flex>
    </HelmetProvider>
  );
};

export default BlogPage;


