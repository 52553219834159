import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Avatar,
  Flex,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import ShareModel from "../Components/Share";
import { CuratedCollectionCard } from "../Components/Collection/CuratedCollectionCard";

const getData = async ({ id }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API}/myspace/public/get/${id}`
  );
  return response.data.data;
};

function MyTools() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData({ id });
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}>
      <Flex marginTop={{ base: "0px", md: "60px" }}>
        <Stack justifyContent="center" w="100%">
          <Box width="90%" m="auto" mt="0px">
            <Box
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              py={1}
              paddingBottom="20px"
            ></Box>

            <Flex flexDirection={"column"} gap={"20px"} py={"20px"}>
              {/* {data?.map((el, i) => (
                <CuratedCollectionCard
                  // el={el}
                  key={i}
                  el={el.videoID}
                  setcat={setcat}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                />
              ))} */}
            </Flex>
          </Box>
        </Stack>
        {/* LandingRight component */}
      </Flex>
    </Box>
  );
}

export default MyTools;
