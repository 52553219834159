import React, { useEffect } from "react";

import style from "../../Style/Grid.module.css";

import { Box,SimpleGrid } from "@chakra-ui/react";

import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ListModal_home } from "../ListModal_home";

export const LIstcomp_home = () => {
  const [pageName, setPageName] = useState("");

  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  let [data, setData] = useState([]);

  const userData = useSelector((state) => state.userReducer.loginData);

  let token = userData.data;

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/data/get/userlikes/data`,

        { headers: { token } }
      );
      //console.log(res);
      setData(res.data.data);
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box mt="30px" >
        <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacingX="40px" spacingY="20px">

      {data?.map((el, i) => (
        <ListModal_home
          key={i}
          el={el?.videoID}
          id={el?.videoID?._id}
          i={i}
          setcat={setcat}
          setPageName={setPageName}
          setFilterLoader={setFilterLoader}
        />
      ))}
      </SimpleGrid>
    </Box>
  );
};

                          