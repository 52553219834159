import {
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  Image,
  useColorModeValue, UnorderedList, ListItem
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "../Style/Collection.module.css";
import { HashLink } from 'react-router-hash-link';
import { Curated } from "../Components/Collection/Curated";
import { LikeBoard } from "../Components/Collection/like";
import { MyCollection } from "../Components/Collection/MyCollection";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import LoginModel from "../Components/model/LoginModel";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {Helmet, HelmetProvider} from "react-helmet-async";
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import Footer_options from "../Components/Home/Footer_home";

export const Collection = () => {
  const [collectionNavigation, setCollectionNavigation] = useState(
    "Curated Collections"
  );
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const { colorMode, toggleColorMode } = useColorMode();
  const { loginData, isAuth } = useSelector((state) => state.userReducer);


  // loginData
  document.title = "Collections - Curated Solutions at AI Zones";

  // Set meta tags
  const metaTitle = "Explore Top AI Tools: Ultimate Collections for Developers & Creatives - AI Zones";
  const metaDescription = "Dive into our curated collections of AI tools designed to revolutionize productivity, creativity, and efficiency. From AI-driven design enhancements and developer tools to innovative writing assistants and business solutions, find the perfect AI application to elevate your projects and streamline your workflow.";


  const tabMap = {
    '#curatedcollections': 0,
    '#liked': 1,
    '#mycollections': 2,
  };

  // Effect to sync URL hash with tab state
  useEffect(() => {
    const hash = location.hash;
    const index = tabMap[hash];
    if (index !== undefined) {
      setTabIndex(index);
    }
  }, [location.hash, tabMap]);

  // Update the URL hash when a tab is changed
  const handleTabsChange = (index) => {
    const hashKey = Object.keys(tabMap).find(key => tabMap[key] === index);
    window.location.hash = hashKey; // This updates the URL hash
    setTabIndex(index);
  };
  

  const heading = useColorModeValue("gray.800", "white");
  const font = useColorModeValue("gray.600", "gray.400");

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= "https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
    
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />

    
    </Helmet>

     <Stack marginTop={{ base: "60px", md: "60px" }}>
      <Box
        paddingTop={{ base: "10px", md: "20px" }}
        className={style.collection}
        margin="auto"
        fontFamily="Nunito"
      >
        <Flex
          mb="25px"
          alignItems="center"
          fontSize="12px"
          fontWeight="400"
          lineHeight="20px"
        >
          <Link to="/">
            <Text>Home</Text>
          </Link>

          <Text>
            {" "}
            <IoIosArrowForward
              size={15}
              color={colorMode === "light" ? "#1a202c" : "white"}
            />{" "}
            {""}
          </Text>

          <Link to="/collection">
            <Text >Collections</Text>
          </Link>

          <Text>
            {" "}
            <IoIosArrowForward
              size={15}
              color={colorMode === "light" ? "#1a202c" : "white"}
            />{" "}
            {""}
          </Text>

          <Text>{collectionNavigation}</Text>
        </Flex>
        <Flex alignItems="center">
          <Text fontWeight="600" as="h1" ml={2}fontSize={"32px"} py={{ base: 2, md: 5 }}color={heading}lineHeight="24px">
          Collections
          </Text>
          <FontAwesomeIcon
    style={{
      fontSize: "1.5rem",
      marginLeft: "20px",
      // paddingTop can be removed since alignItems="center" will take care of vertical alignment
    }}
    icon={faBookmark} // Ensure this icon is properly imported at the top of your file
  />
        </Flex>
        <Box p={5}>
  <UnorderedList textAlign="left" ml={5}>
    <ListItem>
      <Text fontSize="md" as="h2"py={1} fontWeight="200" color={useColorModeValue("gray.800", "white")}>
        Explore <strong>Curated Collections</strong> to navigate through a public treasury of AI tools, meticulously selected by our community to fuel creativity, enhance productivity, and solve complex challenges.
      </Text>
    </ListItem>
    <ListItem>
      <Text fontSize="md" as="h2" py={1} fontWeight="200" color={useColorModeValue("gray.800", "white")}>
        In the <strong>Liked</strong> section, find a compilation of top-rated tools and collections endorsed by users like you, showcasing the best in AI-driven design enhancements, developer tools, writing assistants, and more.
      </Text>
    </ListItem>
    <ListItem>
      <Text fontSize="md" as="h2" py={1} fontWeight="200" color={useColorModeValue("gray.800", "white")}>
        <strong>My Collection</strong> offers a personalized space to assemble your preferred AI applications, from powerful email assistants to generative AI, spreadsheet solutions, and beyond.
      </Text>
    </ListItem>
  </UnorderedList>
</Box>
        
        {/* <Text fontWeight="100" fontSize={"1.3rem"} pt="25px" pb="10px">
          Discover the Top Curated Collections of AI Tools: Community and Official Picks
        </Text> */}
    <Tabs index={tabIndex} onChange={handleTabsChange} mt="20px" position="sticky">
     <TabList
      w="100%"   
      whiteSpace="nowrap" // Prevents tab text from wrapping
    >
    <Tab
      px={{ base: 3, md: 4 }} // Responsive padding
      py={2}
      fontSize={{ base: "sm", md: "md" }} // Responsive font size
      fontWeight="600"
      lineHeight={"24px"}
              // onClick={() => setCollectionNavigation("Curated Collections")}
              as={HashLink} to="#curatedcollections"
              // isDisabled={!loginData}
              >
      Curated Collections
    </Tab>
    <Tab
      px={{ base: 3, md: 4 }}
      py={2}
      fontSize={{ base: "sm", md: "md" }}
      fontWeight="600"
      lineHeight={"24px"}
      as={HashLink} to="#liked"
    >
      Liked
    </Tab>
    <Tab
      px={{ base: 3, md: 4 }}
      py={2}
      fontSize={{ base: "sm", md: "md" }}
      fontWeight="600"
      lineHeight={"24px"}
      as={HashLink} to="#mycollections"
    >
      My Collections
    </Tab>
  </TabList>

          <TabPanels>
            <TabPanel>
            {tabIndex === 0 && <Curated />}
              {/* <Curated /> */}
              {/* {(loginData === false && collectionNavigation === 'Curated Collections') && <LoginModel />} */}

            </TabPanel>

            <TabPanel id="liked" >
            {tabIndex === 1 && <LikeBoard />}
            {tabIndex === 1 && !loginData && <Box><LoginModel /></Box>}

            </TabPanel>

            <TabPanel id="mycollections">
            {tabIndex === 2 && <MyCollection />}
            {tabIndex === 2 && !loginData && <Box><LoginModel /></Box>}
            </TabPanel>
          </TabPanels>
        </Tabs>
        
      </Box>
    </Stack>
    <Footer_options/>
    </HelmetProvider>
  );
};
