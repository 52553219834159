// handleGoogleAuth.js
import { auth, googleAuthProvider } from './Firebase';
import axios from 'axios';
import notification from '../Components/Toast';
import { setUserLogin } from '../Redux/action';


const initializeGoogleOneTap = (dispatch, navigate, onClose, callback) => {
    window.google.accounts.id.initialize({
      client_id: '255599874809-s5mjhl2roblfudqm6pu2ac3s0ctsa39s.apps.googleusercontent.com',
      callback: response => handleCredentialResponse(response, dispatch, navigate, onClose, callback)
    });
    window.google.accounts.id.prompt();
  };
  
  // Parse JWT to get user data
  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };
  
  // Handle Google One Tap sign-in response
  const handleCredentialResponse = async (response, dispatch, navigate, onClose, callback) => {
    const user = parseJwt(response.credential);
  
    let payload = {
      name: user.name,
      image: user.picture,
      email: user.email,
    };
  
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/users/google/auth`,
        payload
      );
  
      dispatch(setUserLogin(data));
      localStorage.setItem("UserData", JSON.stringify(data));
      notification("success", "Logged in successfully");
  
      if (callback && typeof callback === 'function') {
        callback();
      } 
  
      if (onClose) onClose();
    } catch (error) {
      notification("error", "Login failed");
    }
  };

const HandleGoogle = async (dispatch, navigate, onClose,callback) => {
  try {
    let { user } = await auth.signInWithPopup(googleAuthProvider);

    let payload = {
      name: user._delegate.displayName,
      image: user._delegate.photoURL,
      email: user._delegate.email,
    };

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/users/google/auth`,
      payload
    );

    dispatch(setUserLogin(data));
    localStorage.setItem("UserData", JSON.stringify(data));
    notification("success", "Logged in successfully");

    // If a callback function is provided, execute it.
    if (callback && typeof callback === 'function') {
      callback();
    } 

    if (onClose) onClose(); // Close the modal if the onClose function is provided
  } catch (error) {
    notification("error", "Log in process cancelled");
  }
};

export default HandleGoogle;
